@property --mat-form-field-height {
  syntax: '<length>';
  inherits: true;
  initial-value: 50px;
}

@property --mat-form-field-no-label-height {
  syntax: '<length>';
  inherits: true;
  initial-value: 42px;
}

.mat-mdc-form-field:not([appearance='outline']) {
  &:not([class*='col-']) {
    width: 100%;
  }

  .mat-mdc-form-field-text-prefix {
    font-family: var(--rs-ds--font--primary-light);
  }

  .mat-mdc-input-element {
    @include rs-font--primary-light;
  }

  .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }

  .mat-mdc-text-field-wrapper {
    background-color: transparent;
    height: var(--mat-form-field-height);
    padding-left: 4px;
    padding-right: 4px;

    &.mdc-text-field--no-label {
      height: var(--mat-form-field-no-label-height);
    }

    .mdc-floating-label--float-above {
      transform: translateY(-106%) !important;
    }

    .mdc-floating-label {
      transform: translateY(-4px);
    }

    mat-label {
      @include rs-font--primary-light();
      font-size: 14px !important;
    }

    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-icon-prefix {
        > .mat-icon {
          @include rs-set-mat-icon-size(24px);
          transition: 0.4s transform var(--rs-transition--bezier);
          transform: translateY(5px);
          padding: 0;
        }
      }

      .mat-mdc-form-field-icon-suffix {
        padding-left: 0;

        &:nth-of-type(2) {
          padding: 0 0 0 4px;
        }
      }
    }
  }
}

/** Errors  */
.mat-mdc-form-field:not([appearance='outline']) {
  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    .mat-mdc-form-field-error-wrapper {
      padding-left: 0;
    }
  }
}

/** Mat hint */
.mat-mdc-form-field:not([appearance='outline']) {
  &.mat-form-field-disabled .mat-mdc-form-field-hint {
    opacity: 0.38;
  }

  .mat-mdc-form-field-hint {
    margin: 2px 0;
  }

  /* Make it multiline & responsive */
  .mat-mdc-form-field-subscript-wrapper {
    &:has(.mat-mdc-form-field-hint)::before {
      display: none;
    }

    .mat-mdc-form-field-hint-wrapper {
      padding-left: 4px;
      position: relative;

      .mat-mdc-form-field-bottom-align::before {
        display: none;
      }
    }
  }
}

/** READ ONLY*/
.mat-mdc-form-field:has(input[readonly='true']) {
  &.mat-form-field-hide-placeholder input {
    display: none;
  }

  .mdc-line-ripple--active::after {
    transform: scale(0) !important;
  }
}

/** mat-auto-complete */
.mat-mdc-form-field:has(mat-autocomplete) {
  .mat-mdc-form-field-icon-suffix mat-icon {
    padding: 0;
    width: 20px;
  }
}

/** When mat-form-field is filled in */
.mat-mdc-form-field:not([appearance='outline']):not(.mat-form-field-hide-placeholder) {
  .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-icon {
    transform: translateY(0);
    transition: 0.4s transform var(--rs-transition--bezier);
  }
}
