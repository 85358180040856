@import '../../abstract/variables';
@import '../../abstract/mixins';

mat-vertical-stepper {
  --rs-mat-stepper-content-padding-short: 24px;
  --rs-mat-stepper-step-margin-bottom: 40px;
  --rs-mat-stepper-header-x-spacing: 40px;
  --rs-mat-stepper-header-y-spacing: 24px;
  --rs-mat-stepper-header-label-text-size: 1.125rem;
  --rs-mat-stepper-header-label-line-height: 1.625rem;
  --rs-mat-stepper-content-x-spacing: 40px;
  --rs-mat-stepper-content-y-spacing: 40px;
  --mat-stepper-line-color: var(--rs-ds--border-color);

  --rs-mat-stepper-icon-size: 40px;
  --rs-mat-stepper-lef-offset: 80px;

  --mat-stepper-header-selected-state-icon-background-color: var(--rs-color--accent--50);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--rs-color--accent--500);
  --mat-stepper-header-selected-state-icon-border-color: var(--rs-color--accent--500);
  --mat-stepper-header-icon-background-color: transparent;
  --mat-stepper-header-icon-foreground-color: var(--rs-ds--text-disabled);
  --mat-stepper-header-icon-border-color: var(--rs-ds--text-disabled);

  --rs-mat-stepper-vertical-line-left-align: -60px;
  --mat-stepper-header-hover-state-layer-shape: var(--rs-card--border-radius);
  --mat-stepper-header-focus-state-layer-shape: var(--rs-card--border-radius);
  --mat-stepper-header-selected-state-label-text-size: var(--rs-mat-stepper-header-label-text-size);
  --mat-stepper-header-label-text-size: var(--rs-mat-stepper-header-label-text-size);
  --mat-stepper-header-error-state-label-text-size: var(--rs-mat-stepper-header-label-text-size);

  --mat-stepper-container-color: transparent;
  --mat-stepper-header-hover-state-layer-color: transparent;
  --mat-stepper-header-label-text-color: var(--rs-ds--text-color);
  --mat-stepper-header-selected-state-label-text-color: var(--rs-ds--text-color);
  --mat-stepper-header-error-state-label-text-color: var(--mat-stepper-header-label-text-color);

  width: calc(100% - var(--rs-mat-stepper-lef-offset));
  transform: translateX(var(--rs-mat-stepper-lef-offset));

  .mat-step {
    @include rs-card();
    --rs-card--padding: 0;

    &:not(:last-child) {
      margin-bottom: var(--rs-mat-stepper-step-margin-bottom);
    }

    &:has(mat-step-header[ng-reflect-selected='false']) {
      .mat-vertical-content-container:has(.closed-step-content),
      .mat-vertical-stepper-content:has(.closed-step-content) {
        height: auto !important;
        visibility: visible !important;
      }

      &:has(ct-disabled-step) {
        pointer-events: none;
        box-shadow: none;
        --mat-stepper-header-label-text-color: var(--rs-ds--text-disabled);

        .mat-step-icon {
          --mat-stepper-header-icon-background-color: transparent;
          --mat-stepper-header-icon-foreground-color: var(--rs-ds--text-disabled);
          border-color: var(--rs-ds--text-disabled);

          .mat-step-icon-content {
            display: none;
          }

          &:before {
            content: '\e145';
            font-family: 'Material Icons';
            font-size: 25px;
            @include rs-vertical-horizontal-align();
          }
        }
      }
    }

    .mat-vertical-stepper-header {
      height: auto;
      overflow: initial;
      padding: var(--rs-mat-stepper-header-y-spacing) var(--rs-mat-stepper-header-x-spacing);
      box-sizing: border-box;
      line-height: var(--rs-mat-stepper-header-label-line-height);

      &.cdk-keyboard-focused,
      &.cdk-program-focused {
        background-color: transparent;
      }

      &[ng-reflect-selected='true'] {
        cursor: default;
        pointer-events: none;

        [matripple] {
          display: none;
        }
      }

      .mat-step-label {
        width: 100%;

        .mat-step-text-label {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .mat-ripple {
        border-radius: var(--rs-card--border-radius);
      }

      ct-disabled-step {
        color: var(--rs-ds--text-disabled);
      }

      .mat-step-icon {
        font-size: 1.125rem;
        position: absolute;
        transform: translateX(calc(var(--rs-mat-stepper-vertical-line-left-align) - 60px));
        border: 2px solid var(--mat-stepper-header-icon-border-color);
        height: var(--rs-mat-stepper-icon-size);
        width: var(--rs-mat-stepper-icon-size);

        &.mat-step-icon-selected {
          border-color: var(--mat-stepper-header-selected-state-icon-border-color);
        }

        &.mat-step-icon-state-error {
          border: none;
          background-color: var(--rs-ds--color--alert);
          color: white;
        }

        &.mat-step-icon-state-edit:not(.mat-step-icon-selected),
        &.mat-step-icon-state-done {
          background-color: var(--rs-color--green--600) !important;
          border: none !important;
          color: white !important;

          mat-icon {
            font-size: 1.5rem;
            height: 1.5rem;
            width: 1.5rem;

            &:before {
              content: 'check';
            }
          }
        }
      }
    }

    .mat-vertical-content-container {
      margin-left: 0 !important;

      &.mat-stepper-vertical-line::before {
        left: var(--rs-mat-stepper-vertical-line-left-align);
        bottom: calc(8px - (var(--mat-stepper-header-height) + 22px) / 2);
        top: calc(8px - calc((var(--mat-stepper-header-height) - 58px) / 2));
      }

      .mat-vertical-content {
        &::before {
          content: '';
          display: block;
          height: 1px;
          background-color: var(--rs-ds--border-color);
          width: calc(100% - (var(--rs-mat-stepper-header-x-spacing) * 2));
          top: 0;
          position: absolute;
        }

        padding: 24px var(--rs-mat-stepper-content-x-spacing) var(--rs-mat-stepper-content-y-spacing)
          var(--rs-mat-stepper-content-x-spacing);
      }
    }

    ct-action-buttons {
      display: flex;
      width: 100%;
      justify-content: end;
      margin-top: 40px;
    }
  }
}
