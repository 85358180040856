:root {
  --mat-app-background-color: var(--sys-colors-background);
  --mat-app-text-color: var(--sys-colors-on-background);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
:root {
  --mat-ripple-color: color-mix(in srgb, var(--sys-colors-on-surface) 10%, transparent);
}
:root {
  --mat-option-selected-state-label-text-color: var(--sys-colors-on-secondary-container);
  --mat-option-label-text-color: var(--sys-colors-on-surface);
  --mat-option-hover-state-layer-color: color-mix(in srgb, var(--sys-colors-on-surface) 8%, transparent);
  --mat-option-focus-state-layer-color: color-mix(in srgb, var(--sys-colors-on-surface) 12%, transparent);
  --mat-option-selected-state-layer-color: var(--sys-colors-secondary-container);
  --mat-option-label-text-font: var(--sys-label-large-font);
  --mat-option-label-text-line-height: var(--sys-label-large-line-height);
  --mat-option-label-text-size: var(--sys-body-large-size);
  --mat-option-label-text-tracking: var(--sys-label-large-tracking);
  --mat-option-label-text-weight: var(--sys-body-large-weight);
}
:root {
  --mat-optgroup-label-text-color: var(--sys-colors-on-surface-variant);
  --mat-optgroup-label-text-font: var(--sys-title-small-font);
  --mat-optgroup-label-text-line-height: var(--sys-title-small-line-height);
  --mat-optgroup-label-text-size: var(--sys-title-small-size);
  --mat-optgroup-label-text-tracking: var(--sys-title-small-tracking);
  --mat-optgroup-label-text-weight: var(--sys-title-small-weight);
}
:root {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--sys-colors-primary);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--sys-colors-on-primary);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--sys-colors-on-surface-variant);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: var(--sys-colors-surface);
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
}
:root {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--sys-colors-primary);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
}
:root {
  --mat-app-background-color: var(--sys-colors-background);
  --mat-app-text-color: var(--sys-colors-on-background);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
:root {
  --mat-ripple-color: color-mix(in srgb, var(--sys-colors-on-surface) 10%, transparent);
}
:root {
  --mat-option-selected-state-label-text-color: var(--sys-colors-on-secondary-container);
  --mat-option-label-text-color: var(--sys-colors-on-surface);
  --mat-option-hover-state-layer-color: color-mix(in srgb, var(--sys-colors-on-surface) 8%, transparent);
  --mat-option-focus-state-layer-color: color-mix(in srgb, var(--sys-colors-on-surface) 12%, transparent);
  --mat-option-selected-state-layer-color: var(--sys-colors-secondary-container);
  --mat-option-label-text-font: var(--sys-label-large-font);
  --mat-option-label-text-line-height: var(--sys-label-large-line-height);
  --mat-option-label-text-size: var(--sys-body-large-size);
  --mat-option-label-text-tracking: var(--sys-label-large-tracking);
  --mat-option-label-text-weight: var(--sys-body-large-weight);
}
:root {
  --mat-optgroup-label-text-color: var(--sys-colors-on-surface-variant);
  --mat-optgroup-label-text-font: var(--sys-title-small-font);
  --mat-optgroup-label-text-line-height: var(--sys-title-small-line-height);
  --mat-optgroup-label-text-size: var(--sys-title-small-size);
  --mat-optgroup-label-text-tracking: var(--sys-title-small-tracking);
  --mat-optgroup-label-text-weight: var(--sys-title-small-weight);
}
:root {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--sys-colors-primary);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--sys-colors-on-primary);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--sys-colors-on-surface-variant);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: var(--sys-colors-surface);
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
}
:root {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--sys-colors-primary);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
}
:root {
  --mdc-elevated-card-container-color: var(--sys-colors-surface-container-low);
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 12px;
}
:root {
  --mdc-outlined-card-container-color: var(--sys-colors-surface);
  --mdc-outlined-card-outline-color: var(--sys-colors-outline-variant);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-shape: 12px;
  --mdc-outlined-card-outline-width: 1px;
}
:root {
  --mat-card-subtitle-text-color: var(--sys-colors-on-surface);
  --mat-card-title-text-font: var(--sys-title-large-font);
  --mat-card-title-text-line-height: var(--sys-title-large-line-height);
  --mat-card-title-text-size: var(--sys-title-large-size);
  --mat-card-title-text-tracking: var(--sys-title-large-tracking);
  --mat-card-title-text-weight: var(--sys-title-large-weight);
  --mat-card-subtitle-text-font: var(--sys-title-medium-font);
  --mat-card-subtitle-text-line-height: var(--sys-title-medium-line-height);
  --mat-card-subtitle-text-size: var(--sys-title-medium-size);
  --mat-card-subtitle-text-tracking: var(--sys-title-medium-tracking);
  --mat-card-subtitle-text-weight: var(--sys-title-medium-weight);
}
:root {
  --mdc-linear-progress-active-indicator-color: var(--sys-colors-primary);
  --mdc-linear-progress-track-color: var(--sys-colors-surface-variant);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}
:root {
  --mdc-plain-tooltip-container-color: var(--sys-colors-inverse-surface);
  --mdc-plain-tooltip-supporting-text-color: var(--sys-colors-inverse-on-surface);
  --mdc-plain-tooltip-supporting-text-line-height: var(--sys-body-small-line-height);
  --mdc-plain-tooltip-supporting-text-font: var(--sys-body-small-font);
  --mdc-plain-tooltip-supporting-text-size: var(--sys-body-small-size);
  --mdc-plain-tooltip-supporting-text-weight: var(--sys-body-small-weight);
  --mdc-plain-tooltip-supporting-text-tracking: var(--sys-body-small-tracking);
  --mdc-plain-tooltip-container-shape: 4px;
}
:root {
  --mdc-filled-text-field-caret-color: var(--sys-colors-primary);
  --mdc-filled-text-field-focus-active-indicator-color: var(--sys-colors-primary);
  --mdc-filled-text-field-focus-label-text-color: var(--sys-colors-primary);
  --mdc-filled-text-field-container-color: var(--sys-colors-surface-variant);
  --mdc-filled-text-field-disabled-container-color: color-mix(in srgb, var(--sys-colors-on-surface) 4%, transparent);
  --mdc-filled-text-field-label-text-color: var(--sys-colors-on-surface-variant);
  --mdc-filled-text-field-hover-label-text-color: var(--sys-colors-on-surface-variant);
  --mdc-filled-text-field-disabled-label-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mdc-filled-text-field-input-text-color: var(--sys-colors-on-surface);
  --mdc-filled-text-field-disabled-input-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mdc-filled-text-field-input-text-placeholder-color: var(--sys-colors-on-surface-variant);
  --mdc-filled-text-field-error-hover-label-text-color: var(--sys-colors-on-error-container);
  --mdc-filled-text-field-error-focus-label-text-color: var(--sys-colors-error);
  --mdc-filled-text-field-error-label-text-color: var(--sys-colors-error);
  --mdc-filled-text-field-active-indicator-color: var(--sys-colors-on-surface-variant);
  --mdc-filled-text-field-disabled-active-indicator-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mdc-filled-text-field-hover-active-indicator-color: var(--sys-colors-on-surface);
  --mdc-filled-text-field-error-active-indicator-color: var(--sys-colors-error);
  --mdc-filled-text-field-error-focus-active-indicator-color: var(--sys-colors-error);
  --mdc-filled-text-field-error-hover-active-indicator-color: var(--sys-colors-on-error-container);
  --mdc-filled-text-field-label-text-font: var(--sys-body-large-font);
  --mdc-filled-text-field-label-text-size: var(--sys-body-large-size);
  --mdc-filled-text-field-label-text-tracking: var(--sys-body-large-tracking);
  --mdc-filled-text-field-label-text-weight: var(--sys-body-large-weight);
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
}
:root {
  --mdc-outlined-text-field-caret-color: var(--sys-colors-primary);
  --mdc-outlined-text-field-focus-outline-color: var(--sys-colors-primary);
  --mdc-outlined-text-field-focus-label-text-color: var(--sys-colors-primary);
  --mdc-outlined-text-field-label-text-color: var(--sys-colors-on-surface-variant);
  --mdc-outlined-text-field-hover-label-text-color: var(--sys-colors-on-surface);
  --mdc-outlined-text-field-disabled-label-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mdc-outlined-text-field-input-text-color: var(--sys-colors-on-surface);
  --mdc-outlined-text-field-disabled-input-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mdc-outlined-text-field-input-text-placeholder-color: var(--sys-colors-on-surface-variant);
  --mdc-outlined-text-field-error-focus-label-text-color: var(--sys-colors-error);
  --mdc-outlined-text-field-error-label-text-color: var(--sys-colors-error);
  --mdc-outlined-text-field-error-hover-label-text-color: var(--sys-colors-on-error-container);
  --mdc-outlined-text-field-outline-color: var(--sys-colors-outline);
  --mdc-outlined-text-field-disabled-outline-color: color-mix(in srgb, var(--sys-colors-on-surface) 12%, transparent);
  --mdc-outlined-text-field-hover-outline-color: var(--sys-colors-on-surface);
  --mdc-outlined-text-field-error-focus-outline-color: var(--sys-colors-error);
  --mdc-outlined-text-field-error-hover-outline-color: var(--sys-colors-on-error-container);
  --mdc-outlined-text-field-error-outline-color: var(--sys-colors-error);
  --mdc-outlined-text-field-label-text-font: var(--sys-body-large-font);
  --mdc-outlined-text-field-label-text-size: var(--sys-body-large-size);
  --mdc-outlined-text-field-label-text-tracking: var(--sys-body-large-tracking);
  --mdc-outlined-text-field-label-text-weight: var(--sys-body-large-weight);
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}
:root {
  --mat-form-field-focus-select-arrow-color: var(--sys-colors-primary);
  --mat-form-field-disabled-input-text-placeholder-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-form-field-state-layer-color: var(--sys-colors-on-surface);
  --mat-form-field-error-text-color: var(--sys-colors-error);
  --mat-form-field-select-option-text-color: #1b1b1d;
  --mat-form-field-select-disabled-option-text-color: rgba(27, 27, 29, 0.38);
  --mat-form-field-leading-icon-color: var(--sys-colors-on-surface-variant);
  --mat-form-field-disabled-leading-icon-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-form-field-trailing-icon-color: var(--sys-colors-on-surface-variant);
  --mat-form-field-disabled-trailing-icon-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-form-field-error-focus-trailing-icon-color: var(--sys-colors-error);
  --mat-form-field-error-hover-trailing-icon-color: var(--sys-colors-on-error-container);
  --mat-form-field-error-trailing-icon-color: var(--sys-colors-error);
  --mat-form-field-enabled-select-arrow-color: var(--sys-colors-on-surface-variant);
  --mat-form-field-disabled-select-arrow-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-container-text-font: var(--sys-body-large-font);
  --mat-form-field-container-text-line-height: var(--sys-body-large-line-height);
  --mat-form-field-container-text-size: var(--sys-body-large-size);
  --mat-form-field-container-text-tracking: var(--sys-body-large-tracking);
  --mat-form-field-container-text-weight: var(--sys-body-large-weight);
  --mat-form-field-subscript-text-font: var(--sys-body-small-font);
  --mat-form-field-subscript-text-line-height: var(--sys-body-small-line-height);
  --mat-form-field-subscript-text-size: var(--sys-body-small-size);
  --mat-form-field-subscript-text-tracking: var(--sys-body-small-tracking);
  --mat-form-field-subscript-text-weight: var(--sys-body-small-weight);
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
  --mat-form-field-focus-state-layer-opacity: 0;
}
:root {
  --mat-select-panel-background-color: var(--sys-colors-surface-container);
  --mat-select-enabled-trigger-text-color: var(--sys-colors-on-surface);
  --mat-select-disabled-trigger-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-select-placeholder-text-color: var(--sys-colors-on-surface-variant);
  --mat-select-enabled-arrow-color: var(--sys-colors-on-surface-variant);
  --mat-select-disabled-arrow-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-select-focused-arrow-color: var(--sys-colors-primary);
  --mat-select-invalid-arrow-color: var(--sys-colors-error);
  --mat-select-trigger-text-font: var(--sys-body-large-font);
  --mat-select-trigger-text-line-height: var(--sys-body-large-line-height);
  --mat-select-trigger-text-size: var(--sys-body-large-size);
  --mat-select-trigger-text-tracking: var(--sys-body-large-tracking);
  --mat-select-trigger-text-weight: var(--sys-body-large-weight);
  --mat-select-arrow-transform: translateY(-8px);
  --mat-select-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
:root {
  --mat-autocomplete-background-color: var(--sys-colors-surface-container);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
:root {
  --mdc-dialog-container-color: var(--sys-colors-surface);
  --mdc-dialog-subhead-color: var(--sys-colors-on-surface);
  --mdc-dialog-supporting-text-color: var(--sys-colors-on-surface-variant);
  --mdc-dialog-subhead-font: var(--sys-headline-small-font);
  --mdc-dialog-subhead-line-height: var(--sys-headline-small-line-height);
  --mdc-dialog-subhead-size: var(--sys-headline-small-size);
  --mdc-dialog-subhead-weight: var(--sys-headline-small-weight);
  --mdc-dialog-subhead-tracking: var(--sys-headline-small-tracking);
  --mdc-dialog-supporting-text-font: var(--sys-body-medium-font);
  --mdc-dialog-supporting-text-line-height: var(--sys-body-medium-line-height);
  --mdc-dialog-supporting-text-size: var(--sys-body-medium-size);
  --mdc-dialog-supporting-text-weight: var(--sys-body-medium-weight);
  --mdc-dialog-supporting-text-tracking: var(--sys-body-medium-tracking);
  --mdc-dialog-container-shape: 28px;
}
:root {
  --mat-dialog-container-elevation-shadow: none;
  --mat-dialog-container-max-width: 560px;
  --mat-dialog-container-small-max-width: calc(100vw - 32px);
  --mat-dialog-container-min-width: 280px;
  --mat-dialog-actions-alignment: flex-end;
  --mat-dialog-actions-padding: 16px 24px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px 0;
  --mat-dialog-headline-padding: 6px 24px 13px;
}
:root {
  --mdc-chip-outline-color: var(--sys-colors-outline);
  --mdc-chip-disabled-outline-color: color-mix(in srgb, var(--sys-colors-on-surface) 12%, transparent);
  --mdc-chip-focus-outline-color: var(--sys-colors-on-surface-variant);
  --mdc-chip-hover-state-layer-opacity: 0.08;
  --mdc-chip-selected-hover-state-layer-opacity: 0.08;
  --mdc-chip-disabled-label-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mdc-chip-elevated-selected-container-color: var(--sys-colors-secondary-container);
  --mdc-chip-flat-disabled-selected-container-color: color-mix(in srgb, var(--sys-colors-on-surface) 12%, transparent);
  --mdc-chip-focus-state-layer-color: var(--sys-colors-on-surface-variant);
  --mdc-chip-hover-state-layer-color: var(--sys-colors-on-surface-variant);
  --mdc-chip-selected-hover-state-layer-color: var(--sys-colors-on-secondary-container);
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: var(--sys-colors-on-secondary-container);
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--sys-colors-on-surface-variant);
  --mdc-chip-selected-label-text-color: var(--sys-colors-on-secondary-container);
  --mdc-chip-with-icon-icon-color: var(--sys-colors-on-surface-variant);
  --mdc-chip-with-icon-disabled-icon-color: var(--sys-colors-on-surface);
  --mdc-chip-with-icon-selected-icon-color: var(--sys-colors-on-secondary-container);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--sys-colors-on-surface);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--sys-colors-on-surface-variant);
  --mdc-chip-label-text-font: var(--sys-label-large-font);
  --mdc-chip-label-text-line-height: var(--sys-label-large-line-height);
  --mdc-chip-label-text-size: var(--sys-label-large-size);
  --mdc-chip-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-chip-label-text-weight: var(--sys-label-large-weight);
  --mdc-chip-container-height: 32px;
  --mdc-chip-container-shape-radius: 8px;
  --mdc-chip-with-avatar-avatar-shape-radius: 24px;
  --mdc-chip-with-avatar-avatar-size: 24px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 1px;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 0.38;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 0.38;
  --mdc-chip-with-icon-disabled-icon-opacity: 0.38;
  --mdc-chip-elevated-container-color: transparent;
}
:root {
  --mat-chip-trailing-action-state-layer-color: var(--sys-colors-on-surface-variant);
  --mat-chip-selected-trailing-action-state-layer-color: var(--sys-colors-on-secondary-container);
  --mat-chip-trailing-action-hover-state-layer-opacity: 0.08;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0.12;
  --mat-chip-selected-disabled-trailing-icon-color: var(--sys-colors-on-surface);
  --mat-chip-selected-trailing-icon-color: var(--sys-colors-on-secondary-container);
  --mat-chip-disabled-container-opacity: 1;
  --mat-chip-trailing-action-opacity: 1;
  --mat-chip-trailing-action-focus-opacity: 1;
}
:root {
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.08;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.08;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-selected-focus-state-layer-color: var(--sys-colors-primary);
  --mdc-switch-selected-handle-color: var(--sys-colors-on-primary);
  --mdc-switch-selected-hover-state-layer-color: var(--sys-colors-primary);
  --mdc-switch-selected-pressed-state-layer-color: var(--sys-colors-primary);
  --mdc-switch-selected-focus-handle-color: var(--sys-colors-primary-container);
  --mdc-switch-selected-hover-handle-color: var(--sys-colors-primary-container);
  --mdc-switch-selected-pressed-handle-color: var(--sys-colors-primary-container);
  --mdc-switch-selected-focus-track-color: var(--sys-colors-primary);
  --mdc-switch-selected-hover-track-color: var(--sys-colors-primary);
  --mdc-switch-selected-pressed-track-color: var(--sys-colors-primary);
  --mdc-switch-selected-track-color: var(--sys-colors-primary);
  --mdc-switch-disabled-selected-handle-color: var(--sys-colors-surface);
  --mdc-switch-disabled-selected-icon-color: var(--sys-colors-on-surface);
  --mdc-switch-disabled-selected-track-color: var(--sys-colors-on-surface);
  --mdc-switch-disabled-unselected-handle-color: var(--sys-colors-on-surface);
  --mdc-switch-disabled-unselected-icon-color: var(--sys-colors-surface-variant);
  --mdc-switch-disabled-unselected-track-color: var(--sys-colors-surface-variant);
  --mdc-switch-selected-icon-color: var(--sys-colors-on-primary-container);
  --mdc-switch-unselected-focus-handle-color: var(--sys-colors-on-surface-variant);
  --mdc-switch-unselected-focus-state-layer-color: var(--sys-colors-on-surface);
  --mdc-switch-unselected-focus-track-color: var(--sys-colors-surface-variant);
  --mdc-switch-unselected-handle-color: var(--sys-colors-outline);
  --mdc-switch-unselected-hover-handle-color: var(--sys-colors-on-surface-variant);
  --mdc-switch-unselected-hover-state-layer-color: var(--sys-colors-on-surface);
  --mdc-switch-unselected-hover-track-color: var(--sys-colors-surface-variant);
  --mdc-switch-unselected-icon-color: var(--sys-colors-surface-variant);
  --mdc-switch-unselected-pressed-handle-color: var(--sys-colors-on-surface-variant);
  --mdc-switch-unselected-pressed-state-layer-color: var(--sys-colors-on-surface);
  --mdc-switch-unselected-pressed-track-color: var(--sys-colors-surface-variant);
  --mdc-switch-unselected-track-color: var(--sys-colors-surface-variant);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-shape: 9999px;
  --mdc-switch-selected-icon-size: 16px;
  --mdc-switch-track-height: 32px;
  --mdc-switch-track-shape: 9999px;
  --mdc-switch-track-width: 52px;
  --mdc-switch-unselected-icon-size: 16px;
  --mdc-switch-state-layer-size: 40px;
}
:root {
  --mat-switch-track-outline-color: var(--sys-colors-outline);
  --mat-switch-disabled-unselected-track-outline-color: var(--sys-colors-on-surface);
  --mat-switch-label-text-color: var(--sys-colors-on-surface);
  --mat-switch-label-text-font: var(--sys-body-medium-font);
  --mat-switch-label-text-line-height: var(--sys-body-medium-line-height);
  --mat-switch-label-text-size: var(--sys-body-medium-size);
  --mat-switch-label-text-tracking: var(--sys-body-medium-tracking);
  --mat-switch-label-text-weight: var(--sys-body-medium-weight);
  --mat-switch-disabled-selected-handle-opacity: 1;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 16px;
  --mat-switch-selected-handle-size: 24px;
  --mat-switch-pressed-handle-size: 28px;
  --mat-switch-with-icon-handle-size: 24px;
  --mat-switch-selected-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 22px;
  --mat-switch-unselected-handle-horizontal-margin: 0 8px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 4px;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0 2px;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 0;
  --mat-switch-visible-track-transition: opacity 75ms;
  --mat-switch-hidden-track-transition: opacity 75ms;
  --mat-switch-track-outline-width: 2px;
  --mat-switch-selected-track-outline-width: 2px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 2px;
}
:root {
  --mdc-radio-disabled-selected-icon-color: var(--sys-colors-on-surface);
  --mdc-radio-disabled-unselected-icon-color: var(--sys-colors-on-surface);
  --mdc-radio-unselected-hover-icon-color: var(--sys-colors-on-surface);
  --mdc-radio-unselected-focus-icon-color: var(--sys-colors-on-surface);
  --mdc-radio-unselected-icon-color: var(--sys-colors-on-surface-variant);
  --mdc-radio-unselected-pressed-icon-color: var(--sys-colors-on-surface);
  --mdc-radio-selected-focus-icon-color: var(--sys-colors-primary);
  --mdc-radio-selected-hover-icon-color: var(--sys-colors-primary);
  --mdc-radio-selected-icon-color: var(--sys-colors-primary);
  --mdc-radio-selected-pressed-icon-color: var(--sys-colors-primary);
  --mdc-radio-state-layer-size: 40px;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
}
:root {
  --mat-radio-ripple-color: var(--sys-colors-on-surface);
  --mat-radio-checked-ripple-color: var(--sys-colors-primary);
  --mat-radio-disabled-label-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-radio-label-text-color: var(--sys-colors-on-surface);
  --mat-radio-label-text-font: var(--sys-body-medium-font);
  --mat-radio-label-text-line-height: var(--sys-body-medium-line-height);
  --mat-radio-label-text-size: var(--sys-body-medium-size);
  --mat-radio-label-text-tracking: var(--sys-body-medium-tracking);
  --mat-radio-label-text-weight: var(--sys-body-medium-weight);
  --mat-radio-touch-target-display: block;
}
:root {
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-slider-handle-color: var(--sys-colors-primary);
  --mdc-slider-focus-handle-color: var(--sys-colors-primary);
  --mdc-slider-hover-handle-color: var(--sys-colors-primary);
  --mdc-slider-active-track-color: var(--sys-colors-primary);
  --mdc-slider-inactive-track-color: var(--sys-colors-surface-variant);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--sys-colors-on-surface-variant);
  --mdc-slider-with-tick-marks-active-container-color: var(--sys-colors-on-primary);
  --mdc-slider-disabled-active-track-color: var(--sys-colors-on-surface);
  --mdc-slider-disabled-handle-color: var(--sys-colors-on-surface);
  --mdc-slider-disabled-inactive-track-color: var(--sys-colors-on-surface);
  --mdc-slider-label-container-color: var(--sys-colors-primary);
  --mdc-slider-label-label-text-color: var(--sys-colors-on-primary);
  --mdc-slider-with-overlap-handle-outline-color: var(--sys-colors-on-primary);
  --mdc-slider-with-tick-marks-disabled-container-color: var(--sys-colors-on-surface);
  --mdc-slider-label-label-text-font: var(--sys-label-medium-font);
  --mdc-slider-label-label-text-size: var(--sys-label-medium-size);
  --mdc-slider-label-label-text-line-height: var(--sys-label-medium-line-height);
  --mdc-slider-label-label-text-tracking: var(--sys-label-medium-tracking);
  --mdc-slider-label-label-text-weight: var(--sys-label-medium-weight);
  --mdc-slider-active-track-height: 4px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 9999px;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.38;
  --mdc-slider-with-tick-marks-container-shape: 9999px;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.38;
}
:root {
  --mat-slider-ripple-color: var(--sys-colors-primary);
  --mat-slider-hover-state-layer-color: color-mix(in srgb, var(--sys-colors-primary) 5%, transparent);
  --mat-slider-focus-state-layer-color: color-mix(in srgb, var(--sys-colors-primary) 20%, transparent);
  --mat-slider-value-indicator-width: 28px;
  --mat-slider-value-indicator-height: 28px;
  --mat-slider-value-indicator-caret-display: none;
  --mat-slider-value-indicator-border-radius: 50% 50% 50% 0;
  --mat-slider-value-indicator-padding: 0;
  --mat-slider-value-indicator-text-transform: rotate(45deg);
  --mat-slider-value-indicator-container-transform: translateX(-50%) rotate(-45deg);
  --mat-slider-value-indicator-opacity: 1;
}
:root {
  --mat-menu-item-label-text-color: var(--sys-colors-on-surface);
  --mat-menu-item-icon-color: var(--sys-colors-on-surface-variant);
  --mat-menu-item-hover-state-layer-color: color-mix(in srgb, var(--sys-colors-on-surface) 8%, transparent);
  --mat-menu-item-focus-state-layer-color: color-mix(in srgb, var(--sys-colors-on-surface) 12%, transparent);
  --mat-menu-container-color: var(--sys-colors-surface-container);
  --mat-menu-divider-color: var(--sys-colors-surface-variant);
  --mat-menu-item-label-text-font: var(--sys-label-large-font);
  --mat-menu-item-label-text-size: var(--sys-label-large-size);
  --mat-menu-item-label-text-tracking: var(--sys-label-large-tracking);
  --mat-menu-item-label-text-line-height: var(--sys-label-large-line-height);
  --mat-menu-item-label-text-weight: var(--sys-label-large-weight);
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 8px;
  --mat-menu-divider-top-spacing: 8px;
  --mat-menu-item-spacing: 12px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 12px;
  --mat-menu-item-trailing-spacing: 12px;
  --mat-menu-item-with-icon-leading-spacing: 12px;
  --mat-menu-item-with-icon-trailing-spacing: 12px;
  --mat-menu-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
:root {
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: var(--sys-colors-primary-container);
  --mdc-list-list-item-disabled-state-layer-color: var(--sys-colors-on-surface);
  --mdc-list-list-item-disabled-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-color: var(--sys-colors-on-surface);
  --mdc-list-list-item-supporting-text-color: var(--sys-colors-on-surface-variant);
  --mdc-list-list-item-leading-icon-color: var(--sys-colors-on-surface-variant);
  --mdc-list-list-item-trailing-supporting-text-color: var(--sys-colors-on-surface-variant);
  --mdc-list-list-item-trailing-icon-color: var(--sys-colors-on-surface-variant);
  --mdc-list-list-item-selected-trailing-icon-color: var(--sys-colors-primary);
  --mdc-list-list-item-disabled-label-text-color: var(--sys-colors-on-surface);
  --mdc-list-list-item-disabled-leading-icon-color: var(--sys-colors-on-surface);
  --mdc-list-list-item-disabled-trailing-icon-color: var(--sys-colors-on-surface);
  --mdc-list-list-item-hover-label-text-color: var(--sys-colors-on-surface);
  --mdc-list-list-item-focus-label-text-color: var(--sys-colors-on-surface);
  --mdc-list-list-item-hover-state-layer-color: var(--sys-colors-on-surface);
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: var(--sys-colors-on-surface);
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-font: var(--sys-body-large-font);
  --mdc-list-list-item-label-text-line-height: var(--sys-body-large-line-height);
  --mdc-list-list-item-label-text-size: var(--sys-body-large-size);
  --mdc-list-list-item-label-text-tracking: var(--sys-body-large-tracking);
  --mdc-list-list-item-label-text-weight: var(--sys-body-large-weight);
  --mdc-list-list-item-supporting-text-font: var(--sys-body-medium-font);
  --mdc-list-list-item-supporting-text-line-height: var(--sys-body-medium-line-height);
  --mdc-list-list-item-supporting-text-size: var(--sys-body-medium-size);
  --mdc-list-list-item-supporting-text-tracking: var(--sys-body-medium-tracking);
  --mdc-list-list-item-supporting-text-weight: var(--sys-body-medium-weight);
  --mdc-list-list-item-trailing-supporting-text-font: var(--sys-label-small-font);
  --mdc-list-list-item-trailing-supporting-text-line-height: var(--sys-label-small-line-height);
  --mdc-list-list-item-trailing-supporting-text-size: var(--sys-label-small-size);
  --mdc-list-list-item-trailing-supporting-text-tracking: var(--sys-label-small-tracking);
  --mdc-list-list-item-trailing-supporting-text-weight: var(--sys-label-small-weight);
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 9999px;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-label-text-opacity: 0.3;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}
:root {
  --mat-list-active-indicator-color: var(--sys-colors-secondary-container);
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 16px;
  --mat-list-active-indicator-shape: 9999px;
}
:root {
  --mat-paginator-container-text-color: var(--sys-colors-on-surface);
  --mat-paginator-container-background-color: var(--sys-colors-surface);
  --mat-paginator-enabled-icon-color: var(--sys-colors-on-surface-variant);
  --mat-paginator-disabled-icon-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-paginator-container-text-font: var(--sys-body-small-font);
  --mat-paginator-container-text-line-height: var(--sys-body-small-line-height);
  --mat-paginator-container-text-size: var(--sys-body-small-size);
  --mat-paginator-container-text-tracking: var(--sys-body-small-tracking);
  --mat-paginator-container-text-weight: var(--sys-body-small-weight);
  --mat-paginator-select-trigger-text-size: var(--sys-body-small-size);
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}
:root {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
:root {
  --mdc-tab-indicator-active-indicator-color: var(--sys-colors-primary);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
}
:root {
  --mat-tab-header-divider-color: var(--sys-colors-surface-variant);
  --mat-tab-header-pagination-icon-color: var(--sys-colors-on-surface);
  --mat-tab-header-inactive-label-text-color: var(--sys-colors-on-surface);
  --mat-tab-header-active-label-text-color: var(--sys-colors-on-surface);
  --mat-tab-header-active-ripple-color: var(--sys-colors-on-surface);
  --mat-tab-header-inactive-ripple-color: var(--sys-colors-on-surface);
  --mat-tab-header-inactive-focus-label-text-color: var(--sys-colors-on-surface);
  --mat-tab-header-inactive-hover-label-text-color: var(--sys-colors-on-surface);
  --mat-tab-header-active-focus-label-text-color: var(--sys-colors-on-surface);
  --mat-tab-header-active-hover-label-text-color: var(--sys-colors-on-surface);
  --mat-tab-header-active-focus-indicator-color: var(--sys-colors-primary);
  --mat-tab-header-active-hover-indicator-color: var(--sys-colors-primary);
  --mat-tab-header-label-text-font: var(--sys-title-small-font);
  --mat-tab-header-label-text-size: var(--sys-title-small-size);
  --mat-tab-header-label-text-tracking: var(--sys-title-small-tracking);
  --mat-tab-header-label-text-line-height: var(--sys-title-small-line-height);
  --mat-tab-header-label-text-weight: var(--sys-title-small-weight);
  --mat-tab-header-divider-height: 1px;
}
:root {
  --mdc-checkbox-disabled-selected-checkmark-color: var(--sys-colors-surface);
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-colors-primary);
  --mdc-checkbox-disabled-selected-icon-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mdc-checkbox-disabled-unselected-icon-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mdc-checkbox-selected-checkmark-color: var(--sys-colors-on-primary);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-colors-primary);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-colors-primary);
  --mdc-checkbox-selected-icon-color: var(--sys-colors-primary);
  --mdc-checkbox-unselected-focus-icon-color: var(--sys-colors-on-surface);
  --mdc-checkbox-unselected-hover-icon-color: var(--sys-colors-on-surface);
  --mdc-checkbox-unselected-icon-color: var(--sys-colors-on-surface-variant);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-colors-primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-colors-primary);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--sys-colors-on-surface);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--sys-colors-on-surface);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--sys-colors-on-surface);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-colors-primary);
  --mdc-checkbox-state-layer-size: 40px;
}
:root {
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-colors-on-surface);
  --mat-checkbox-label-text-font: var(--sys-body-medium-font);
  --mat-checkbox-label-text-line-height: var(--sys-body-medium-line-height);
  --mat-checkbox-label-text-size: var(--sys-body-medium-size);
  --mat-checkbox-label-text-tracking: var(--sys-body-medium-tracking);
  --mat-checkbox-label-text-weight: var(--sys-body-medium-weight);
  --mat-checkbox-touch-target-display: block;
}
:root {
  --mdc-text-button-label-text-color: var(--sys-colors-primary);
  --mdc-text-button-disabled-label-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mdc-text-button-label-text-font: var(--sys-label-large-font);
  --mdc-text-button-label-text-size: var(--sys-label-large-size);
  --mdc-text-button-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-text-button-label-text-weight: var(--sys-label-large-weight);
  --mdc-text-button-container-height: 40px;
  --mdc-text-button-container-shape: 9999px;
}
:root {
  --mdc-protected-button-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-color: var(--sys-colors-surface);
  --mdc-protected-button-label-text-color: var(--sys-colors-primary);
  --mdc-protected-button-disabled-container-color: color-mix(in srgb, var(--sys-colors-on-surface) 12%, transparent);
  --mdc-protected-button-disabled-label-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mdc-protected-button-label-text-font: var(--sys-label-large-font);
  --mdc-protected-button-label-text-size: var(--sys-label-large-size);
  --mdc-protected-button-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-protected-button-label-text-weight: var(--sys-label-large-weight);
  --mdc-protected-button-container-height: 40px;
  --mdc-protected-button-container-shape: 9999px;
}
:root {
  --mdc-filled-button-container-color: var(--sys-colors-primary);
  --mdc-filled-button-label-text-color: var(--sys-colors-on-primary);
  --mdc-filled-button-disabled-container-color: color-mix(in srgb, var(--sys-colors-on-surface) 12%, transparent);
  --mdc-filled-button-disabled-label-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mdc-filled-button-label-text-font: var(--sys-label-large-font);
  --mdc-filled-button-label-text-size: var(--sys-label-large-size);
  --mdc-filled-button-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-filled-button-label-text-weight: var(--sys-label-large-weight);
  --mdc-filled-button-container-height: 40px;
  --mdc-filled-button-container-shape: 9999px;
}
:root {
  --mdc-outlined-button-disabled-outline-color: color-mix(in srgb, var(--sys-colors-on-surface) 12%, transparent);
  --mdc-outlined-button-disabled-label-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mdc-outlined-button-label-text-color: var(--sys-colors-primary);
  --mdc-outlined-button-outline-color: var(--sys-colors-outline);
  --mdc-outlined-button-label-text-font: var(--sys-label-large-font);
  --mdc-outlined-button-label-text-size: var(--sys-label-large-size);
  --mdc-outlined-button-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-outlined-button-label-text-weight: var(--sys-label-large-weight);
  --mdc-outlined-button-container-height: 40px;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 9999px;
}
:root {
  --mat-text-button-state-layer-color: var(--sys-colors-primary);
  --mat-text-button-disabled-state-layer-color: var(--sys-colors-on-surface-variant);
  --mat-text-button-ripple-color: color-mix(in srgb, var(--sys-colors-primary) 12%, transparent);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mat-text-button-touch-target-display: block;
  --mat-text-button-horizontal-padding: 12px;
  --mat-text-button-with-icon-horizontal-padding: 16px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: -4px;
}
:root {
  --mat-protected-button-state-layer-color: var(--sys-colors-primary);
  --mat-protected-button-disabled-state-layer-color: var(--sys-colors-on-surface-variant);
  --mat-protected-button-ripple-color: color-mix(in srgb, var(--sys-colors-primary) 12%, transparent);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mat-protected-button-touch-target-display: block;
  --mat-protected-button-horizontal-padding: 24px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -8px;
}
:root {
  --mat-filled-button-state-layer-color: var(--sys-colors-on-primary);
  --mat-filled-button-disabled-state-layer-color: var(--sys-colors-on-surface-variant);
  --mat-filled-button-ripple-color: color-mix(in srgb, var(--sys-colors-on-primary) 12%, transparent);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mat-filled-button-touch-target-display: block;
  --mat-filled-button-horizontal-padding: 24px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -8px;
}
:root {
  --mat-outlined-button-state-layer-color: var(--sys-colors-primary);
  --mat-outlined-button-disabled-state-layer-color: var(--sys-colors-on-surface-variant);
  --mat-outlined-button-ripple-color: color-mix(in srgb, var(--sys-colors-primary) 12%, transparent);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mat-outlined-button-touch-target-display: block;
  --mat-outlined-button-horizontal-padding: 24px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -8px;
}
:root {
  --mdc-icon-button-icon-color: var(--sys-colors-on-surface-variant);
  --mdc-icon-button-disabled-icon-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mdc-icon-button-state-layer-size: 40px;
  --mdc-icon-button-icon-size: 24px;
}
:root {
  --mat-icon-button-state-layer-color: var(--sys-colors-on-surface-variant);
  --mat-icon-button-disabled-state-layer-color: var(--sys-colors-on-surface-variant);
  --mat-icon-button-ripple-color: color-mix(in srgb, var(--sys-colors-on-surface-variant) 12%, transparent);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mat-icon-button-touch-target-display: block;
}
:root {
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-label-text-font: var(--sys-label-large-font);
  --mdc-extended-fab-label-text-size: var(--sys-label-large-size);
  --mdc-extended-fab-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-extended-fab-label-text-weight: var(--sys-label-large-weight);
  --mdc-extended-fab-container-height: 56px;
  --mdc-extended-fab-container-shape: 16px;
}
:root {
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: var(--sys-colors-primary-container);
  --mdc-fab-container-shape: 16px;
}
:root {
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-color: var(--sys-colors-primary-container);
  --mdc-fab-small-container-shape: 12px;
}
:root {
  --mat-fab-foreground-color: var(--sys-colors-on-primary-container);
  --mat-fab-state-layer-color: var(--sys-colors-on-primary-container);
  --mat-fab-ripple-color: color-mix(in srgb, var(--sys-colors-on-primary-container) 12%, transparent);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: color-mix(in srgb, var(--sys-colors-on-surface) 12%, transparent);
  --mat-fab-disabled-state-foreground-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-fab-touch-target-display: block;
}
:root {
  --mat-fab-small-foreground-color: var(--sys-colors-on-primary-container);
  --mat-fab-small-state-layer-color: var(--sys-colors-on-primary-container);
  --mat-fab-small-ripple-color: color-mix(in srgb, var(--sys-colors-on-primary-container) 12%, transparent);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: color-mix(in srgb, var(--sys-colors-on-surface) 12%, transparent);
  --mat-fab-small-disabled-state-foreground-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
}
:root {
  --mdc-snackbar-container-color: var(--sys-colors-inverse-surface);
  --mdc-snackbar-supporting-text-color: var(--sys-colors-inverse-on-surface);
  --mdc-snackbar-supporting-text-font: var(--sys-body-medium-font);
  --mdc-snackbar-supporting-text-line-height: var(--sys-body-medium-line-height);
  --mdc-snackbar-supporting-text-size: var(--sys-body-medium-size);
  --mdc-snackbar-supporting-text-weight: var(--sys-body-medium-weight);
  --mdc-snackbar-container-shape: 4px;
}
:root {
  --mat-snack-bar-button-color: var(--sys-colors-inverse-primary);
}
:root {
  --mat-table-background-color: var(--sys-colors-surface);
  --mat-table-header-headline-color: var(--sys-colors-on-surface);
  --mat-table-row-item-label-text-color: var(--sys-colors-on-surface);
  --mat-table-row-item-outline-color: var(--sys-colors-outline);
  --mat-table-header-headline-font: var(--sys-title-small-font);
  --mat-table-header-headline-line-height: var(--sys-title-small-line-height);
  --mat-table-header-headline-size: var(--sys-title-small-size);
  --mat-table-header-headline-weight: var(--sys-title-small-weight);
  --mat-table-header-headline-tracking: var(--sys-title-small-tracking);
  --mat-table-row-item-label-text-font: var(--sys-body-medium-font);
  --mat-table-row-item-label-text-line-height: var(--sys-body-medium-line-height);
  --mat-table-row-item-label-text-size: var(--sys-body-medium-size);
  --mat-table-row-item-label-text-weight: var(--sys-body-medium-weight);
  --mat-table-row-item-label-text-tracking: var(--sys-body-medium-tracking);
  --mat-table-footer-supporting-text-font: var(--sys-body-medium-font);
  --mat-table-footer-supporting-text-line-height: var(--sys-body-medium-line-height);
  --mat-table-footer-supporting-text-size: var(--sys-body-medium-size);
  --mat-table-footer-supporting-text-weight: var(--sys-body-medium-weight);
  --mat-table-footer-supporting-text-tracking: var(--sys-body-medium-tracking);
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
  --mat-table-row-item-outline-width: 1px;
}
:root {
  --mdc-circular-progress-active-indicator-color: var(--sys-colors-primary);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}
:root {
  --mat-badge-background-color: var(--sys-colors-error);
  --mat-badge-text-color: var(--sys-colors-on-error);
  --mat-badge-disabled-state-background-color: color-mix(in srgb, var(--sys-colors-error) 38%, transparent);
  --mat-badge-disabled-state-text-color: var(--sys-colors-on-error);
  --mat-badge-text-font: var(--sys-label-small-font);
  --mat-badge-text-size: var(--sys-label-small-size);
  --mat-badge-text-weight: var(--sys-label-small-weight);
  --mat-badge-large-size-text-size: var(--sys-label-small-size);
  --mat-badge-container-shape: 9999px;
  --mat-badge-container-size: 16px;
  --mat-badge-small-size-container-size: 6px;
  --mat-badge-large-size-container-size: 16px;
  --mat-badge-legacy-container-size: unset;
  --mat-badge-legacy-small-size-container-size: unset;
  --mat-badge-legacy-large-size-container-size: unset;
  --mat-badge-container-offset: -12px 0;
  --mat-badge-small-size-container-offset: -6px 0;
  --mat-badge-large-size-container-offset: -12px 0;
  --mat-badge-container-overlap-offset: -12px;
  --mat-badge-small-size-container-overlap-offset: -6px;
  --mat-badge-large-size-container-overlap-offset: -12px;
  --mat-badge-container-padding: 0 4px;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0 4px;
  --mat-badge-line-height: 16px;
  --mat-badge-small-size-text-size: 0;
  --mat-badge-small-size-line-height: 6px;
  --mat-badge-large-size-line-height: 16px;
}
:root {
  --mat-bottom-sheet-container-text-color: var(--sys-colors-on-surface);
  --mat-bottom-sheet-container-background-color: var(--sys-colors-surface-container-low);
  --mat-bottom-sheet-container-text-font: var(--sys-body-large-font);
  --mat-bottom-sheet-container-text-line-height: var(--sys-body-large-line-height);
  --mat-bottom-sheet-container-text-size: var(--sys-body-large-size);
  --mat-bottom-sheet-container-text-tracking: var(--sys-body-large-tracking);
  --mat-bottom-sheet-container-text-weight: var(--sys-body-large-weight);
  --mat-bottom-sheet-container-shape: 28px;
}
:root {
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.08;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-standard-button-toggle-text-color: var(--sys-colors-on-surface);
  --mat-standard-button-toggle-state-layer-color: var(--sys-colors-on-surface);
  --mat-standard-button-toggle-selected-state-background-color: var(--sys-colors-secondary-container);
  --mat-standard-button-toggle-selected-state-text-color: var(--sys-colors-on-secondary-container);
  --mat-standard-button-toggle-disabled-state-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-standard-button-toggle-disabled-selected-state-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-standard-button-toggle-disabled-selected-state-background-color: color-mix(in srgb, var(--sys-colors-on-surface) 12%, transparent);
  --mat-standard-button-toggle-divider-color: var(--sys-colors-outline);
  --mat-standard-button-toggle-label-text-font: var(--sys-label-large-font);
  --mat-standard-button-toggle-label-text-line-height: var(--sys-label-large-line-height);
  --mat-standard-button-toggle-label-text-size: var(--sys-label-large-size);
  --mat-standard-button-toggle-label-text-tracking: var(--sys-label-large-tracking);
  --mat-standard-button-toggle-label-text-weight: var(--sys-label-large-weight);
  --mat-standard-button-toggle-height: 40px;
  --mat-standard-button-toggle-shape: 9999px;
  --mat-standard-button-toggle-background-color: transparent;
  --mat-standard-button-toggle-disabled-state-background-color: transparent;
}
:root {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--sys-colors-on-primary);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--sys-colors-primary);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--sys-colors-primary);
  --mat-datepicker-calendar-date-focus-state-background-color: color-mix(in srgb, var(--sys-colors-on-surface) 12%, transparent);
  --mat-datepicker-calendar-date-hover-state-background-color: color-mix(in srgb, var(--sys-colors-on-surface) 8%, transparent);
  --mat-datepicker-toggle-active-state-icon-color: var(--sys-colors-on-surface-variant);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--sys-colors-primary-container);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: var(--sys-colors-tertiary-container);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: var(--sys-colors-secondary-container);
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: var(--sys-colors-secondary);
  --mat-datepicker-toggle-icon-color: var(--sys-colors-on-surface-variant);
  --mat-datepicker-calendar-body-label-text-color: var(--sys-colors-on-surface);
  --mat-datepicker-calendar-period-button-text-color: var(--sys-colors-on-surface-variant);
  --mat-datepicker-calendar-period-button-icon-color: var(--sys-colors-on-surface-variant);
  --mat-datepicker-calendar-navigation-button-icon-color: var(--sys-colors-on-surface-variant);
  --mat-datepicker-calendar-header-text-color: var(--sys-colors-on-surface-variant);
  --mat-datepicker-calendar-date-today-outline-color: var(--sys-colors-primary);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-datepicker-calendar-date-text-color: var(--sys-colors-on-surface);
  --mat-datepicker-calendar-date-disabled-state-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--sys-colors-primary);
  --mat-datepicker-range-input-separator-color: var(--sys-colors-on-surface);
  --mat-datepicker-range-input-disabled-state-separator-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-datepicker-range-input-disabled-state-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-datepicker-calendar-container-background-color: var(--sys-colors-surface-container-high);
  --mat-datepicker-calendar-container-text-color: var(--sys-colors-on-surface);
  --mat-datepicker-calendar-text-font: var(--sys-body-medium-font);
  --mat-datepicker-calendar-text-size: var(--sys-body-medium-size);
  --mat-datepicker-calendar-body-label-text-size: var(--sys-title-small-size);
  --mat-datepicker-calendar-body-label-text-weight: var(--sys-title-small-weight);
  --mat-datepicker-calendar-period-button-text-size: var(--sys-title-small-size);
  --mat-datepicker-calendar-period-button-text-weight: var(--sys-title-small-weight);
  --mat-datepicker-calendar-header-text-size: var(--sys-title-small-size);
  --mat-datepicker-calendar-header-text-weight: var(--sys-title-small-weight);
  --mat-datepicker-calendar-container-shape: 16px;
  --mat-datepicker-calendar-container-touch-shape: 28px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-divider-color: transparent;
  --mat-datepicker-calendar-date-outline-color: transparent;
}
:root {
  --mat-divider-color: var(--sys-colors-outline);
  --mat-divider-width: 1px;
}
:root {
  --mat-expansion-container-background-color: var(--sys-colors-surface);
  --mat-expansion-container-text-color: var(--sys-colors-on-surface);
  --mat-expansion-actions-divider-color: var(--sys-colors-outline);
  --mat-expansion-header-hover-state-layer-color: color-mix(in srgb, var(--sys-colors-on-surface) 8%, transparent);
  --mat-expansion-header-focus-state-layer-color: color-mix(in srgb, var(--sys-colors-on-surface) 12%, transparent);
  --mat-expansion-header-disabled-state-text-color: color-mix(in srgb, var(--sys-colors-on-surface) 38%, transparent);
  --mat-expansion-header-text-color: var(--sys-colors-on-surface);
  --mat-expansion-header-description-color: var(--sys-colors-on-surface-variant);
  --mat-expansion-header-indicator-color: var(--sys-colors-on-surface-variant);
  --mat-expansion-header-text-font: var(--sys-title-medium-font);
  --mat-expansion-header-text-size: var(--sys-title-medium-size);
  --mat-expansion-header-text-weight: var(--sys-title-medium-weight);
  --mat-expansion-header-text-line-height: var(--sys-title-medium-line-height);
  --mat-expansion-header-text-tracking: var(--sys-title-medium-tracking);
  --mat-expansion-container-text-font: var(--sys-body-large-font);
  --mat-expansion-container-text-line-height: var(--sys-body-large-line-height);
  --mat-expansion-container-text-size: var(--sys-body-large-size);
  --mat-expansion-container-text-tracking: var(--sys-body-large-tracking);
  --mat-expansion-container-text-weight: var(--sys-body-large-weight);
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
  --mat-expansion-container-shape: 12px;
  --mat-expansion-legacy-header-indicator-display: none;
  --mat-expansion-header-indicator-display: inline-block;
}
:root {
  --mat-grid-list-tile-header-primary-text-size: var(--sys-body-large);
  --mat-grid-list-tile-header-secondary-text-size: var(--sys-body-medium);
  --mat-grid-list-tile-footer-primary-text-size: var(--sys-body-large);
  --mat-grid-list-tile-footer-secondary-text-size: var(--sys-body-medium);
}
:root {
  --mat-icon-color: inherit;
}
:root {
  --mat-sidenav-container-background-color: var(--sys-colors-surface);
  --mat-sidenav-container-text-color: var(--sys-colors-on-surface-variant);
  --mat-sidenav-content-background-color: var(--sys-colors-background);
  --mat-sidenav-content-text-color: var(--sys-colors-on-background);
  --mat-sidenav-scrim-color: rgba(62, 44, 37, 0.4);
  --mat-sidenav-container-shape: 16px;
  --mat-sidenav-container-elevation-shadow: none;
  --mat-sidenav-container-width: 360px;
  --mat-sidenav-container-divider-color: transparent;
}
:root {
  --mat-stepper-header-icon-foreground-color: var(--sys-colors-surface);
  --mat-stepper-header-selected-state-icon-background-color: var(--sys-colors-primary);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--sys-colors-on-primary);
  --mat-stepper-header-edit-state-icon-background-color: var(--sys-colors-primary);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--sys-colors-on-primary);
  --mat-stepper-container-color: var(--sys-colors-surface);
  --mat-stepper-line-color: var(--sys-colors-outline);
  --mat-stepper-header-hover-state-layer-color: color-mix(in srgb, var(--sys-colors-inverse-surface) 8%, transparent);
  --mat-stepper-header-focus-state-layer-color: color-mix(in srgb, var(--sys-colors-inverse-surface) 12%, transparent);
  --mat-stepper-header-label-text-color: var(--sys-colors-on-surface-variant);
  --mat-stepper-header-optional-label-text-color: var(--sys-colors-on-surface-variant);
  --mat-stepper-header-selected-state-label-text-color: var(--sys-colors-on-surface-variant);
  --mat-stepper-header-error-state-label-text-color: var(--sys-colors-error);
  --mat-stepper-header-icon-background-color: var(--sys-colors-on-surface-variant);
  --mat-stepper-header-error-state-icon-foreground-color: var(--sys-colors-error);
  --mat-stepper-container-text-font: var(--sys-body-medium-font);
  --mat-stepper-header-label-text-font: var(--sys-title-small-font);
  --mat-stepper-header-label-text-size: var(--sys-title-small-size);
  --mat-stepper-header-label-text-weight: var(--sys-title-small-weight);
  --mat-stepper-header-error-state-label-text-size: var(--sys-title-small-size);
  --mat-stepper-header-selected-state-label-text-size: var(--sys-title-small-size);
  --mat-stepper-header-selected-state-label-text-weight: var(--sys-title-small-weight);
  --mat-stepper-header-height: 72px;
  --mat-stepper-header-focus-state-layer-shape: 12px;
  --mat-stepper-header-hover-state-layer-shape: 12px;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
:root {
  --mat-sort-arrow-color: var(--sys-colors-on-surface);
}
:root {
  --mat-toolbar-container-background-color: var(--sys-colors-surface);
  --mat-toolbar-container-text-color: var(--sys-colors-on-surface);
  --mat-toolbar-title-text-font: var(--sys-title-large-font);
  --mat-toolbar-title-text-line-height: var(--sys-title-large-line-height);
  --mat-toolbar-title-text-size: var(--sys-title-large-size);
  --mat-toolbar-title-text-tracking: var(--sys-title-large-tracking);
  --mat-toolbar-title-text-weight: var(--sys-title-large-weight);
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}
:root {
  --mat-tree-container-background-color: var(--sys-colors-surface);
  --mat-tree-node-text-color: var(--sys-colors-on-surface);
  --mat-tree-node-text-font: var(--sys-body-large-font);
  --mat-tree-node-text-size: var(--sys-body-large-size);
  --mat-tree-node-text-weight: var(--sys-body-large-weight);
  --mat-tree-node-min-height: 48px;
}
:root {
  --mat-timepicker-container-background-color: var(--sys-colors-surface-container);
  --mat-timepicker-container-shape: 4px;
  --mat-timepicker-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
:root {
  --sys-colors-background: #f9f9ff;
  --sys-colors-error: var(--rs-color--red--700);
  --sys-colors-error-container: #ffdad6;
  --sys-colors-inverse-on-surface: #f0f0f7;
  --sys-colors-inverse-primary: #ffb597;
  --sys-colors-inverse-surface: #2e3035;
  --sys-colors-on-background: #191c20;
  --sys-colors-on-error: #ffffff;
  --sys-colors-on-error-container: #73332f;
  --sys-colors-on-primary: #ffffff;
  --sys-colors-on-primary-container: #71361b;
  --sys-colors-on-primary-fixed: #360f00;
  --sys-colors-on-primary-fixed-variant: #71361b;
  --sys-colors-on-secondary: #ffffff;
  --sys-colors-on-secondary-container: #71361b;
  --sys-colors-on-secondary-fixed: #360f00;
  --sys-colors-on-secondary-fixed-variant: #71361b;
  --sys-colors-on-surface: var(--rs-ds--text-color);
  --sys-colors-on-surface-variant: var(--rs-ds--text-color);
  --sys-colors-on-tertiary: #ffffff;
  --sys-colors-on-tertiary-container: #71361b;
  --sys-colors-on-tertiary-fixed: #360f00;
  --sys-colors-on-tertiary-fixed-variant: #71361b;
  --sys-colors-outline: #74777f;
  --sys-colors-outline-variant: #c3c6cf;
  --sys-colors-primary: #e97845;
  --sys-colors-primary-container: #ffdbcd;
  --sys-colors-primary-fixed: #ffdbcd;
  --sys-colors-primary-fixed-dim: #ffb597;
  --sys-colors-scrim: #000000;
  --sys-colors-secondary: #8e4d30;
  --sys-colors-secondary-container: #ffdbcd;
  --sys-colors-secondary-fixed: #ffdbcd;
  --sys-colors-secondary-fixed-dim: #ffb597;
  --sys-colors-shadow: #000000;
  --sys-colors-surface: var(--rs-color--white);
  --sys-colors-surface-bright: #f9f9ff;
  --sys-colors-surface-container: var(--rs-color--white);
  --sys-colors-surface-container-high: var(--rs-color--white);
  --sys-colors-surface-container-highest: #e1e2e9;
  --sys-colors-surface-container-low: #f3f3fa;
  --sys-colors-surface-container-lowest: #ffffff;
  --sys-colors-surface-dim: #d9dae0;
  --sys-colors-surface-tint: #8e4d30;
  --sys-colors-surface-variant: #e0e2ec;
  --sys-colors-tertiary: #8e4d30;
  --sys-colors-tertiary-container: #ffdbcd;
  --sys-colors-tertiary-fixed: #ffdbcd;
  --sys-colors-tertiary-fixed-dim: #ffb597;
  --sys-colors-neutral-variant20: #3e2c25;
  --sys-colors-neutral10: #1b1b1d;
}
:root {
  --sys-body-large: 400 1rem / 1.5rem Montserrat-light;
  --sys-body-large-font: Montserrat-light;
  --sys-body-large-line-height: 1.5rem;
  --sys-body-large-size: 0.875rem;
  --sys-body-large-tracking: 0.031rem;
  --sys-body-large-weight: 400;
  --sys-body-medium: 400 0.875rem / 1.25rem Montserrat-light;
  --sys-body-medium-font: Montserrat-light;
  --sys-body-medium-line-height: 1.375rem;
  --sys-body-medium-size: 0.875rem;
  --sys-body-medium-tracking: 0.016rem;
  --sys-body-medium-weight: 400;
  --sys-body-small: 400 0.75rem / 1rem Montserrat-light;
  --sys-body-small-font: Montserrat-light;
  --sys-body-small-line-height: 1rem;
  --sys-body-small-size: 0.75rem;
  --sys-body-small-tracking: 0.025rem;
  --sys-body-small-weight: 400;
  --sys-display-large: 400 3.562rem / 4rem Montserrat;
  --sys-display-large-font: Montserrat;
  --sys-display-large-line-height: 4rem;
  --sys-display-large-size: 3.562rem;
  --sys-display-large-tracking: -0.016rem;
  --sys-display-large-weight: 400;
  --sys-display-medium: 400 2.812rem / 3.25rem Montserrat;
  --sys-display-medium-font: Montserrat;
  --sys-display-medium-line-height: 3.25rem;
  --sys-display-medium-size: 2.812rem;
  --sys-display-medium-tracking: 0;
  --sys-display-medium-weight: 400;
  --sys-display-small: 400 2.25rem / 2.75rem Montserrat;
  --sys-display-small-font: Montserrat;
  --sys-display-small-line-height: 2.75rem;
  --sys-display-small-size: 2.25rem;
  --sys-display-small-tracking: 0;
  --sys-display-small-weight: 400;
  --sys-headline-large: 400 2rem / 2.5rem Montserrat;
  --sys-headline-large-font: Montserrat;
  --sys-headline-large-line-height: 2.5rem;
  --sys-headline-large-size: 2rem;
  --sys-headline-large-tracking: 0;
  --sys-headline-large-weight: 400;
  --sys-headline-medium: 400 1.75rem / 2.25rem Montserrat;
  --sys-headline-medium-font: Montserrat;
  --sys-headline-medium-line-height: 2.25rem;
  --sys-headline-medium-size: 1.75rem;
  --sys-headline-medium-tracking: 0;
  --sys-headline-medium-weight: 400;
  --sys-headline-small: 400 1.5rem / 2rem Montserrat;
  --sys-headline-small-font: Montserrat;
  --sys-headline-small-line-height: 2rem;
  --sys-headline-small-size: 1.5rem;
  --sys-headline-small-tracking: 0;
  --sys-headline-small-weight: 400;
  --sys-label-large: 600 0.875rem / 1.25rem Montserrat-light;
  --sys-label-large-font: Montserrat-light;
  --sys-label-large-line-height: 1.25rem;
  --sys-label-large-size: 0.875rem;
  --sys-label-large-tracking: 0.006rem;
  --sys-label-large-weight: 600;
  --sys-label-large-weight-prominent: 800;
  --sys-label-medium: 600 0.75rem / 1rem Montserrat-light;
  --sys-label-medium-font: Montserrat-light;
  --sys-label-medium-line-height: 1rem;
  --sys-label-medium-size: 0.75rem;
  --sys-label-medium-tracking: 0.031rem;
  --sys-label-medium-weight: 600;
  --sys-label-medium-weight-prominent: 800;
  --sys-label-small: 600 0.688rem / 1rem Montserrat-light;
  --sys-label-small-font: Montserrat-light;
  --sys-label-small-line-height: 1rem;
  --sys-label-small-size: 0.688rem;
  --sys-label-small-tracking: 0.031rem;
  --sys-label-small-weight: 600;
  --sys-title-large: 400 1.375rem / 1.75rem Montserrat;
  --sys-title-large-font: Montserrat;
  --sys-title-large-line-height: 1.75rem;
  --sys-title-large-size: 1.375rem;
  --sys-title-large-tracking: 0;
  --sys-title-large-weight: 400;
  --sys-title-medium: 600 1rem / 1.5rem Montserrat-light;
  --sys-title-medium-font: Montserrat-light;
  --sys-title-medium-line-height: 1.5rem;
  --sys-title-medium-size: 1rem;
  --sys-title-medium-tracking: 0.009rem;
  --sys-title-medium-weight: 600;
  --sys-title-small: 600 0.875rem / 1.25rem Montserrat-light;
  --sys-title-small-font: Montserrat-light;
  --sys-title-small-line-height: 1.25rem;
  --sys-title-small-size: 0.875rem;
  --sys-title-small-tracking: 0.006rem;
  --sys-title-small-weight: 600;
}
:root {
  --mat-menu-item-label-text-weight: 400;
}
:root {
  --mat-option-hover-state-layer-color: var(--rs-ds--color--hover);
}

mat-tab-group .mat-mdc-tab {
  --mat-tab-header-active-label-text-color: var(--rs-ds--color--primary);
  --mat-tab-header-active-focus-label-text-color: var(--rs-ds--color--primary);
  --mat-tab-header-active-hover-label-text-color: var(--rs-ds--color--primary--hover);
  padding: 0 0.5rem;
}
mat-tab-group .mat-mdc-tab.mdc-tab-indicator--active {
  pointer-events: none;
}
mat-tab-group .mat-mdc-tab.mdc-tab-indicator--active > * {
  pointer-events: none;
}
mat-tab-group .mat-mdc-tab.mdc-tab-indicator--active .mdc-tab__text-label > * {
  color: var(--rs-ds--color--primary);
}
mat-tab-group .mat-mdc-tab .mdc-tab__text-label {
  column-gap: 0.5rem;
  transition: none;
}

/* Breakpoints */
@property --rs-container-breakpoint--lg {
  syntax: "<length>";
  inherits: false;
  initial-value: 992px;
}
@property --rs-ds--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-ds--border-radius--small {
  syntax: "<length>";
  inherits: true;
  initial-value: 4px;
}
@property --rs-ds--main-container--width {
  syntax: "<percentage>";
  inherits: true;
  initial-value: 100%;
}
@property --rs-ds--main-container-top-spacing {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-ds--main-container--padding-inline {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-ds--section--bottom-spacing {
  syntax: "<length>";
  inherits: true;
  initial-value: 40px;
}
@property --rs-ds--link--color {
  syntax: "<length>";
  inherits: true;
  initial-value: orange;
}
:root {
  --rs-ds--font--primary: "Montserrat";
  --rs-ds--font--primary-light: "Montserrat-light";
  --rs-ds--body-font-size: 0.875rem;
  --rs-ds--body-line-height: 1.375rem;
  --rs-ds--font-size--xs: 0.625rem;
  --rs-ds--font-size--xs-line-height: 1rem;
  --rs-ds--font-size--sm: 0.75rem;
  --rs-ds--font-size--sm-line-height: 1.25rem;
  --rs-ds--font-size--md: 0.875rem;
  --rs-ds--font-size--md-line-height: 1.375rem;
  --rs-ds--font-size--lg: 1rem;
  --rs-ds--font-size--lg-line-height: 1.5rem;
  --rs-ds--text-color: var(--rs-color--grey--700);
  --rs-ds--text-secondary: var(--rs-color--grey--500);
  --rs-ds--text-disabled: var(--rs-color--grey--300);
  --rs-ds--text-dark: var(--rs-color--grey--900);
  --rs-ds--border-color: var(--rs-color--grey--200);
  --rs-ds--color--primary: var(--rs-color--orange--300);
  --rs-ds--color--primary--hover: hsl(from var(--rs-ds--color--primary) h calc(s - 2) calc(l - 15));
  --rs-ds--color--hover: var(--rs-color--grey--100);
  --rs-ds--color--alert: var(--rs-color--red--400);
  --rs-ds--background-color: var(--rs-color--grey--50);
  --rs-ds--color--icon--grey: var(--rs-color--grey--700);
  --rs-ds--color--icon--disabled: var(--rs-color--grey--300);
  --rs-ds--color--icon--orange: var(--rs-color--orange--300);
  --rs-ds--link--color: var(--rs-ds--color--primary);
  --rs-ds--button-height: 2.5rem;
  --rs-transition--bezier: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --rs-ds--box--bottom-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.08);
  --rs-ds--box--top-shadow: 1px -6px 10px 0 rgba(98, 105, 115, 0.08);
  --rs-ds--main-container-top-spacing: 2.5rem;
  --rs-ds--main-container--padding-inline: 1rem;
  --rs-ds--section--bottom-spacing: 2.5rem;
}

@property --rs-card--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-card--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 30px;
}
@property --rs-card--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 30px;
}
@property --rs-card--margin-bottom {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-card--background-color--primary {
  syntax: "<color>";
  inherits: false;
  initial-value: white;
}
@property --rs-card-details--margin-bottom {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-card-details--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
/* Breakpoints */
@property --rs-container-breakpoint--lg {
  syntax: "<length>";
  inherits: false;
  initial-value: 992px;
}
@property --rs-ds--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-ds--border-radius--small {
  syntax: "<length>";
  inherits: true;
  initial-value: 4px;
}
@property --rs-ds--main-container--width {
  syntax: "<percentage>";
  inherits: true;
  initial-value: 100%;
}
@property --rs-ds--main-container-top-spacing {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-ds--main-container--padding-inline {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-ds--section--bottom-spacing {
  syntax: "<length>";
  inherits: true;
  initial-value: 40px;
}
@property --rs-ds--link--color {
  syntax: "<length>";
  inherits: true;
  initial-value: orange;
}
:root {
  --rs-ds--font--primary: "Montserrat";
  --rs-ds--font--primary-light: "Montserrat-light";
  --rs-ds--body-font-size: 0.875rem;
  --rs-ds--body-line-height: 1.375rem;
  --rs-ds--font-size--xs: 0.625rem;
  --rs-ds--font-size--xs-line-height: 1rem;
  --rs-ds--font-size--sm: 0.75rem;
  --rs-ds--font-size--sm-line-height: 1.25rem;
  --rs-ds--font-size--md: 0.875rem;
  --rs-ds--font-size--md-line-height: 1.375rem;
  --rs-ds--font-size--lg: 1rem;
  --rs-ds--font-size--lg-line-height: 1.5rem;
  --rs-ds--text-color: var(--rs-color--grey--700);
  --rs-ds--text-secondary: var(--rs-color--grey--500);
  --rs-ds--text-disabled: var(--rs-color--grey--300);
  --rs-ds--text-dark: var(--rs-color--grey--900);
  --rs-ds--border-color: var(--rs-color--grey--200);
  --rs-ds--color--primary: var(--rs-color--orange--300);
  --rs-ds--color--primary--hover: hsl(from var(--rs-ds--color--primary) h calc(s - 2) calc(l - 15));
  --rs-ds--color--hover: var(--rs-color--grey--100);
  --rs-ds--color--alert: var(--rs-color--red--400);
  --rs-ds--background-color: var(--rs-color--grey--50);
  --rs-ds--color--icon--grey: var(--rs-color--grey--700);
  --rs-ds--color--icon--disabled: var(--rs-color--grey--300);
  --rs-ds--color--icon--orange: var(--rs-color--orange--300);
  --rs-ds--link--color: var(--rs-ds--color--primary);
  --rs-ds--button-height: 2.5rem;
  --rs-transition--bezier: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --rs-ds--box--bottom-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.08);
  --rs-ds--box--top-shadow: 1px -6px 10px 0 rgba(98, 105, 115, 0.08);
  --rs-ds--main-container-top-spacing: 2.5rem;
  --rs-ds--main-container--padding-inline: 1rem;
  --rs-ds--section--bottom-spacing: 2.5rem;
}

/**
 * @param {*} $param
 * @param {String|*} $param-optional
 */
/* Fonts */
@property --rs-card--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-card--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 30px;
}
@property --rs-card--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 30px;
}
@property --rs-card--margin-bottom {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-card--background-color--primary {
  syntax: "<color>";
  inherits: false;
  initial-value: white;
}
@property --rs-card-details--margin-bottom {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-card-details--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
/* Breakpoints */
@property --rs-container-breakpoint--lg {
  syntax: "<length>";
  inherits: false;
  initial-value: 992px;
}
@property --rs-ds--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-ds--border-radius--small {
  syntax: "<length>";
  inherits: true;
  initial-value: 4px;
}
@property --rs-ds--main-container--width {
  syntax: "<percentage>";
  inherits: true;
  initial-value: 100%;
}
@property --rs-ds--main-container-top-spacing {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-ds--main-container--padding-inline {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-ds--section--bottom-spacing {
  syntax: "<length>";
  inherits: true;
  initial-value: 40px;
}
@property --rs-ds--link--color {
  syntax: "<length>";
  inherits: true;
  initial-value: orange;
}
:root {
  --rs-ds--font--primary: "Montserrat";
  --rs-ds--font--primary-light: "Montserrat-light";
  --rs-ds--body-font-size: 0.875rem;
  --rs-ds--body-line-height: 1.375rem;
  --rs-ds--font-size--xs: 0.625rem;
  --rs-ds--font-size--xs-line-height: 1rem;
  --rs-ds--font-size--sm: 0.75rem;
  --rs-ds--font-size--sm-line-height: 1.25rem;
  --rs-ds--font-size--md: 0.875rem;
  --rs-ds--font-size--md-line-height: 1.375rem;
  --rs-ds--font-size--lg: 1rem;
  --rs-ds--font-size--lg-line-height: 1.5rem;
  --rs-ds--text-color: var(--rs-color--grey--700);
  --rs-ds--text-secondary: var(--rs-color--grey--500);
  --rs-ds--text-disabled: var(--rs-color--grey--300);
  --rs-ds--text-dark: var(--rs-color--grey--900);
  --rs-ds--border-color: var(--rs-color--grey--200);
  --rs-ds--color--primary: var(--rs-color--orange--300);
  --rs-ds--color--primary--hover: hsl(from var(--rs-ds--color--primary) h calc(s - 2) calc(l - 15));
  --rs-ds--color--hover: var(--rs-color--grey--100);
  --rs-ds--color--alert: var(--rs-color--red--400);
  --rs-ds--background-color: var(--rs-color--grey--50);
  --rs-ds--color--icon--grey: var(--rs-color--grey--700);
  --rs-ds--color--icon--disabled: var(--rs-color--grey--300);
  --rs-ds--color--icon--orange: var(--rs-color--orange--300);
  --rs-ds--link--color: var(--rs-ds--color--primary);
  --rs-ds--button-height: 2.5rem;
  --rs-transition--bezier: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --rs-ds--box--bottom-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.08);
  --rs-ds--box--top-shadow: 1px -6px 10px 0 rgba(98, 105, 115, 0.08);
  --rs-ds--main-container-top-spacing: 2.5rem;
  --rs-ds--main-container--padding-inline: 1rem;
  --rs-ds--section--bottom-spacing: 2.5rem;
}

/**
 * @param {*} $param
 * @param {String|*} $param-optional
 */
/* Fonts */
.rs-font--primary-light,
rs-font-light,
[rs-font=light] {
  font-family: var(--rs-ds--font--primary-light) !important;
  font-weight: 400;
}

.rs-font--primary-regular,
rs-font-regular,
[rs-font=regular] {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
}

.rs-font--primary-medium,
rs-font-medium,
[rs-font=medium] {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 600;
}

.rs-font--primary-bold,
[rs-font=bold] {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 800;
}

.rs-sub-text {
  color: var(--rs-ds--text-secondary);
  font-size: 0.8125rem !important;
  line-height: 1rem !important;
}

rs-font-light-block,
[rs-font-light-block] {
  font-family: var(--rs-ds--font--primary-light) !important;
  font-weight: 400;
  display: block;
}

[rs-text-color=secondary] {
  color: var(--rs-ds--text-secondary) !important;
}

[rs-text-color=alert] {
  color: var(--rs-ds--color--alert) !important;
}

[rs-text-color=ds-primary] {
  color: var(--rs-ds--color--primary) !important;
}

[rs-font-size=xs] {
  font-size: var(--rs-ds--font-size--xs);
  line-height: var(--rs-ds--font-size--xs-line-height);
}

[rs-font-size=sm] {
  font-size: var(--rs-ds--font-size--sm);
  line-height: var(--rs-ds--font-size--sm-line-height);
}

[rs-font-size=md] {
  font-size: var(--rs-ds--font-size--md);
  line-height: var(--rs-ds--font-size--md-line-height);
}

[rs-font-size=lg] {
  font-size: var(--rs-ds--font-size--lg);
  line-height: var(--rs-ds--font-size--lg-line-height);
}

/** If even number add 4 to value else 3 >> igloo rules */
/** $font-sizes-map: (
  [class-suffix]: '[font-size in-rem] [line-height in-rem]'
  ex: 13px: ('font-size': 0.8125rem, 'line-height': 1rem)
) */
.rs-font-size-1px {
  font-size: 0.0625rem !important;
  line-height: 0.25rem !important;
}

.rs-font-size-2px {
  font-size: 0.125rem !important;
  line-height: 0.375rem !important;
}

.rs-font-size-3px {
  font-size: 0.1875rem !important;
  line-height: 0.375rem !important;
}

.rs-font-size-4px {
  font-size: 0.25rem !important;
  line-height: 0.5rem !important;
}

.rs-font-size-5px {
  font-size: 0.3125rem !important;
  line-height: 0.5rem !important;
}

.rs-font-size-6px {
  font-size: 0.375rem !important;
  line-height: 0.625rem !important;
}

.rs-font-size-7px {
  font-size: 0.4375rem !important;
  line-height: 0.625rem !important;
}

.rs-font-size-8px {
  font-size: 0.5rem !important;
  line-height: 0.75rem !important;
}

.rs-font-size-9px {
  font-size: 0.5625rem !important;
  line-height: 0.75rem !important;
}

.rs-font-size-10px {
  font-size: 0.625rem !important;
  line-height: 0.875rem !important;
}

.rs-font-size-11px {
  font-size: 0.6875rem !important;
  line-height: 0.875rem !important;
}

.rs-font-size-12px {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.rs-font-size-13px {
  font-size: 0.8125rem !important;
  line-height: 1rem !important;
}

.rs-font-size-14px {
  font-size: 0.875rem !important;
  line-height: 1.125rem !important;
}

.rs-font-size-15px {
  font-size: 0.9375rem !important;
  line-height: 1.125rem !important;
}

.rs-font-size-16px {
  font-size: 1rem !important;
  line-height: 1.25rem !important;
}

.rs-font-size-17px {
  font-size: 1.0625rem !important;
  line-height: 1.25rem !important;
}

.rs-font-size-18px {
  font-size: 1.125rem !important;
  line-height: 1.375rem !important;
}

.rs-font-size-19px {
  font-size: 1.1875rem !important;
  line-height: 1.375rem !important;
}

.rs-font-size-20px {
  font-size: 1.25rem !important;
  line-height: 1.5rem !important;
}

.rs-font-size-21px {
  font-size: 1.3125rem !important;
  line-height: 1.5rem !important;
}

.rs-font-size-22px {
  font-size: 1.375rem !important;
  line-height: 1.625rem !important;
}

.rs-font-size-23px {
  font-size: 1.4375rem !important;
  line-height: 1.625rem !important;
}

.rs-font-size-24px {
  font-size: 1.5rem !important;
  line-height: 1.75rem !important;
}

.rs-font-size-25px {
  font-size: 1.5625rem !important;
  line-height: 1.75rem !important;
}

.rs-font-size-26px {
  font-size: 1.625rem !important;
  line-height: 1.875rem !important;
}

.rs-font-size-27px {
  font-size: 1.6875rem !important;
  line-height: 1.875rem !important;
}

.rs-font-size-28px {
  font-size: 1.75rem !important;
  line-height: 2rem !important;
}

.rs-font-size-29px {
  font-size: 1.8125rem !important;
  line-height: 2rem !important;
}

.rs-font-size-30px {
  font-size: 1.875rem !important;
  line-height: 2.125rem !important;
}

.rs-font-size-31px {
  font-size: 1.9375rem !important;
  line-height: 2.125rem !important;
}

.rs-font-size-32px {
  font-size: 2rem !important;
  line-height: 2.25rem !important;
}

.rs-font-size-33px {
  font-size: 2.0625rem !important;
  line-height: 2.25rem !important;
}

.rs-font-size-34px {
  font-size: 2.125rem !important;
  line-height: 2.375rem !important;
}

.rs-font-size-35px {
  font-size: 2.1875rem !important;
  line-height: 2.375rem !important;
}

.rs-font-size-36px {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.rs-font-size-37px {
  font-size: 2.3125rem !important;
  line-height: 2.5rem !important;
}

.rs-font-size-38px {
  font-size: 2.375rem !important;
  line-height: 2.625rem !important;
}

.rs-font-size-39px {
  font-size: 2.4375rem !important;
  line-height: 2.625rem !important;
}

.rs-font-size-40px {
  font-size: 2.5rem !important;
  line-height: 2.75rem !important;
}

.rs-font-size-41px {
  font-size: 2.5625rem !important;
  line-height: 2.75rem !important;
}

.rs-font-size-42px {
  font-size: 2.625rem !important;
  line-height: 2.875rem !important;
}

.rs-font-size-43px {
  font-size: 2.6875rem !important;
  line-height: 2.875rem !important;
}

.rs-font-size-44px {
  font-size: 2.75rem !important;
  line-height: 3rem !important;
}

.rs-font-size-45px {
  font-size: 2.8125rem !important;
  line-height: 3rem !important;
}

.rs-font-size-46px {
  font-size: 2.875rem !important;
  line-height: 3.125rem !important;
}

.rs-font-size-47px {
  font-size: 2.9375rem !important;
  line-height: 3.125rem !important;
}

.rs-font-size-48px {
  font-size: 3rem !important;
  line-height: 3.25rem !important;
}

.rs-font-size-49px {
  font-size: 3.0625rem !important;
  line-height: 3.25rem !important;
}

.rs-font-size-50px {
  font-size: 3.125rem !important;
  line-height: 3.375rem !important;
}

.rs-font-size-51px {
  font-size: 3.1875rem !important;
  line-height: 3.375rem !important;
}

.rs-font-size-52px {
  font-size: 3.25rem !important;
  line-height: 3.5rem !important;
}

.rs-font-size-53px {
  font-size: 3.3125rem !important;
  line-height: 3.5rem !important;
}

.rs-font-size-54px {
  font-size: 3.375rem !important;
  line-height: 3.625rem !important;
}

.rs-font-size-55px {
  font-size: 3.4375rem !important;
  line-height: 3.625rem !important;
}

.rs-font-size-56px {
  font-size: 3.5rem !important;
  line-height: 3.75rem !important;
}

.rs-font-size-57px {
  font-size: 3.5625rem !important;
  line-height: 3.75rem !important;
}

.rs-font-size-58px {
  font-size: 3.625rem !important;
  line-height: 3.875rem !important;
}

.rs-font-size-59px {
  font-size: 3.6875rem !important;
  line-height: 3.875rem !important;
}

.rs-font-size-60px {
  font-size: 3.75rem !important;
  line-height: 4rem !important;
}

/** $line-height-map: (
  [class-suffix]: '[line-height in-rem]'
  ex: 16px: 1rem
) */
.rs-line-height-1px {
  line-height: 0.0625rem !important;
}

.rs-line-height-2px {
  line-height: 0.125rem !important;
}

.rs-line-height-3px {
  line-height: 0.1875rem !important;
}

.rs-line-height-4px {
  line-height: 0.25rem !important;
}

.rs-line-height-5px {
  line-height: 0.3125rem !important;
}

.rs-line-height-6px {
  line-height: 0.375rem !important;
}

.rs-line-height-7px {
  line-height: 0.4375rem !important;
}

.rs-line-height-8px {
  line-height: 0.5rem !important;
}

.rs-line-height-9px {
  line-height: 0.5625rem !important;
}

.rs-line-height-10px {
  line-height: 0.625rem !important;
}

.rs-line-height-11px {
  line-height: 0.6875rem !important;
}

.rs-line-height-12px {
  line-height: 0.75rem !important;
}

.rs-line-height-13px {
  line-height: 0.8125rem !important;
}

.rs-line-height-14px {
  line-height: 0.875rem !important;
}

.rs-line-height-15px {
  line-height: 0.9375rem !important;
}

.rs-line-height-16px {
  line-height: 1rem !important;
}

.rs-line-height-17px {
  line-height: 1.0625rem !important;
}

.rs-line-height-18px {
  line-height: 1.125rem !important;
}

.rs-line-height-19px {
  line-height: 1.1875rem !important;
}

.rs-line-height-20px {
  line-height: 1.25rem !important;
}

.rs-line-height-21px {
  line-height: 1.3125rem !important;
}

.rs-line-height-22px {
  line-height: 1.375rem !important;
}

.rs-line-height-23px {
  line-height: 1.4375rem !important;
}

.rs-line-height-24px {
  line-height: 1.5rem !important;
}

.rs-line-height-25px {
  line-height: 1.5625rem !important;
}

.rs-line-height-26px {
  line-height: 1.625rem !important;
}

.rs-line-height-27px {
  line-height: 1.6875rem !important;
}

.rs-line-height-28px {
  line-height: 1.75rem !important;
}

.rs-line-height-29px {
  line-height: 1.8125rem !important;
}

.rs-line-height-30px {
  line-height: 1.875rem !important;
}

.rs-line-height-31px {
  line-height: 1.9375rem !important;
}

.rs-line-height-32px {
  line-height: 2rem !important;
}

.rs-line-height-33px {
  line-height: 2.0625rem !important;
}

.rs-line-height-34px {
  line-height: 2.125rem !important;
}

.rs-line-height-35px {
  line-height: 2.1875rem !important;
}

.rs-line-height-36px {
  line-height: 2.25rem !important;
}

.rs-line-height-37px {
  line-height: 2.3125rem !important;
}

.rs-line-height-38px {
  line-height: 2.375rem !important;
}

.rs-line-height-39px {
  line-height: 2.4375rem !important;
}

.rs-line-height-40px {
  line-height: 2.5rem !important;
}

.rs-line-height-41px {
  line-height: 2.5625rem !important;
}

.rs-line-height-42px {
  line-height: 2.625rem !important;
}

.rs-line-height-43px {
  line-height: 2.6875rem !important;
}

.rs-line-height-44px {
  line-height: 2.75rem !important;
}

.rs-line-height-45px {
  line-height: 2.8125rem !important;
}

.rs-line-height-46px {
  line-height: 2.875rem !important;
}

.rs-line-height-47px {
  line-height: 2.9375rem !important;
}

.rs-line-height-48px {
  line-height: 3rem !important;
}

.rs-line-height-49px {
  line-height: 3.0625rem !important;
}

.rs-line-height-50px {
  line-height: 3.125rem !important;
}

.rs-line-height-51px {
  line-height: 3.1875rem !important;
}

.rs-line-height-52px {
  line-height: 3.25rem !important;
}

.rs-line-height-53px {
  line-height: 3.3125rem !important;
}

.rs-line-height-54px {
  line-height: 3.375rem !important;
}

.rs-line-height-55px {
  line-height: 3.4375rem !important;
}

.rs-line-height-56px {
  line-height: 3.5rem !important;
}

.rs-line-height-57px {
  line-height: 3.5625rem !important;
}

.rs-line-height-58px {
  line-height: 3.625rem !important;
}

.rs-line-height-59px {
  line-height: 3.6875rem !important;
}

.rs-line-height-60px {
  line-height: 3.75rem !important;
}

/** $height-map: (
  [class-suffix]: '[height in-rem]'
  ex: 16px: 1rem
) */
.rs-height-1px {
  height: 0.0625rem !important;
}

.rs-height-2px {
  height: 0.125rem !important;
}

.rs-height-3px {
  height: 0.1875rem !important;
}

.rs-height-4px {
  height: 0.25rem !important;
}

.rs-height-5px {
  height: 0.3125rem !important;
}

.rs-height-6px {
  height: 0.375rem !important;
}

.rs-height-7px {
  height: 0.4375rem !important;
}

.rs-height-8px {
  height: 0.5rem !important;
}

.rs-height-9px {
  height: 0.5625rem !important;
}

.rs-height-10px {
  height: 0.625rem !important;
}

.rs-height-11px {
  height: 0.6875rem !important;
}

.rs-height-12px {
  height: 0.75rem !important;
}

.rs-height-13px {
  height: 0.8125rem !important;
}

.rs-height-14px {
  height: 0.875rem !important;
}

.rs-height-15px {
  height: 0.9375rem !important;
}

.rs-height-16px {
  height: 1rem !important;
}

.rs-height-17px {
  height: 1.0625rem !important;
}

.rs-height-18px {
  height: 1.125rem !important;
}

.rs-height-19px {
  height: 1.1875rem !important;
}

.rs-height-20px {
  height: 1.25rem !important;
}

.rs-height-21px {
  height: 1.3125rem !important;
}

.rs-height-22px {
  height: 1.375rem !important;
}

.rs-height-23px {
  height: 1.4375rem !important;
}

.rs-height-24px {
  height: 1.5rem !important;
}

.rs-height-25px {
  height: 1.5625rem !important;
}

.rs-height-26px {
  height: 1.625rem !important;
}

.rs-height-27px {
  height: 1.6875rem !important;
}

.rs-height-28px {
  height: 1.75rem !important;
}

.rs-height-29px {
  height: 1.8125rem !important;
}

.rs-height-30px {
  height: 1.875rem !important;
}

.rs-height-31px {
  height: 1.9375rem !important;
}

.rs-height-32px {
  height: 2rem !important;
}

.rs-height-33px {
  height: 2.0625rem !important;
}

.rs-height-34px {
  height: 2.125rem !important;
}

.rs-height-35px {
  height: 2.1875rem !important;
}

.rs-height-36px {
  height: 2.25rem !important;
}

.rs-height-37px {
  height: 2.3125rem !important;
}

.rs-height-38px {
  height: 2.375rem !important;
}

.rs-height-39px {
  height: 2.4375rem !important;
}

.rs-height-40px {
  height: 2.5rem !important;
}

.rs-height-41px {
  height: 2.5625rem !important;
}

.rs-height-42px {
  height: 2.625rem !important;
}

.rs-height-43px {
  height: 2.6875rem !important;
}

.rs-height-44px {
  height: 2.75rem !important;
}

.rs-height-45px {
  height: 2.8125rem !important;
}

.rs-height-46px {
  height: 2.875rem !important;
}

.rs-height-47px {
  height: 2.9375rem !important;
}

.rs-height-48px {
  height: 3rem !important;
}

.rs-height-49px {
  height: 3.0625rem !important;
}

.rs-height-50px {
  height: 3.125rem !important;
}

.rs-height-51px {
  height: 3.1875rem !important;
}

.rs-height-52px {
  height: 3.25rem !important;
}

.rs-height-53px {
  height: 3.3125rem !important;
}

.rs-height-54px {
  height: 3.375rem !important;
}

.rs-height-55px {
  height: 3.4375rem !important;
}

.rs-height-56px {
  height: 3.5rem !important;
}

.rs-height-57px {
  height: 3.5625rem !important;
}

.rs-height-58px {
  height: 3.625rem !important;
}

.rs-height-59px {
  height: 3.6875rem !important;
}

.rs-height-60px {
  height: 3.75rem !important;
}

.rs-height-61px {
  height: 3.8125rem !important;
}

.rs-height-62px {
  height: 3.875rem !important;
}

.rs-height-63px {
  height: 3.9375rem !important;
}

.rs-height-64px {
  height: 4rem !important;
}

.rs-height-65px {
  height: 4.0625rem !important;
}

.rs-height-66px {
  height: 4.125rem !important;
}

.rs-height-67px {
  height: 4.1875rem !important;
}

.rs-height-68px {
  height: 4.25rem !important;
}

.rs-height-69px {
  height: 4.3125rem !important;
}

.rs-height-70px {
  height: 4.375rem !important;
}

.rs-height-71px {
  height: 4.4375rem !important;
}

.rs-height-72px {
  height: 4.5rem !important;
}

.rs-height-73px {
  height: 4.5625rem !important;
}

.rs-height-74px {
  height: 4.625rem !important;
}

.rs-height-75px {
  height: 4.6875rem !important;
}

.rs-height-76px {
  height: 4.75rem !important;
}

.rs-height-77px {
  height: 4.8125rem !important;
}

.rs-height-78px {
  height: 4.875rem !important;
}

.rs-height-79px {
  height: 4.9375rem !important;
}

.rs-height-80px {
  height: 5rem !important;
}

.rs-height-81px {
  height: 5.0625rem !important;
}

.rs-height-82px {
  height: 5.125rem !important;
}

.rs-height-83px {
  height: 5.1875rem !important;
}

.rs-height-84px {
  height: 5.25rem !important;
}

.rs-height-85px {
  height: 5.3125rem !important;
}

.rs-height-86px {
  height: 5.375rem !important;
}

.rs-height-87px {
  height: 5.4375rem !important;
}

.rs-height-88px {
  height: 5.5rem !important;
}

.rs-height-89px {
  height: 5.5625rem !important;
}

.rs-height-90px {
  height: 5.625rem !important;
}

.rs-height-91px {
  height: 5.6875rem !important;
}

.rs-height-92px {
  height: 5.75rem !important;
}

.rs-height-93px {
  height: 5.8125rem !important;
}

.rs-height-94px {
  height: 5.875rem !important;
}

.rs-height-95px {
  height: 5.9375rem !important;
}

.rs-height-96px {
  height: 6rem !important;
}

.rs-height-97px {
  height: 6.0625rem !important;
}

.rs-height-98px {
  height: 6.125rem !important;
}

.rs-height-99px {
  height: 6.1875rem !important;
}

.rs-height-100px {
  height: 6.25rem !important;
}

.rs-height-101px {
  height: 6.3125rem !important;
}

.rs-height-102px {
  height: 6.375rem !important;
}

.rs-height-103px {
  height: 6.4375rem !important;
}

.rs-height-104px {
  height: 6.5rem !important;
}

.rs-height-105px {
  height: 6.5625rem !important;
}

.rs-height-106px {
  height: 6.625rem !important;
}

.rs-height-107px {
  height: 6.6875rem !important;
}

.rs-height-108px {
  height: 6.75rem !important;
}

.rs-height-109px {
  height: 6.8125rem !important;
}

.rs-height-110px {
  height: 6.875rem !important;
}

.rs-height-111px {
  height: 6.9375rem !important;
}

.rs-height-112px {
  height: 7rem !important;
}

.rs-height-113px {
  height: 7.0625rem !important;
}

.rs-height-114px {
  height: 7.125rem !important;
}

.rs-height-115px {
  height: 7.1875rem !important;
}

.rs-height-116px {
  height: 7.25rem !important;
}

.rs-height-117px {
  height: 7.3125rem !important;
}

.rs-height-118px {
  height: 7.375rem !important;
}

.rs-height-119px {
  height: 7.4375rem !important;
}

.rs-height-120px {
  height: 7.5rem !important;
}

/** $min-width-map: (
  [class-suffix]: '[min-width in-rem]'
  ex: 16px: 1rem
) */
.rs-min-width-1px {
  min-width: 0.0625rem !important;
}

.rs-min-width-2px {
  min-width: 0.125rem !important;
}

.rs-min-width-3px {
  min-width: 0.1875rem !important;
}

.rs-min-width-4px {
  min-width: 0.25rem !important;
}

.rs-min-width-5px {
  min-width: 0.3125rem !important;
}

.rs-min-width-6px {
  min-width: 0.375rem !important;
}

.rs-min-width-7px {
  min-width: 0.4375rem !important;
}

.rs-min-width-8px {
  min-width: 0.5rem !important;
}

.rs-min-width-9px {
  min-width: 0.5625rem !important;
}

.rs-min-width-10px {
  min-width: 0.625rem !important;
}

.rs-min-width-11px {
  min-width: 0.6875rem !important;
}

.rs-min-width-12px {
  min-width: 0.75rem !important;
}

.rs-min-width-13px {
  min-width: 0.8125rem !important;
}

.rs-min-width-14px {
  min-width: 0.875rem !important;
}

.rs-min-width-15px {
  min-width: 0.9375rem !important;
}

.rs-min-width-16px {
  min-width: 1rem !important;
}

.rs-min-width-17px {
  min-width: 1.0625rem !important;
}

.rs-min-width-18px {
  min-width: 1.125rem !important;
}

.rs-min-width-19px {
  min-width: 1.1875rem !important;
}

.rs-min-width-20px {
  min-width: 1.25rem !important;
}

.rs-min-width-21px {
  min-width: 1.3125rem !important;
}

.rs-min-width-22px {
  min-width: 1.375rem !important;
}

.rs-min-width-23px {
  min-width: 1.4375rem !important;
}

.rs-min-width-24px {
  min-width: 1.5rem !important;
}

.rs-min-width-25px {
  min-width: 1.5625rem !important;
}

.rs-min-width-26px {
  min-width: 1.625rem !important;
}

.rs-min-width-27px {
  min-width: 1.6875rem !important;
}

.rs-min-width-28px {
  min-width: 1.75rem !important;
}

.rs-min-width-29px {
  min-width: 1.8125rem !important;
}

.rs-min-width-30px {
  min-width: 1.875rem !important;
}

.rs-min-width-31px {
  min-width: 1.9375rem !important;
}

.rs-min-width-32px {
  min-width: 2rem !important;
}

.rs-min-width-33px {
  min-width: 2.0625rem !important;
}

.rs-min-width-34px {
  min-width: 2.125rem !important;
}

.rs-min-width-35px {
  min-width: 2.1875rem !important;
}

.rs-min-width-36px {
  min-width: 2.25rem !important;
}

.rs-min-width-37px {
  min-width: 2.3125rem !important;
}

.rs-min-width-38px {
  min-width: 2.375rem !important;
}

.rs-min-width-39px {
  min-width: 2.4375rem !important;
}

.rs-min-width-40px {
  min-width: 2.5rem !important;
}

.rs-min-width-41px {
  min-width: 2.5625rem !important;
}

.rs-min-width-42px {
  min-width: 2.625rem !important;
}

.rs-min-width-43px {
  min-width: 2.6875rem !important;
}

.rs-min-width-44px {
  min-width: 2.75rem !important;
}

.rs-min-width-45px {
  min-width: 2.8125rem !important;
}

.rs-min-width-46px {
  min-width: 2.875rem !important;
}

.rs-min-width-47px {
  min-width: 2.9375rem !important;
}

.rs-min-width-48px {
  min-width: 3rem !important;
}

.rs-min-width-49px {
  min-width: 3.0625rem !important;
}

.rs-min-width-50px {
  min-width: 3.125rem !important;
}

.rs-min-width-51px {
  min-width: 3.1875rem !important;
}

.rs-min-width-52px {
  min-width: 3.25rem !important;
}

.rs-min-width-53px {
  min-width: 3.3125rem !important;
}

.rs-min-width-54px {
  min-width: 3.375rem !important;
}

.rs-min-width-55px {
  min-width: 3.4375rem !important;
}

.rs-min-width-56px {
  min-width: 3.5rem !important;
}

.rs-min-width-57px {
  min-width: 3.5625rem !important;
}

.rs-min-width-58px {
  min-width: 3.625rem !important;
}

.rs-min-width-59px {
  min-width: 3.6875rem !important;
}

.rs-min-width-60px {
  min-width: 3.75rem !important;
}

.rs-min-width-61px {
  min-width: 3.8125rem !important;
}

.rs-min-width-62px {
  min-width: 3.875rem !important;
}

.rs-min-width-63px {
  min-width: 3.9375rem !important;
}

.rs-min-width-64px {
  min-width: 4rem !important;
}

.rs-min-width-65px {
  min-width: 4.0625rem !important;
}

.rs-min-width-66px {
  min-width: 4.125rem !important;
}

.rs-min-width-67px {
  min-width: 4.1875rem !important;
}

.rs-min-width-68px {
  min-width: 4.25rem !important;
}

.rs-min-width-69px {
  min-width: 4.3125rem !important;
}

.rs-min-width-70px {
  min-width: 4.375rem !important;
}

.rs-min-width-71px {
  min-width: 4.4375rem !important;
}

.rs-min-width-72px {
  min-width: 4.5rem !important;
}

.rs-min-width-73px {
  min-width: 4.5625rem !important;
}

.rs-min-width-74px {
  min-width: 4.625rem !important;
}

.rs-min-width-75px {
  min-width: 4.6875rem !important;
}

.rs-min-width-76px {
  min-width: 4.75rem !important;
}

.rs-min-width-77px {
  min-width: 4.8125rem !important;
}

.rs-min-width-78px {
  min-width: 4.875rem !important;
}

.rs-min-width-79px {
  min-width: 4.9375rem !important;
}

.rs-min-width-80px {
  min-width: 5rem !important;
}

.rs-min-width-81px {
  min-width: 5.0625rem !important;
}

.rs-min-width-82px {
  min-width: 5.125rem !important;
}

.rs-min-width-83px {
  min-width: 5.1875rem !important;
}

.rs-min-width-84px {
  min-width: 5.25rem !important;
}

.rs-min-width-85px {
  min-width: 5.3125rem !important;
}

.rs-min-width-86px {
  min-width: 5.375rem !important;
}

.rs-min-width-87px {
  min-width: 5.4375rem !important;
}

.rs-min-width-88px {
  min-width: 5.5rem !important;
}

.rs-min-width-89px {
  min-width: 5.5625rem !important;
}

.rs-min-width-90px {
  min-width: 5.625rem !important;
}

.rs-min-width-91px {
  min-width: 5.6875rem !important;
}

.rs-min-width-92px {
  min-width: 5.75rem !important;
}

.rs-min-width-93px {
  min-width: 5.8125rem !important;
}

.rs-min-width-94px {
  min-width: 5.875rem !important;
}

.rs-min-width-95px {
  min-width: 5.9375rem !important;
}

.rs-min-width-96px {
  min-width: 6rem !important;
}

.rs-min-width-97px {
  min-width: 6.0625rem !important;
}

.rs-min-width-98px {
  min-width: 6.125rem !important;
}

.rs-min-width-99px {
  min-width: 6.1875rem !important;
}

.rs-min-width-100px {
  min-width: 6.25rem !important;
}

.rs-min-width-101px {
  min-width: 6.3125rem !important;
}

.rs-min-width-102px {
  min-width: 6.375rem !important;
}

.rs-min-width-103px {
  min-width: 6.4375rem !important;
}

.rs-min-width-104px {
  min-width: 6.5rem !important;
}

.rs-min-width-105px {
  min-width: 6.5625rem !important;
}

.rs-min-width-106px {
  min-width: 6.625rem !important;
}

.rs-min-width-107px {
  min-width: 6.6875rem !important;
}

.rs-min-width-108px {
  min-width: 6.75rem !important;
}

.rs-min-width-109px {
  min-width: 6.8125rem !important;
}

.rs-min-width-110px {
  min-width: 6.875rem !important;
}

.rs-min-width-111px {
  min-width: 6.9375rem !important;
}

.rs-min-width-112px {
  min-width: 7rem !important;
}

.rs-min-width-113px {
  min-width: 7.0625rem !important;
}

.rs-min-width-114px {
  min-width: 7.125rem !important;
}

.rs-min-width-115px {
  min-width: 7.1875rem !important;
}

.rs-min-width-116px {
  min-width: 7.25rem !important;
}

.rs-min-width-117px {
  min-width: 7.3125rem !important;
}

.rs-min-width-118px {
  min-width: 7.375rem !important;
}

.rs-min-width-119px {
  min-width: 7.4375rem !important;
}

.rs-min-width-120px {
  min-width: 7.5rem !important;
}

:root {
  --rs-color--white: #ffffff;
  --rs-color--accent--50: #eaeef6;
  --rs-color--accent--200: #b6c4e5;
  --rs-color--accent--500: #3c4f7f;
  --rs-color--accent--800: #29375e;
  --rs-color--blue--100: #d7faff;
  --rs-color--blue--200: #73dce6;
  --rs-color--blue--300: #57c7ff;
  --rs-color--blue--400: #8cb2ff;
  --rs-color--blue--500: #385a8d;
  --rs-color--purple--500: #b98dd9;
  --rs-color--orange--50: #fff1ea;
  --rs-color--orange--100: #f7c1a8;
  --rs-color--orange--300: #e97845;
  --rs-color--red--50: #ffe7ea;
  --rs-color--red--200: #ea8b8b;
  --rs-color--red--400: #e64341;
  --rs-color--red--700: #c62225;
  --rs-color--green--50: #e9f9e8;
  --rs-color--green--600: #3cb947;
  --rs-color--green--800: #178d33;
  --rs-color--grey--50: #f6f7f9;
  --rs-color--grey--100: #f4f4f4;
  --rs-color--grey--200: #e0e2e4;
  --rs-color--grey--300: #cdced0;
  --rs-color--grey--500: #838486;
  --rs-color--grey--700: #4a4a4c;
  --rs-color--grey--900: #161617;
  --rs-color--yellow--100: #fff9c6;
  --rs-color--yellow--600: #fec051;
  --rs-color--yellow--900: #a75228;
  --rs-color--platform--ord: #aae394;
  --rs-color--platform--mgt: #8fcdda;
  --rs-color--platform--rei: #ef88aa;
  --rs-color--platform--fms: #b290d4;
  --rs-color--platform--mrt: #85a9e1;
  --rs-color--platform--other: #f3a67c;
  --rs-color--mobeyond--50: #e9f4e8;
  --rs-color--mobeyond--600: #4e9944;
}
:root .rs-color--white {
  color: #ffffff !important;
}
:root .rs-color--accent--50 {
  color: #eaeef6 !important;
}
:root .rs-color--accent--200 {
  color: #b6c4e5 !important;
}
:root .rs-color--accent--500 {
  color: #3c4f7f !important;
}
:root .rs-color--accent--800 {
  color: #29375e !important;
}
:root .rs-color--blue--100 {
  color: #d7faff !important;
}
:root .rs-color--blue--200 {
  color: #73dce6 !important;
}
:root .rs-color--blue--300 {
  color: #57c7ff !important;
}
:root .rs-color--blue--400 {
  color: #8cb2ff !important;
}
:root .rs-color--blue--500 {
  color: #385a8d !important;
}
:root .rs-color--purple--500 {
  color: #b98dd9 !important;
}
:root .rs-color--orange--50 {
  color: #fff1ea !important;
}
:root .rs-color--orange--100 {
  color: #f7c1a8 !important;
}
:root .rs-color--orange--300 {
  color: #e97845 !important;
}
:root .rs-color--red--50 {
  color: #ffe7ea !important;
}
:root .rs-color--red--200 {
  color: #ea8b8b !important;
}
:root .rs-color--red--400 {
  color: #e64341 !important;
}
:root .rs-color--red--700 {
  color: #c62225 !important;
}
:root .rs-color--green--50 {
  color: #e9f9e8 !important;
}
:root .rs-color--green--600 {
  color: #3cb947 !important;
}
:root .rs-color--green--800 {
  color: #178d33 !important;
}
:root .rs-color--grey--50 {
  color: #f6f7f9 !important;
}
:root .rs-color--grey--100 {
  color: #f4f4f4 !important;
}
:root .rs-color--grey--200 {
  color: #e0e2e4 !important;
}
:root .rs-color--grey--300 {
  color: #cdced0 !important;
}
:root .rs-color--grey--500 {
  color: #838486 !important;
}
:root .rs-color--grey--700 {
  color: #4a4a4c !important;
}
:root .rs-color--grey--900 {
  color: #161617 !important;
}
:root .rs-color--yellow--100 {
  color: #fff9c6 !important;
}
:root .rs-color--yellow--600 {
  color: #fec051 !important;
}
:root .rs-color--yellow--900 {
  color: #a75228 !important;
}
:root .rs-color--platform--ord {
  color: #aae394 !important;
}
:root .rs-color--platform--mgt {
  color: #8fcdda !important;
}
:root .rs-color--platform--rei {
  color: #ef88aa !important;
}
:root .rs-color--platform--fms {
  color: #b290d4 !important;
}
:root .rs-color--platform--mrt {
  color: #85a9e1 !important;
}
:root .rs-color--platform--other {
  color: #f3a67c !important;
}
:root .rs-color--mobeyond--50 {
  color: #e9f4e8 !important;
}
:root .rs-color--mobeyond--600 {
  color: #4e9944 !important;
}
:root .rs-ds--color--primary {
  color: var(--rs-ds--color--primary);
}
:root .rs-ds--color--error {
  color: var(--rs-ds--color--alert);
}

.rs-bg-rs-color--white {
  background-color: #ffffff !important;
}

.rs-bg-rs-color--accent--50 {
  background-color: #eaeef6 !important;
}

.rs-bg-rs-color--accent--200 {
  background-color: #b6c4e5 !important;
}

.rs-bg-rs-color--accent--500 {
  background-color: #3c4f7f !important;
}

.rs-bg-rs-color--accent--800 {
  background-color: #29375e !important;
}

.rs-bg-rs-color--blue--100 {
  background-color: #d7faff !important;
}

.rs-bg-rs-color--blue--200 {
  background-color: #73dce6 !important;
}

.rs-bg-rs-color--blue--300 {
  background-color: #57c7ff !important;
}

.rs-bg-rs-color--blue--400 {
  background-color: #8cb2ff !important;
}

.rs-bg-rs-color--blue--500 {
  background-color: #385a8d !important;
}

.rs-bg-rs-color--purple--500 {
  background-color: #b98dd9 !important;
}

.rs-bg-rs-color--orange--50 {
  background-color: #fff1ea !important;
}

.rs-bg-rs-color--orange--100 {
  background-color: #f7c1a8 !important;
}

.rs-bg-rs-color--orange--300 {
  background-color: #e97845 !important;
}

.rs-bg-rs-color--red--50 {
  background-color: #ffe7ea !important;
}

.rs-bg-rs-color--red--200 {
  background-color: #ea8b8b !important;
}

.rs-bg-rs-color--red--400 {
  background-color: #e64341 !important;
}

.rs-bg-rs-color--red--700 {
  background-color: #c62225 !important;
}

.rs-bg-rs-color--green--50 {
  background-color: #e9f9e8 !important;
}

.rs-bg-rs-color--green--600 {
  background-color: #3cb947 !important;
}

.rs-bg-rs-color--green--800 {
  background-color: #178d33 !important;
}

.rs-bg-rs-color--grey--50 {
  background-color: #f6f7f9 !important;
}

.rs-bg-rs-color--grey--100 {
  background-color: #f4f4f4 !important;
}

.rs-bg-rs-color--grey--200 {
  background-color: #e0e2e4 !important;
}

.rs-bg-rs-color--grey--300 {
  background-color: #cdced0 !important;
}

.rs-bg-rs-color--grey--500 {
  background-color: #838486 !important;
}

.rs-bg-rs-color--grey--700 {
  background-color: #4a4a4c !important;
}

.rs-bg-rs-color--grey--900 {
  background-color: #161617 !important;
}

.rs-bg-rs-color--yellow--100 {
  background-color: #fff9c6 !important;
}

.rs-bg-rs-color--yellow--600 {
  background-color: #fec051 !important;
}

.rs-bg-rs-color--yellow--900 {
  background-color: #a75228 !important;
}

.rs-bg-rs-color--platform--ord {
  background-color: #aae394 !important;
}

.rs-bg-rs-color--platform--mgt {
  background-color: #8fcdda !important;
}

.rs-bg-rs-color--platform--rei {
  background-color: #ef88aa !important;
}

.rs-bg-rs-color--platform--fms {
  background-color: #b290d4 !important;
}

.rs-bg-rs-color--platform--mrt {
  background-color: #85a9e1 !important;
}

.rs-bg-rs-color--platform--other {
  background-color: #f3a67c !important;
}

.rs-bg-rs-color--mobeyond--50 {
  background-color: #e9f4e8 !important;
}

.rs-bg-rs-color--mobeyond--600 {
  background-color: #4e9944 !important;
}

.rs-ds--bg-color--primary {
  background-color: var(--rs-ds--color--primary);
}

.rs-ds--bg-color--error {
  background-color: var(--rs-ds--color--alert);
}

/** Creating some spacing utilities like boostrap but with rem/px
  * @usage: ms-16px >> margin-left: 1rem !important
  */
.m--60px {
  margin: -3.75rem !important;
}

.ms--60px {
  margin-left: -3.75rem !important;
}

.me--60px {
  margin-right: -3.75rem !important;
}

.mt--60px {
  margin-top: -3.75rem !important;
}

.mb--60px {
  margin-bottom: -3.75rem !important;
}

.my--60px {
  margin-top: -3.75rem !important;
}

.my--60px {
  margin-bottom: -3.75rem !important;
}

.mx--60px {
  margin-left: -3.75rem !important;
}

.mx--60px {
  margin-right: -3.75rem !important;
}

.p--60px {
  padding: -3.75rem !important;
}

.ps--60px {
  padding-left: -3.75rem !important;
}

.pe--60px {
  padding-right: -3.75rem !important;
}

.pt--60px {
  padding-top: -3.75rem !important;
}

.pb--60px {
  padding-bottom: -3.75rem !important;
}

.py--60px {
  padding-top: -3.75rem !important;
}

.py--60px {
  padding-bottom: -3.75rem !important;
}

.px--60px {
  padding-left: -3.75rem !important;
}

.px--60px {
  padding-right: -3.75rem !important;
}

.m--59px {
  margin: -3.6875rem !important;
}

.ms--59px {
  margin-left: -3.6875rem !important;
}

.me--59px {
  margin-right: -3.6875rem !important;
}

.mt--59px {
  margin-top: -3.6875rem !important;
}

.mb--59px {
  margin-bottom: -3.6875rem !important;
}

.my--59px {
  margin-top: -3.6875rem !important;
}

.my--59px {
  margin-bottom: -3.6875rem !important;
}

.mx--59px {
  margin-left: -3.6875rem !important;
}

.mx--59px {
  margin-right: -3.6875rem !important;
}

.p--59px {
  padding: -3.6875rem !important;
}

.ps--59px {
  padding-left: -3.6875rem !important;
}

.pe--59px {
  padding-right: -3.6875rem !important;
}

.pt--59px {
  padding-top: -3.6875rem !important;
}

.pb--59px {
  padding-bottom: -3.6875rem !important;
}

.py--59px {
  padding-top: -3.6875rem !important;
}

.py--59px {
  padding-bottom: -3.6875rem !important;
}

.px--59px {
  padding-left: -3.6875rem !important;
}

.px--59px {
  padding-right: -3.6875rem !important;
}

.m--58px {
  margin: -3.625rem !important;
}

.ms--58px {
  margin-left: -3.625rem !important;
}

.me--58px {
  margin-right: -3.625rem !important;
}

.mt--58px {
  margin-top: -3.625rem !important;
}

.mb--58px {
  margin-bottom: -3.625rem !important;
}

.my--58px {
  margin-top: -3.625rem !important;
}

.my--58px {
  margin-bottom: -3.625rem !important;
}

.mx--58px {
  margin-left: -3.625rem !important;
}

.mx--58px {
  margin-right: -3.625rem !important;
}

.p--58px {
  padding: -3.625rem !important;
}

.ps--58px {
  padding-left: -3.625rem !important;
}

.pe--58px {
  padding-right: -3.625rem !important;
}

.pt--58px {
  padding-top: -3.625rem !important;
}

.pb--58px {
  padding-bottom: -3.625rem !important;
}

.py--58px {
  padding-top: -3.625rem !important;
}

.py--58px {
  padding-bottom: -3.625rem !important;
}

.px--58px {
  padding-left: -3.625rem !important;
}

.px--58px {
  padding-right: -3.625rem !important;
}

.m--57px {
  margin: -3.5625rem !important;
}

.ms--57px {
  margin-left: -3.5625rem !important;
}

.me--57px {
  margin-right: -3.5625rem !important;
}

.mt--57px {
  margin-top: -3.5625rem !important;
}

.mb--57px {
  margin-bottom: -3.5625rem !important;
}

.my--57px {
  margin-top: -3.5625rem !important;
}

.my--57px {
  margin-bottom: -3.5625rem !important;
}

.mx--57px {
  margin-left: -3.5625rem !important;
}

.mx--57px {
  margin-right: -3.5625rem !important;
}

.p--57px {
  padding: -3.5625rem !important;
}

.ps--57px {
  padding-left: -3.5625rem !important;
}

.pe--57px {
  padding-right: -3.5625rem !important;
}

.pt--57px {
  padding-top: -3.5625rem !important;
}

.pb--57px {
  padding-bottom: -3.5625rem !important;
}

.py--57px {
  padding-top: -3.5625rem !important;
}

.py--57px {
  padding-bottom: -3.5625rem !important;
}

.px--57px {
  padding-left: -3.5625rem !important;
}

.px--57px {
  padding-right: -3.5625rem !important;
}

.m--56px {
  margin: -3.5rem !important;
}

.ms--56px {
  margin-left: -3.5rem !important;
}

.me--56px {
  margin-right: -3.5rem !important;
}

.mt--56px {
  margin-top: -3.5rem !important;
}

.mb--56px {
  margin-bottom: -3.5rem !important;
}

.my--56px {
  margin-top: -3.5rem !important;
}

.my--56px {
  margin-bottom: -3.5rem !important;
}

.mx--56px {
  margin-left: -3.5rem !important;
}

.mx--56px {
  margin-right: -3.5rem !important;
}

.p--56px {
  padding: -3.5rem !important;
}

.ps--56px {
  padding-left: -3.5rem !important;
}

.pe--56px {
  padding-right: -3.5rem !important;
}

.pt--56px {
  padding-top: -3.5rem !important;
}

.pb--56px {
  padding-bottom: -3.5rem !important;
}

.py--56px {
  padding-top: -3.5rem !important;
}

.py--56px {
  padding-bottom: -3.5rem !important;
}

.px--56px {
  padding-left: -3.5rem !important;
}

.px--56px {
  padding-right: -3.5rem !important;
}

.m--55px {
  margin: -3.4375rem !important;
}

.ms--55px {
  margin-left: -3.4375rem !important;
}

.me--55px {
  margin-right: -3.4375rem !important;
}

.mt--55px {
  margin-top: -3.4375rem !important;
}

.mb--55px {
  margin-bottom: -3.4375rem !important;
}

.my--55px {
  margin-top: -3.4375rem !important;
}

.my--55px {
  margin-bottom: -3.4375rem !important;
}

.mx--55px {
  margin-left: -3.4375rem !important;
}

.mx--55px {
  margin-right: -3.4375rem !important;
}

.p--55px {
  padding: -3.4375rem !important;
}

.ps--55px {
  padding-left: -3.4375rem !important;
}

.pe--55px {
  padding-right: -3.4375rem !important;
}

.pt--55px {
  padding-top: -3.4375rem !important;
}

.pb--55px {
  padding-bottom: -3.4375rem !important;
}

.py--55px {
  padding-top: -3.4375rem !important;
}

.py--55px {
  padding-bottom: -3.4375rem !important;
}

.px--55px {
  padding-left: -3.4375rem !important;
}

.px--55px {
  padding-right: -3.4375rem !important;
}

.m--54px {
  margin: -3.375rem !important;
}

.ms--54px {
  margin-left: -3.375rem !important;
}

.me--54px {
  margin-right: -3.375rem !important;
}

.mt--54px {
  margin-top: -3.375rem !important;
}

.mb--54px {
  margin-bottom: -3.375rem !important;
}

.my--54px {
  margin-top: -3.375rem !important;
}

.my--54px {
  margin-bottom: -3.375rem !important;
}

.mx--54px {
  margin-left: -3.375rem !important;
}

.mx--54px {
  margin-right: -3.375rem !important;
}

.p--54px {
  padding: -3.375rem !important;
}

.ps--54px {
  padding-left: -3.375rem !important;
}

.pe--54px {
  padding-right: -3.375rem !important;
}

.pt--54px {
  padding-top: -3.375rem !important;
}

.pb--54px {
  padding-bottom: -3.375rem !important;
}

.py--54px {
  padding-top: -3.375rem !important;
}

.py--54px {
  padding-bottom: -3.375rem !important;
}

.px--54px {
  padding-left: -3.375rem !important;
}

.px--54px {
  padding-right: -3.375rem !important;
}

.m--53px {
  margin: -3.3125rem !important;
}

.ms--53px {
  margin-left: -3.3125rem !important;
}

.me--53px {
  margin-right: -3.3125rem !important;
}

.mt--53px {
  margin-top: -3.3125rem !important;
}

.mb--53px {
  margin-bottom: -3.3125rem !important;
}

.my--53px {
  margin-top: -3.3125rem !important;
}

.my--53px {
  margin-bottom: -3.3125rem !important;
}

.mx--53px {
  margin-left: -3.3125rem !important;
}

.mx--53px {
  margin-right: -3.3125rem !important;
}

.p--53px {
  padding: -3.3125rem !important;
}

.ps--53px {
  padding-left: -3.3125rem !important;
}

.pe--53px {
  padding-right: -3.3125rem !important;
}

.pt--53px {
  padding-top: -3.3125rem !important;
}

.pb--53px {
  padding-bottom: -3.3125rem !important;
}

.py--53px {
  padding-top: -3.3125rem !important;
}

.py--53px {
  padding-bottom: -3.3125rem !important;
}

.px--53px {
  padding-left: -3.3125rem !important;
}

.px--53px {
  padding-right: -3.3125rem !important;
}

.m--52px {
  margin: -3.25rem !important;
}

.ms--52px {
  margin-left: -3.25rem !important;
}

.me--52px {
  margin-right: -3.25rem !important;
}

.mt--52px {
  margin-top: -3.25rem !important;
}

.mb--52px {
  margin-bottom: -3.25rem !important;
}

.my--52px {
  margin-top: -3.25rem !important;
}

.my--52px {
  margin-bottom: -3.25rem !important;
}

.mx--52px {
  margin-left: -3.25rem !important;
}

.mx--52px {
  margin-right: -3.25rem !important;
}

.p--52px {
  padding: -3.25rem !important;
}

.ps--52px {
  padding-left: -3.25rem !important;
}

.pe--52px {
  padding-right: -3.25rem !important;
}

.pt--52px {
  padding-top: -3.25rem !important;
}

.pb--52px {
  padding-bottom: -3.25rem !important;
}

.py--52px {
  padding-top: -3.25rem !important;
}

.py--52px {
  padding-bottom: -3.25rem !important;
}

.px--52px {
  padding-left: -3.25rem !important;
}

.px--52px {
  padding-right: -3.25rem !important;
}

.m--51px {
  margin: -3.1875rem !important;
}

.ms--51px {
  margin-left: -3.1875rem !important;
}

.me--51px {
  margin-right: -3.1875rem !important;
}

.mt--51px {
  margin-top: -3.1875rem !important;
}

.mb--51px {
  margin-bottom: -3.1875rem !important;
}

.my--51px {
  margin-top: -3.1875rem !important;
}

.my--51px {
  margin-bottom: -3.1875rem !important;
}

.mx--51px {
  margin-left: -3.1875rem !important;
}

.mx--51px {
  margin-right: -3.1875rem !important;
}

.p--51px {
  padding: -3.1875rem !important;
}

.ps--51px {
  padding-left: -3.1875rem !important;
}

.pe--51px {
  padding-right: -3.1875rem !important;
}

.pt--51px {
  padding-top: -3.1875rem !important;
}

.pb--51px {
  padding-bottom: -3.1875rem !important;
}

.py--51px {
  padding-top: -3.1875rem !important;
}

.py--51px {
  padding-bottom: -3.1875rem !important;
}

.px--51px {
  padding-left: -3.1875rem !important;
}

.px--51px {
  padding-right: -3.1875rem !important;
}

.m--50px {
  margin: -3.125rem !important;
}

.ms--50px {
  margin-left: -3.125rem !important;
}

.me--50px {
  margin-right: -3.125rem !important;
}

.mt--50px {
  margin-top: -3.125rem !important;
}

.mb--50px {
  margin-bottom: -3.125rem !important;
}

.my--50px {
  margin-top: -3.125rem !important;
}

.my--50px {
  margin-bottom: -3.125rem !important;
}

.mx--50px {
  margin-left: -3.125rem !important;
}

.mx--50px {
  margin-right: -3.125rem !important;
}

.p--50px {
  padding: -3.125rem !important;
}

.ps--50px {
  padding-left: -3.125rem !important;
}

.pe--50px {
  padding-right: -3.125rem !important;
}

.pt--50px {
  padding-top: -3.125rem !important;
}

.pb--50px {
  padding-bottom: -3.125rem !important;
}

.py--50px {
  padding-top: -3.125rem !important;
}

.py--50px {
  padding-bottom: -3.125rem !important;
}

.px--50px {
  padding-left: -3.125rem !important;
}

.px--50px {
  padding-right: -3.125rem !important;
}

.m--49px {
  margin: -3.0625rem !important;
}

.ms--49px {
  margin-left: -3.0625rem !important;
}

.me--49px {
  margin-right: -3.0625rem !important;
}

.mt--49px {
  margin-top: -3.0625rem !important;
}

.mb--49px {
  margin-bottom: -3.0625rem !important;
}

.my--49px {
  margin-top: -3.0625rem !important;
}

.my--49px {
  margin-bottom: -3.0625rem !important;
}

.mx--49px {
  margin-left: -3.0625rem !important;
}

.mx--49px {
  margin-right: -3.0625rem !important;
}

.p--49px {
  padding: -3.0625rem !important;
}

.ps--49px {
  padding-left: -3.0625rem !important;
}

.pe--49px {
  padding-right: -3.0625rem !important;
}

.pt--49px {
  padding-top: -3.0625rem !important;
}

.pb--49px {
  padding-bottom: -3.0625rem !important;
}

.py--49px {
  padding-top: -3.0625rem !important;
}

.py--49px {
  padding-bottom: -3.0625rem !important;
}

.px--49px {
  padding-left: -3.0625rem !important;
}

.px--49px {
  padding-right: -3.0625rem !important;
}

.m--48px {
  margin: -3rem !important;
}

.ms--48px {
  margin-left: -3rem !important;
}

.me--48px {
  margin-right: -3rem !important;
}

.mt--48px {
  margin-top: -3rem !important;
}

.mb--48px {
  margin-bottom: -3rem !important;
}

.my--48px {
  margin-top: -3rem !important;
}

.my--48px {
  margin-bottom: -3rem !important;
}

.mx--48px {
  margin-left: -3rem !important;
}

.mx--48px {
  margin-right: -3rem !important;
}

.p--48px {
  padding: -3rem !important;
}

.ps--48px {
  padding-left: -3rem !important;
}

.pe--48px {
  padding-right: -3rem !important;
}

.pt--48px {
  padding-top: -3rem !important;
}

.pb--48px {
  padding-bottom: -3rem !important;
}

.py--48px {
  padding-top: -3rem !important;
}

.py--48px {
  padding-bottom: -3rem !important;
}

.px--48px {
  padding-left: -3rem !important;
}

.px--48px {
  padding-right: -3rem !important;
}

.m--47px {
  margin: -2.9375rem !important;
}

.ms--47px {
  margin-left: -2.9375rem !important;
}

.me--47px {
  margin-right: -2.9375rem !important;
}

.mt--47px {
  margin-top: -2.9375rem !important;
}

.mb--47px {
  margin-bottom: -2.9375rem !important;
}

.my--47px {
  margin-top: -2.9375rem !important;
}

.my--47px {
  margin-bottom: -2.9375rem !important;
}

.mx--47px {
  margin-left: -2.9375rem !important;
}

.mx--47px {
  margin-right: -2.9375rem !important;
}

.p--47px {
  padding: -2.9375rem !important;
}

.ps--47px {
  padding-left: -2.9375rem !important;
}

.pe--47px {
  padding-right: -2.9375rem !important;
}

.pt--47px {
  padding-top: -2.9375rem !important;
}

.pb--47px {
  padding-bottom: -2.9375rem !important;
}

.py--47px {
  padding-top: -2.9375rem !important;
}

.py--47px {
  padding-bottom: -2.9375rem !important;
}

.px--47px {
  padding-left: -2.9375rem !important;
}

.px--47px {
  padding-right: -2.9375rem !important;
}

.m--46px {
  margin: -2.875rem !important;
}

.ms--46px {
  margin-left: -2.875rem !important;
}

.me--46px {
  margin-right: -2.875rem !important;
}

.mt--46px {
  margin-top: -2.875rem !important;
}

.mb--46px {
  margin-bottom: -2.875rem !important;
}

.my--46px {
  margin-top: -2.875rem !important;
}

.my--46px {
  margin-bottom: -2.875rem !important;
}

.mx--46px {
  margin-left: -2.875rem !important;
}

.mx--46px {
  margin-right: -2.875rem !important;
}

.p--46px {
  padding: -2.875rem !important;
}

.ps--46px {
  padding-left: -2.875rem !important;
}

.pe--46px {
  padding-right: -2.875rem !important;
}

.pt--46px {
  padding-top: -2.875rem !important;
}

.pb--46px {
  padding-bottom: -2.875rem !important;
}

.py--46px {
  padding-top: -2.875rem !important;
}

.py--46px {
  padding-bottom: -2.875rem !important;
}

.px--46px {
  padding-left: -2.875rem !important;
}

.px--46px {
  padding-right: -2.875rem !important;
}

.m--45px {
  margin: -2.8125rem !important;
}

.ms--45px {
  margin-left: -2.8125rem !important;
}

.me--45px {
  margin-right: -2.8125rem !important;
}

.mt--45px {
  margin-top: -2.8125rem !important;
}

.mb--45px {
  margin-bottom: -2.8125rem !important;
}

.my--45px {
  margin-top: -2.8125rem !important;
}

.my--45px {
  margin-bottom: -2.8125rem !important;
}

.mx--45px {
  margin-left: -2.8125rem !important;
}

.mx--45px {
  margin-right: -2.8125rem !important;
}

.p--45px {
  padding: -2.8125rem !important;
}

.ps--45px {
  padding-left: -2.8125rem !important;
}

.pe--45px {
  padding-right: -2.8125rem !important;
}

.pt--45px {
  padding-top: -2.8125rem !important;
}

.pb--45px {
  padding-bottom: -2.8125rem !important;
}

.py--45px {
  padding-top: -2.8125rem !important;
}

.py--45px {
  padding-bottom: -2.8125rem !important;
}

.px--45px {
  padding-left: -2.8125rem !important;
}

.px--45px {
  padding-right: -2.8125rem !important;
}

.m--44px {
  margin: -2.75rem !important;
}

.ms--44px {
  margin-left: -2.75rem !important;
}

.me--44px {
  margin-right: -2.75rem !important;
}

.mt--44px {
  margin-top: -2.75rem !important;
}

.mb--44px {
  margin-bottom: -2.75rem !important;
}

.my--44px {
  margin-top: -2.75rem !important;
}

.my--44px {
  margin-bottom: -2.75rem !important;
}

.mx--44px {
  margin-left: -2.75rem !important;
}

.mx--44px {
  margin-right: -2.75rem !important;
}

.p--44px {
  padding: -2.75rem !important;
}

.ps--44px {
  padding-left: -2.75rem !important;
}

.pe--44px {
  padding-right: -2.75rem !important;
}

.pt--44px {
  padding-top: -2.75rem !important;
}

.pb--44px {
  padding-bottom: -2.75rem !important;
}

.py--44px {
  padding-top: -2.75rem !important;
}

.py--44px {
  padding-bottom: -2.75rem !important;
}

.px--44px {
  padding-left: -2.75rem !important;
}

.px--44px {
  padding-right: -2.75rem !important;
}

.m--43px {
  margin: -2.6875rem !important;
}

.ms--43px {
  margin-left: -2.6875rem !important;
}

.me--43px {
  margin-right: -2.6875rem !important;
}

.mt--43px {
  margin-top: -2.6875rem !important;
}

.mb--43px {
  margin-bottom: -2.6875rem !important;
}

.my--43px {
  margin-top: -2.6875rem !important;
}

.my--43px {
  margin-bottom: -2.6875rem !important;
}

.mx--43px {
  margin-left: -2.6875rem !important;
}

.mx--43px {
  margin-right: -2.6875rem !important;
}

.p--43px {
  padding: -2.6875rem !important;
}

.ps--43px {
  padding-left: -2.6875rem !important;
}

.pe--43px {
  padding-right: -2.6875rem !important;
}

.pt--43px {
  padding-top: -2.6875rem !important;
}

.pb--43px {
  padding-bottom: -2.6875rem !important;
}

.py--43px {
  padding-top: -2.6875rem !important;
}

.py--43px {
  padding-bottom: -2.6875rem !important;
}

.px--43px {
  padding-left: -2.6875rem !important;
}

.px--43px {
  padding-right: -2.6875rem !important;
}

.m--42px {
  margin: -2.625rem !important;
}

.ms--42px {
  margin-left: -2.625rem !important;
}

.me--42px {
  margin-right: -2.625rem !important;
}

.mt--42px {
  margin-top: -2.625rem !important;
}

.mb--42px {
  margin-bottom: -2.625rem !important;
}

.my--42px {
  margin-top: -2.625rem !important;
}

.my--42px {
  margin-bottom: -2.625rem !important;
}

.mx--42px {
  margin-left: -2.625rem !important;
}

.mx--42px {
  margin-right: -2.625rem !important;
}

.p--42px {
  padding: -2.625rem !important;
}

.ps--42px {
  padding-left: -2.625rem !important;
}

.pe--42px {
  padding-right: -2.625rem !important;
}

.pt--42px {
  padding-top: -2.625rem !important;
}

.pb--42px {
  padding-bottom: -2.625rem !important;
}

.py--42px {
  padding-top: -2.625rem !important;
}

.py--42px {
  padding-bottom: -2.625rem !important;
}

.px--42px {
  padding-left: -2.625rem !important;
}

.px--42px {
  padding-right: -2.625rem !important;
}

.m--41px {
  margin: -2.5625rem !important;
}

.ms--41px {
  margin-left: -2.5625rem !important;
}

.me--41px {
  margin-right: -2.5625rem !important;
}

.mt--41px {
  margin-top: -2.5625rem !important;
}

.mb--41px {
  margin-bottom: -2.5625rem !important;
}

.my--41px {
  margin-top: -2.5625rem !important;
}

.my--41px {
  margin-bottom: -2.5625rem !important;
}

.mx--41px {
  margin-left: -2.5625rem !important;
}

.mx--41px {
  margin-right: -2.5625rem !important;
}

.p--41px {
  padding: -2.5625rem !important;
}

.ps--41px {
  padding-left: -2.5625rem !important;
}

.pe--41px {
  padding-right: -2.5625rem !important;
}

.pt--41px {
  padding-top: -2.5625rem !important;
}

.pb--41px {
  padding-bottom: -2.5625rem !important;
}

.py--41px {
  padding-top: -2.5625rem !important;
}

.py--41px {
  padding-bottom: -2.5625rem !important;
}

.px--41px {
  padding-left: -2.5625rem !important;
}

.px--41px {
  padding-right: -2.5625rem !important;
}

.m--40px {
  margin: -2.5rem !important;
}

.ms--40px {
  margin-left: -2.5rem !important;
}

.me--40px {
  margin-right: -2.5rem !important;
}

.mt--40px {
  margin-top: -2.5rem !important;
}

.mb--40px {
  margin-bottom: -2.5rem !important;
}

.my--40px {
  margin-top: -2.5rem !important;
}

.my--40px {
  margin-bottom: -2.5rem !important;
}

.mx--40px {
  margin-left: -2.5rem !important;
}

.mx--40px {
  margin-right: -2.5rem !important;
}

.p--40px {
  padding: -2.5rem !important;
}

.ps--40px {
  padding-left: -2.5rem !important;
}

.pe--40px {
  padding-right: -2.5rem !important;
}

.pt--40px {
  padding-top: -2.5rem !important;
}

.pb--40px {
  padding-bottom: -2.5rem !important;
}

.py--40px {
  padding-top: -2.5rem !important;
}

.py--40px {
  padding-bottom: -2.5rem !important;
}

.px--40px {
  padding-left: -2.5rem !important;
}

.px--40px {
  padding-right: -2.5rem !important;
}

.m--39px {
  margin: -2.4375rem !important;
}

.ms--39px {
  margin-left: -2.4375rem !important;
}

.me--39px {
  margin-right: -2.4375rem !important;
}

.mt--39px {
  margin-top: -2.4375rem !important;
}

.mb--39px {
  margin-bottom: -2.4375rem !important;
}

.my--39px {
  margin-top: -2.4375rem !important;
}

.my--39px {
  margin-bottom: -2.4375rem !important;
}

.mx--39px {
  margin-left: -2.4375rem !important;
}

.mx--39px {
  margin-right: -2.4375rem !important;
}

.p--39px {
  padding: -2.4375rem !important;
}

.ps--39px {
  padding-left: -2.4375rem !important;
}

.pe--39px {
  padding-right: -2.4375rem !important;
}

.pt--39px {
  padding-top: -2.4375rem !important;
}

.pb--39px {
  padding-bottom: -2.4375rem !important;
}

.py--39px {
  padding-top: -2.4375rem !important;
}

.py--39px {
  padding-bottom: -2.4375rem !important;
}

.px--39px {
  padding-left: -2.4375rem !important;
}

.px--39px {
  padding-right: -2.4375rem !important;
}

.m--38px {
  margin: -2.375rem !important;
}

.ms--38px {
  margin-left: -2.375rem !important;
}

.me--38px {
  margin-right: -2.375rem !important;
}

.mt--38px {
  margin-top: -2.375rem !important;
}

.mb--38px {
  margin-bottom: -2.375rem !important;
}

.my--38px {
  margin-top: -2.375rem !important;
}

.my--38px {
  margin-bottom: -2.375rem !important;
}

.mx--38px {
  margin-left: -2.375rem !important;
}

.mx--38px {
  margin-right: -2.375rem !important;
}

.p--38px {
  padding: -2.375rem !important;
}

.ps--38px {
  padding-left: -2.375rem !important;
}

.pe--38px {
  padding-right: -2.375rem !important;
}

.pt--38px {
  padding-top: -2.375rem !important;
}

.pb--38px {
  padding-bottom: -2.375rem !important;
}

.py--38px {
  padding-top: -2.375rem !important;
}

.py--38px {
  padding-bottom: -2.375rem !important;
}

.px--38px {
  padding-left: -2.375rem !important;
}

.px--38px {
  padding-right: -2.375rem !important;
}

.m--37px {
  margin: -2.3125rem !important;
}

.ms--37px {
  margin-left: -2.3125rem !important;
}

.me--37px {
  margin-right: -2.3125rem !important;
}

.mt--37px {
  margin-top: -2.3125rem !important;
}

.mb--37px {
  margin-bottom: -2.3125rem !important;
}

.my--37px {
  margin-top: -2.3125rem !important;
}

.my--37px {
  margin-bottom: -2.3125rem !important;
}

.mx--37px {
  margin-left: -2.3125rem !important;
}

.mx--37px {
  margin-right: -2.3125rem !important;
}

.p--37px {
  padding: -2.3125rem !important;
}

.ps--37px {
  padding-left: -2.3125rem !important;
}

.pe--37px {
  padding-right: -2.3125rem !important;
}

.pt--37px {
  padding-top: -2.3125rem !important;
}

.pb--37px {
  padding-bottom: -2.3125rem !important;
}

.py--37px {
  padding-top: -2.3125rem !important;
}

.py--37px {
  padding-bottom: -2.3125rem !important;
}

.px--37px {
  padding-left: -2.3125rem !important;
}

.px--37px {
  padding-right: -2.3125rem !important;
}

.m--36px {
  margin: -2.25rem !important;
}

.ms--36px {
  margin-left: -2.25rem !important;
}

.me--36px {
  margin-right: -2.25rem !important;
}

.mt--36px {
  margin-top: -2.25rem !important;
}

.mb--36px {
  margin-bottom: -2.25rem !important;
}

.my--36px {
  margin-top: -2.25rem !important;
}

.my--36px {
  margin-bottom: -2.25rem !important;
}

.mx--36px {
  margin-left: -2.25rem !important;
}

.mx--36px {
  margin-right: -2.25rem !important;
}

.p--36px {
  padding: -2.25rem !important;
}

.ps--36px {
  padding-left: -2.25rem !important;
}

.pe--36px {
  padding-right: -2.25rem !important;
}

.pt--36px {
  padding-top: -2.25rem !important;
}

.pb--36px {
  padding-bottom: -2.25rem !important;
}

.py--36px {
  padding-top: -2.25rem !important;
}

.py--36px {
  padding-bottom: -2.25rem !important;
}

.px--36px {
  padding-left: -2.25rem !important;
}

.px--36px {
  padding-right: -2.25rem !important;
}

.m--35px {
  margin: -2.1875rem !important;
}

.ms--35px {
  margin-left: -2.1875rem !important;
}

.me--35px {
  margin-right: -2.1875rem !important;
}

.mt--35px {
  margin-top: -2.1875rem !important;
}

.mb--35px {
  margin-bottom: -2.1875rem !important;
}

.my--35px {
  margin-top: -2.1875rem !important;
}

.my--35px {
  margin-bottom: -2.1875rem !important;
}

.mx--35px {
  margin-left: -2.1875rem !important;
}

.mx--35px {
  margin-right: -2.1875rem !important;
}

.p--35px {
  padding: -2.1875rem !important;
}

.ps--35px {
  padding-left: -2.1875rem !important;
}

.pe--35px {
  padding-right: -2.1875rem !important;
}

.pt--35px {
  padding-top: -2.1875rem !important;
}

.pb--35px {
  padding-bottom: -2.1875rem !important;
}

.py--35px {
  padding-top: -2.1875rem !important;
}

.py--35px {
  padding-bottom: -2.1875rem !important;
}

.px--35px {
  padding-left: -2.1875rem !important;
}

.px--35px {
  padding-right: -2.1875rem !important;
}

.m--34px {
  margin: -2.125rem !important;
}

.ms--34px {
  margin-left: -2.125rem !important;
}

.me--34px {
  margin-right: -2.125rem !important;
}

.mt--34px {
  margin-top: -2.125rem !important;
}

.mb--34px {
  margin-bottom: -2.125rem !important;
}

.my--34px {
  margin-top: -2.125rem !important;
}

.my--34px {
  margin-bottom: -2.125rem !important;
}

.mx--34px {
  margin-left: -2.125rem !important;
}

.mx--34px {
  margin-right: -2.125rem !important;
}

.p--34px {
  padding: -2.125rem !important;
}

.ps--34px {
  padding-left: -2.125rem !important;
}

.pe--34px {
  padding-right: -2.125rem !important;
}

.pt--34px {
  padding-top: -2.125rem !important;
}

.pb--34px {
  padding-bottom: -2.125rem !important;
}

.py--34px {
  padding-top: -2.125rem !important;
}

.py--34px {
  padding-bottom: -2.125rem !important;
}

.px--34px {
  padding-left: -2.125rem !important;
}

.px--34px {
  padding-right: -2.125rem !important;
}

.m--33px {
  margin: -2.0625rem !important;
}

.ms--33px {
  margin-left: -2.0625rem !important;
}

.me--33px {
  margin-right: -2.0625rem !important;
}

.mt--33px {
  margin-top: -2.0625rem !important;
}

.mb--33px {
  margin-bottom: -2.0625rem !important;
}

.my--33px {
  margin-top: -2.0625rem !important;
}

.my--33px {
  margin-bottom: -2.0625rem !important;
}

.mx--33px {
  margin-left: -2.0625rem !important;
}

.mx--33px {
  margin-right: -2.0625rem !important;
}

.p--33px {
  padding: -2.0625rem !important;
}

.ps--33px {
  padding-left: -2.0625rem !important;
}

.pe--33px {
  padding-right: -2.0625rem !important;
}

.pt--33px {
  padding-top: -2.0625rem !important;
}

.pb--33px {
  padding-bottom: -2.0625rem !important;
}

.py--33px {
  padding-top: -2.0625rem !important;
}

.py--33px {
  padding-bottom: -2.0625rem !important;
}

.px--33px {
  padding-left: -2.0625rem !important;
}

.px--33px {
  padding-right: -2.0625rem !important;
}

.m--32px {
  margin: -2rem !important;
}

.ms--32px {
  margin-left: -2rem !important;
}

.me--32px {
  margin-right: -2rem !important;
}

.mt--32px {
  margin-top: -2rem !important;
}

.mb--32px {
  margin-bottom: -2rem !important;
}

.my--32px {
  margin-top: -2rem !important;
}

.my--32px {
  margin-bottom: -2rem !important;
}

.mx--32px {
  margin-left: -2rem !important;
}

.mx--32px {
  margin-right: -2rem !important;
}

.p--32px {
  padding: -2rem !important;
}

.ps--32px {
  padding-left: -2rem !important;
}

.pe--32px {
  padding-right: -2rem !important;
}

.pt--32px {
  padding-top: -2rem !important;
}

.pb--32px {
  padding-bottom: -2rem !important;
}

.py--32px {
  padding-top: -2rem !important;
}

.py--32px {
  padding-bottom: -2rem !important;
}

.px--32px {
  padding-left: -2rem !important;
}

.px--32px {
  padding-right: -2rem !important;
}

.m--31px {
  margin: -1.9375rem !important;
}

.ms--31px {
  margin-left: -1.9375rem !important;
}

.me--31px {
  margin-right: -1.9375rem !important;
}

.mt--31px {
  margin-top: -1.9375rem !important;
}

.mb--31px {
  margin-bottom: -1.9375rem !important;
}

.my--31px {
  margin-top: -1.9375rem !important;
}

.my--31px {
  margin-bottom: -1.9375rem !important;
}

.mx--31px {
  margin-left: -1.9375rem !important;
}

.mx--31px {
  margin-right: -1.9375rem !important;
}

.p--31px {
  padding: -1.9375rem !important;
}

.ps--31px {
  padding-left: -1.9375rem !important;
}

.pe--31px {
  padding-right: -1.9375rem !important;
}

.pt--31px {
  padding-top: -1.9375rem !important;
}

.pb--31px {
  padding-bottom: -1.9375rem !important;
}

.py--31px {
  padding-top: -1.9375rem !important;
}

.py--31px {
  padding-bottom: -1.9375rem !important;
}

.px--31px {
  padding-left: -1.9375rem !important;
}

.px--31px {
  padding-right: -1.9375rem !important;
}

.m--30px {
  margin: -1.875rem !important;
}

.ms--30px {
  margin-left: -1.875rem !important;
}

.me--30px {
  margin-right: -1.875rem !important;
}

.mt--30px {
  margin-top: -1.875rem !important;
}

.mb--30px {
  margin-bottom: -1.875rem !important;
}

.my--30px {
  margin-top: -1.875rem !important;
}

.my--30px {
  margin-bottom: -1.875rem !important;
}

.mx--30px {
  margin-left: -1.875rem !important;
}

.mx--30px {
  margin-right: -1.875rem !important;
}

.p--30px {
  padding: -1.875rem !important;
}

.ps--30px {
  padding-left: -1.875rem !important;
}

.pe--30px {
  padding-right: -1.875rem !important;
}

.pt--30px {
  padding-top: -1.875rem !important;
}

.pb--30px {
  padding-bottom: -1.875rem !important;
}

.py--30px {
  padding-top: -1.875rem !important;
}

.py--30px {
  padding-bottom: -1.875rem !important;
}

.px--30px {
  padding-left: -1.875rem !important;
}

.px--30px {
  padding-right: -1.875rem !important;
}

.m--29px {
  margin: -1.8125rem !important;
}

.ms--29px {
  margin-left: -1.8125rem !important;
}

.me--29px {
  margin-right: -1.8125rem !important;
}

.mt--29px {
  margin-top: -1.8125rem !important;
}

.mb--29px {
  margin-bottom: -1.8125rem !important;
}

.my--29px {
  margin-top: -1.8125rem !important;
}

.my--29px {
  margin-bottom: -1.8125rem !important;
}

.mx--29px {
  margin-left: -1.8125rem !important;
}

.mx--29px {
  margin-right: -1.8125rem !important;
}

.p--29px {
  padding: -1.8125rem !important;
}

.ps--29px {
  padding-left: -1.8125rem !important;
}

.pe--29px {
  padding-right: -1.8125rem !important;
}

.pt--29px {
  padding-top: -1.8125rem !important;
}

.pb--29px {
  padding-bottom: -1.8125rem !important;
}

.py--29px {
  padding-top: -1.8125rem !important;
}

.py--29px {
  padding-bottom: -1.8125rem !important;
}

.px--29px {
  padding-left: -1.8125rem !important;
}

.px--29px {
  padding-right: -1.8125rem !important;
}

.m--28px {
  margin: -1.75rem !important;
}

.ms--28px {
  margin-left: -1.75rem !important;
}

.me--28px {
  margin-right: -1.75rem !important;
}

.mt--28px {
  margin-top: -1.75rem !important;
}

.mb--28px {
  margin-bottom: -1.75rem !important;
}

.my--28px {
  margin-top: -1.75rem !important;
}

.my--28px {
  margin-bottom: -1.75rem !important;
}

.mx--28px {
  margin-left: -1.75rem !important;
}

.mx--28px {
  margin-right: -1.75rem !important;
}

.p--28px {
  padding: -1.75rem !important;
}

.ps--28px {
  padding-left: -1.75rem !important;
}

.pe--28px {
  padding-right: -1.75rem !important;
}

.pt--28px {
  padding-top: -1.75rem !important;
}

.pb--28px {
  padding-bottom: -1.75rem !important;
}

.py--28px {
  padding-top: -1.75rem !important;
}

.py--28px {
  padding-bottom: -1.75rem !important;
}

.px--28px {
  padding-left: -1.75rem !important;
}

.px--28px {
  padding-right: -1.75rem !important;
}

.m--27px {
  margin: -1.6875rem !important;
}

.ms--27px {
  margin-left: -1.6875rem !important;
}

.me--27px {
  margin-right: -1.6875rem !important;
}

.mt--27px {
  margin-top: -1.6875rem !important;
}

.mb--27px {
  margin-bottom: -1.6875rem !important;
}

.my--27px {
  margin-top: -1.6875rem !important;
}

.my--27px {
  margin-bottom: -1.6875rem !important;
}

.mx--27px {
  margin-left: -1.6875rem !important;
}

.mx--27px {
  margin-right: -1.6875rem !important;
}

.p--27px {
  padding: -1.6875rem !important;
}

.ps--27px {
  padding-left: -1.6875rem !important;
}

.pe--27px {
  padding-right: -1.6875rem !important;
}

.pt--27px {
  padding-top: -1.6875rem !important;
}

.pb--27px {
  padding-bottom: -1.6875rem !important;
}

.py--27px {
  padding-top: -1.6875rem !important;
}

.py--27px {
  padding-bottom: -1.6875rem !important;
}

.px--27px {
  padding-left: -1.6875rem !important;
}

.px--27px {
  padding-right: -1.6875rem !important;
}

.m--26px {
  margin: -1.625rem !important;
}

.ms--26px {
  margin-left: -1.625rem !important;
}

.me--26px {
  margin-right: -1.625rem !important;
}

.mt--26px {
  margin-top: -1.625rem !important;
}

.mb--26px {
  margin-bottom: -1.625rem !important;
}

.my--26px {
  margin-top: -1.625rem !important;
}

.my--26px {
  margin-bottom: -1.625rem !important;
}

.mx--26px {
  margin-left: -1.625rem !important;
}

.mx--26px {
  margin-right: -1.625rem !important;
}

.p--26px {
  padding: -1.625rem !important;
}

.ps--26px {
  padding-left: -1.625rem !important;
}

.pe--26px {
  padding-right: -1.625rem !important;
}

.pt--26px {
  padding-top: -1.625rem !important;
}

.pb--26px {
  padding-bottom: -1.625rem !important;
}

.py--26px {
  padding-top: -1.625rem !important;
}

.py--26px {
  padding-bottom: -1.625rem !important;
}

.px--26px {
  padding-left: -1.625rem !important;
}

.px--26px {
  padding-right: -1.625rem !important;
}

.m--25px {
  margin: -1.5625rem !important;
}

.ms--25px {
  margin-left: -1.5625rem !important;
}

.me--25px {
  margin-right: -1.5625rem !important;
}

.mt--25px {
  margin-top: -1.5625rem !important;
}

.mb--25px {
  margin-bottom: -1.5625rem !important;
}

.my--25px {
  margin-top: -1.5625rem !important;
}

.my--25px {
  margin-bottom: -1.5625rem !important;
}

.mx--25px {
  margin-left: -1.5625rem !important;
}

.mx--25px {
  margin-right: -1.5625rem !important;
}

.p--25px {
  padding: -1.5625rem !important;
}

.ps--25px {
  padding-left: -1.5625rem !important;
}

.pe--25px {
  padding-right: -1.5625rem !important;
}

.pt--25px {
  padding-top: -1.5625rem !important;
}

.pb--25px {
  padding-bottom: -1.5625rem !important;
}

.py--25px {
  padding-top: -1.5625rem !important;
}

.py--25px {
  padding-bottom: -1.5625rem !important;
}

.px--25px {
  padding-left: -1.5625rem !important;
}

.px--25px {
  padding-right: -1.5625rem !important;
}

.m--24px {
  margin: -1.5rem !important;
}

.ms--24px {
  margin-left: -1.5rem !important;
}

.me--24px {
  margin-right: -1.5rem !important;
}

.mt--24px {
  margin-top: -1.5rem !important;
}

.mb--24px {
  margin-bottom: -1.5rem !important;
}

.my--24px {
  margin-top: -1.5rem !important;
}

.my--24px {
  margin-bottom: -1.5rem !important;
}

.mx--24px {
  margin-left: -1.5rem !important;
}

.mx--24px {
  margin-right: -1.5rem !important;
}

.p--24px {
  padding: -1.5rem !important;
}

.ps--24px {
  padding-left: -1.5rem !important;
}

.pe--24px {
  padding-right: -1.5rem !important;
}

.pt--24px {
  padding-top: -1.5rem !important;
}

.pb--24px {
  padding-bottom: -1.5rem !important;
}

.py--24px {
  padding-top: -1.5rem !important;
}

.py--24px {
  padding-bottom: -1.5rem !important;
}

.px--24px {
  padding-left: -1.5rem !important;
}

.px--24px {
  padding-right: -1.5rem !important;
}

.m--23px {
  margin: -1.4375rem !important;
}

.ms--23px {
  margin-left: -1.4375rem !important;
}

.me--23px {
  margin-right: -1.4375rem !important;
}

.mt--23px {
  margin-top: -1.4375rem !important;
}

.mb--23px {
  margin-bottom: -1.4375rem !important;
}

.my--23px {
  margin-top: -1.4375rem !important;
}

.my--23px {
  margin-bottom: -1.4375rem !important;
}

.mx--23px {
  margin-left: -1.4375rem !important;
}

.mx--23px {
  margin-right: -1.4375rem !important;
}

.p--23px {
  padding: -1.4375rem !important;
}

.ps--23px {
  padding-left: -1.4375rem !important;
}

.pe--23px {
  padding-right: -1.4375rem !important;
}

.pt--23px {
  padding-top: -1.4375rem !important;
}

.pb--23px {
  padding-bottom: -1.4375rem !important;
}

.py--23px {
  padding-top: -1.4375rem !important;
}

.py--23px {
  padding-bottom: -1.4375rem !important;
}

.px--23px {
  padding-left: -1.4375rem !important;
}

.px--23px {
  padding-right: -1.4375rem !important;
}

.m--22px {
  margin: -1.375rem !important;
}

.ms--22px {
  margin-left: -1.375rem !important;
}

.me--22px {
  margin-right: -1.375rem !important;
}

.mt--22px {
  margin-top: -1.375rem !important;
}

.mb--22px {
  margin-bottom: -1.375rem !important;
}

.my--22px {
  margin-top: -1.375rem !important;
}

.my--22px {
  margin-bottom: -1.375rem !important;
}

.mx--22px {
  margin-left: -1.375rem !important;
}

.mx--22px {
  margin-right: -1.375rem !important;
}

.p--22px {
  padding: -1.375rem !important;
}

.ps--22px {
  padding-left: -1.375rem !important;
}

.pe--22px {
  padding-right: -1.375rem !important;
}

.pt--22px {
  padding-top: -1.375rem !important;
}

.pb--22px {
  padding-bottom: -1.375rem !important;
}

.py--22px {
  padding-top: -1.375rem !important;
}

.py--22px {
  padding-bottom: -1.375rem !important;
}

.px--22px {
  padding-left: -1.375rem !important;
}

.px--22px {
  padding-right: -1.375rem !important;
}

.m--21px {
  margin: -1.3125rem !important;
}

.ms--21px {
  margin-left: -1.3125rem !important;
}

.me--21px {
  margin-right: -1.3125rem !important;
}

.mt--21px {
  margin-top: -1.3125rem !important;
}

.mb--21px {
  margin-bottom: -1.3125rem !important;
}

.my--21px {
  margin-top: -1.3125rem !important;
}

.my--21px {
  margin-bottom: -1.3125rem !important;
}

.mx--21px {
  margin-left: -1.3125rem !important;
}

.mx--21px {
  margin-right: -1.3125rem !important;
}

.p--21px {
  padding: -1.3125rem !important;
}

.ps--21px {
  padding-left: -1.3125rem !important;
}

.pe--21px {
  padding-right: -1.3125rem !important;
}

.pt--21px {
  padding-top: -1.3125rem !important;
}

.pb--21px {
  padding-bottom: -1.3125rem !important;
}

.py--21px {
  padding-top: -1.3125rem !important;
}

.py--21px {
  padding-bottom: -1.3125rem !important;
}

.px--21px {
  padding-left: -1.3125rem !important;
}

.px--21px {
  padding-right: -1.3125rem !important;
}

.m--20px {
  margin: -1.25rem !important;
}

.ms--20px {
  margin-left: -1.25rem !important;
}

.me--20px {
  margin-right: -1.25rem !important;
}

.mt--20px {
  margin-top: -1.25rem !important;
}

.mb--20px {
  margin-bottom: -1.25rem !important;
}

.my--20px {
  margin-top: -1.25rem !important;
}

.my--20px {
  margin-bottom: -1.25rem !important;
}

.mx--20px {
  margin-left: -1.25rem !important;
}

.mx--20px {
  margin-right: -1.25rem !important;
}

.p--20px {
  padding: -1.25rem !important;
}

.ps--20px {
  padding-left: -1.25rem !important;
}

.pe--20px {
  padding-right: -1.25rem !important;
}

.pt--20px {
  padding-top: -1.25rem !important;
}

.pb--20px {
  padding-bottom: -1.25rem !important;
}

.py--20px {
  padding-top: -1.25rem !important;
}

.py--20px {
  padding-bottom: -1.25rem !important;
}

.px--20px {
  padding-left: -1.25rem !important;
}

.px--20px {
  padding-right: -1.25rem !important;
}

.m--19px {
  margin: -1.1875rem !important;
}

.ms--19px {
  margin-left: -1.1875rem !important;
}

.me--19px {
  margin-right: -1.1875rem !important;
}

.mt--19px {
  margin-top: -1.1875rem !important;
}

.mb--19px {
  margin-bottom: -1.1875rem !important;
}

.my--19px {
  margin-top: -1.1875rem !important;
}

.my--19px {
  margin-bottom: -1.1875rem !important;
}

.mx--19px {
  margin-left: -1.1875rem !important;
}

.mx--19px {
  margin-right: -1.1875rem !important;
}

.p--19px {
  padding: -1.1875rem !important;
}

.ps--19px {
  padding-left: -1.1875rem !important;
}

.pe--19px {
  padding-right: -1.1875rem !important;
}

.pt--19px {
  padding-top: -1.1875rem !important;
}

.pb--19px {
  padding-bottom: -1.1875rem !important;
}

.py--19px {
  padding-top: -1.1875rem !important;
}

.py--19px {
  padding-bottom: -1.1875rem !important;
}

.px--19px {
  padding-left: -1.1875rem !important;
}

.px--19px {
  padding-right: -1.1875rem !important;
}

.m--18px {
  margin: -1.125rem !important;
}

.ms--18px {
  margin-left: -1.125rem !important;
}

.me--18px {
  margin-right: -1.125rem !important;
}

.mt--18px {
  margin-top: -1.125rem !important;
}

.mb--18px {
  margin-bottom: -1.125rem !important;
}

.my--18px {
  margin-top: -1.125rem !important;
}

.my--18px {
  margin-bottom: -1.125rem !important;
}

.mx--18px {
  margin-left: -1.125rem !important;
}

.mx--18px {
  margin-right: -1.125rem !important;
}

.p--18px {
  padding: -1.125rem !important;
}

.ps--18px {
  padding-left: -1.125rem !important;
}

.pe--18px {
  padding-right: -1.125rem !important;
}

.pt--18px {
  padding-top: -1.125rem !important;
}

.pb--18px {
  padding-bottom: -1.125rem !important;
}

.py--18px {
  padding-top: -1.125rem !important;
}

.py--18px {
  padding-bottom: -1.125rem !important;
}

.px--18px {
  padding-left: -1.125rem !important;
}

.px--18px {
  padding-right: -1.125rem !important;
}

.m--17px {
  margin: -1.0625rem !important;
}

.ms--17px {
  margin-left: -1.0625rem !important;
}

.me--17px {
  margin-right: -1.0625rem !important;
}

.mt--17px {
  margin-top: -1.0625rem !important;
}

.mb--17px {
  margin-bottom: -1.0625rem !important;
}

.my--17px {
  margin-top: -1.0625rem !important;
}

.my--17px {
  margin-bottom: -1.0625rem !important;
}

.mx--17px {
  margin-left: -1.0625rem !important;
}

.mx--17px {
  margin-right: -1.0625rem !important;
}

.p--17px {
  padding: -1.0625rem !important;
}

.ps--17px {
  padding-left: -1.0625rem !important;
}

.pe--17px {
  padding-right: -1.0625rem !important;
}

.pt--17px {
  padding-top: -1.0625rem !important;
}

.pb--17px {
  padding-bottom: -1.0625rem !important;
}

.py--17px {
  padding-top: -1.0625rem !important;
}

.py--17px {
  padding-bottom: -1.0625rem !important;
}

.px--17px {
  padding-left: -1.0625rem !important;
}

.px--17px {
  padding-right: -1.0625rem !important;
}

.m--16px {
  margin: -1rem !important;
}

.ms--16px {
  margin-left: -1rem !important;
}

.me--16px {
  margin-right: -1rem !important;
}

.mt--16px {
  margin-top: -1rem !important;
}

.mb--16px {
  margin-bottom: -1rem !important;
}

.my--16px {
  margin-top: -1rem !important;
}

.my--16px {
  margin-bottom: -1rem !important;
}

.mx--16px {
  margin-left: -1rem !important;
}

.mx--16px {
  margin-right: -1rem !important;
}

.p--16px {
  padding: -1rem !important;
}

.ps--16px {
  padding-left: -1rem !important;
}

.pe--16px {
  padding-right: -1rem !important;
}

.pt--16px {
  padding-top: -1rem !important;
}

.pb--16px {
  padding-bottom: -1rem !important;
}

.py--16px {
  padding-top: -1rem !important;
}

.py--16px {
  padding-bottom: -1rem !important;
}

.px--16px {
  padding-left: -1rem !important;
}

.px--16px {
  padding-right: -1rem !important;
}

.m--15px {
  margin: -0.9375rem !important;
}

.ms--15px {
  margin-left: -0.9375rem !important;
}

.me--15px {
  margin-right: -0.9375rem !important;
}

.mt--15px {
  margin-top: -0.9375rem !important;
}

.mb--15px {
  margin-bottom: -0.9375rem !important;
}

.my--15px {
  margin-top: -0.9375rem !important;
}

.my--15px {
  margin-bottom: -0.9375rem !important;
}

.mx--15px {
  margin-left: -0.9375rem !important;
}

.mx--15px {
  margin-right: -0.9375rem !important;
}

.p--15px {
  padding: -0.9375rem !important;
}

.ps--15px {
  padding-left: -0.9375rem !important;
}

.pe--15px {
  padding-right: -0.9375rem !important;
}

.pt--15px {
  padding-top: -0.9375rem !important;
}

.pb--15px {
  padding-bottom: -0.9375rem !important;
}

.py--15px {
  padding-top: -0.9375rem !important;
}

.py--15px {
  padding-bottom: -0.9375rem !important;
}

.px--15px {
  padding-left: -0.9375rem !important;
}

.px--15px {
  padding-right: -0.9375rem !important;
}

.m--14px {
  margin: -0.875rem !important;
}

.ms--14px {
  margin-left: -0.875rem !important;
}

.me--14px {
  margin-right: -0.875rem !important;
}

.mt--14px {
  margin-top: -0.875rem !important;
}

.mb--14px {
  margin-bottom: -0.875rem !important;
}

.my--14px {
  margin-top: -0.875rem !important;
}

.my--14px {
  margin-bottom: -0.875rem !important;
}

.mx--14px {
  margin-left: -0.875rem !important;
}

.mx--14px {
  margin-right: -0.875rem !important;
}

.p--14px {
  padding: -0.875rem !important;
}

.ps--14px {
  padding-left: -0.875rem !important;
}

.pe--14px {
  padding-right: -0.875rem !important;
}

.pt--14px {
  padding-top: -0.875rem !important;
}

.pb--14px {
  padding-bottom: -0.875rem !important;
}

.py--14px {
  padding-top: -0.875rem !important;
}

.py--14px {
  padding-bottom: -0.875rem !important;
}

.px--14px {
  padding-left: -0.875rem !important;
}

.px--14px {
  padding-right: -0.875rem !important;
}

.m--13px {
  margin: -0.8125rem !important;
}

.ms--13px {
  margin-left: -0.8125rem !important;
}

.me--13px {
  margin-right: -0.8125rem !important;
}

.mt--13px {
  margin-top: -0.8125rem !important;
}

.mb--13px {
  margin-bottom: -0.8125rem !important;
}

.my--13px {
  margin-top: -0.8125rem !important;
}

.my--13px {
  margin-bottom: -0.8125rem !important;
}

.mx--13px {
  margin-left: -0.8125rem !important;
}

.mx--13px {
  margin-right: -0.8125rem !important;
}

.p--13px {
  padding: -0.8125rem !important;
}

.ps--13px {
  padding-left: -0.8125rem !important;
}

.pe--13px {
  padding-right: -0.8125rem !important;
}

.pt--13px {
  padding-top: -0.8125rem !important;
}

.pb--13px {
  padding-bottom: -0.8125rem !important;
}

.py--13px {
  padding-top: -0.8125rem !important;
}

.py--13px {
  padding-bottom: -0.8125rem !important;
}

.px--13px {
  padding-left: -0.8125rem !important;
}

.px--13px {
  padding-right: -0.8125rem !important;
}

.m--12px {
  margin: -0.75rem !important;
}

.ms--12px {
  margin-left: -0.75rem !important;
}

.me--12px {
  margin-right: -0.75rem !important;
}

.mt--12px {
  margin-top: -0.75rem !important;
}

.mb--12px {
  margin-bottom: -0.75rem !important;
}

.my--12px {
  margin-top: -0.75rem !important;
}

.my--12px {
  margin-bottom: -0.75rem !important;
}

.mx--12px {
  margin-left: -0.75rem !important;
}

.mx--12px {
  margin-right: -0.75rem !important;
}

.p--12px {
  padding: -0.75rem !important;
}

.ps--12px {
  padding-left: -0.75rem !important;
}

.pe--12px {
  padding-right: -0.75rem !important;
}

.pt--12px {
  padding-top: -0.75rem !important;
}

.pb--12px {
  padding-bottom: -0.75rem !important;
}

.py--12px {
  padding-top: -0.75rem !important;
}

.py--12px {
  padding-bottom: -0.75rem !important;
}

.px--12px {
  padding-left: -0.75rem !important;
}

.px--12px {
  padding-right: -0.75rem !important;
}

.m--11px {
  margin: -0.6875rem !important;
}

.ms--11px {
  margin-left: -0.6875rem !important;
}

.me--11px {
  margin-right: -0.6875rem !important;
}

.mt--11px {
  margin-top: -0.6875rem !important;
}

.mb--11px {
  margin-bottom: -0.6875rem !important;
}

.my--11px {
  margin-top: -0.6875rem !important;
}

.my--11px {
  margin-bottom: -0.6875rem !important;
}

.mx--11px {
  margin-left: -0.6875rem !important;
}

.mx--11px {
  margin-right: -0.6875rem !important;
}

.p--11px {
  padding: -0.6875rem !important;
}

.ps--11px {
  padding-left: -0.6875rem !important;
}

.pe--11px {
  padding-right: -0.6875rem !important;
}

.pt--11px {
  padding-top: -0.6875rem !important;
}

.pb--11px {
  padding-bottom: -0.6875rem !important;
}

.py--11px {
  padding-top: -0.6875rem !important;
}

.py--11px {
  padding-bottom: -0.6875rem !important;
}

.px--11px {
  padding-left: -0.6875rem !important;
}

.px--11px {
  padding-right: -0.6875rem !important;
}

.m--10px {
  margin: -0.625rem !important;
}

.ms--10px {
  margin-left: -0.625rem !important;
}

.me--10px {
  margin-right: -0.625rem !important;
}

.mt--10px {
  margin-top: -0.625rem !important;
}

.mb--10px {
  margin-bottom: -0.625rem !important;
}

.my--10px {
  margin-top: -0.625rem !important;
}

.my--10px {
  margin-bottom: -0.625rem !important;
}

.mx--10px {
  margin-left: -0.625rem !important;
}

.mx--10px {
  margin-right: -0.625rem !important;
}

.p--10px {
  padding: -0.625rem !important;
}

.ps--10px {
  padding-left: -0.625rem !important;
}

.pe--10px {
  padding-right: -0.625rem !important;
}

.pt--10px {
  padding-top: -0.625rem !important;
}

.pb--10px {
  padding-bottom: -0.625rem !important;
}

.py--10px {
  padding-top: -0.625rem !important;
}

.py--10px {
  padding-bottom: -0.625rem !important;
}

.px--10px {
  padding-left: -0.625rem !important;
}

.px--10px {
  padding-right: -0.625rem !important;
}

.m--9px {
  margin: -0.5625rem !important;
}

.ms--9px {
  margin-left: -0.5625rem !important;
}

.me--9px {
  margin-right: -0.5625rem !important;
}

.mt--9px {
  margin-top: -0.5625rem !important;
}

.mb--9px {
  margin-bottom: -0.5625rem !important;
}

.my--9px {
  margin-top: -0.5625rem !important;
}

.my--9px {
  margin-bottom: -0.5625rem !important;
}

.mx--9px {
  margin-left: -0.5625rem !important;
}

.mx--9px {
  margin-right: -0.5625rem !important;
}

.p--9px {
  padding: -0.5625rem !important;
}

.ps--9px {
  padding-left: -0.5625rem !important;
}

.pe--9px {
  padding-right: -0.5625rem !important;
}

.pt--9px {
  padding-top: -0.5625rem !important;
}

.pb--9px {
  padding-bottom: -0.5625rem !important;
}

.py--9px {
  padding-top: -0.5625rem !important;
}

.py--9px {
  padding-bottom: -0.5625rem !important;
}

.px--9px {
  padding-left: -0.5625rem !important;
}

.px--9px {
  padding-right: -0.5625rem !important;
}

.m--8px {
  margin: -0.5rem !important;
}

.ms--8px {
  margin-left: -0.5rem !important;
}

.me--8px {
  margin-right: -0.5rem !important;
}

.mt--8px {
  margin-top: -0.5rem !important;
}

.mb--8px {
  margin-bottom: -0.5rem !important;
}

.my--8px {
  margin-top: -0.5rem !important;
}

.my--8px {
  margin-bottom: -0.5rem !important;
}

.mx--8px {
  margin-left: -0.5rem !important;
}

.mx--8px {
  margin-right: -0.5rem !important;
}

.p--8px {
  padding: -0.5rem !important;
}

.ps--8px {
  padding-left: -0.5rem !important;
}

.pe--8px {
  padding-right: -0.5rem !important;
}

.pt--8px {
  padding-top: -0.5rem !important;
}

.pb--8px {
  padding-bottom: -0.5rem !important;
}

.py--8px {
  padding-top: -0.5rem !important;
}

.py--8px {
  padding-bottom: -0.5rem !important;
}

.px--8px {
  padding-left: -0.5rem !important;
}

.px--8px {
  padding-right: -0.5rem !important;
}

.m--7px {
  margin: -0.4375rem !important;
}

.ms--7px {
  margin-left: -0.4375rem !important;
}

.me--7px {
  margin-right: -0.4375rem !important;
}

.mt--7px {
  margin-top: -0.4375rem !important;
}

.mb--7px {
  margin-bottom: -0.4375rem !important;
}

.my--7px {
  margin-top: -0.4375rem !important;
}

.my--7px {
  margin-bottom: -0.4375rem !important;
}

.mx--7px {
  margin-left: -0.4375rem !important;
}

.mx--7px {
  margin-right: -0.4375rem !important;
}

.p--7px {
  padding: -0.4375rem !important;
}

.ps--7px {
  padding-left: -0.4375rem !important;
}

.pe--7px {
  padding-right: -0.4375rem !important;
}

.pt--7px {
  padding-top: -0.4375rem !important;
}

.pb--7px {
  padding-bottom: -0.4375rem !important;
}

.py--7px {
  padding-top: -0.4375rem !important;
}

.py--7px {
  padding-bottom: -0.4375rem !important;
}

.px--7px {
  padding-left: -0.4375rem !important;
}

.px--7px {
  padding-right: -0.4375rem !important;
}

.m--6px {
  margin: -0.375rem !important;
}

.ms--6px {
  margin-left: -0.375rem !important;
}

.me--6px {
  margin-right: -0.375rem !important;
}

.mt--6px {
  margin-top: -0.375rem !important;
}

.mb--6px {
  margin-bottom: -0.375rem !important;
}

.my--6px {
  margin-top: -0.375rem !important;
}

.my--6px {
  margin-bottom: -0.375rem !important;
}

.mx--6px {
  margin-left: -0.375rem !important;
}

.mx--6px {
  margin-right: -0.375rem !important;
}

.p--6px {
  padding: -0.375rem !important;
}

.ps--6px {
  padding-left: -0.375rem !important;
}

.pe--6px {
  padding-right: -0.375rem !important;
}

.pt--6px {
  padding-top: -0.375rem !important;
}

.pb--6px {
  padding-bottom: -0.375rem !important;
}

.py--6px {
  padding-top: -0.375rem !important;
}

.py--6px {
  padding-bottom: -0.375rem !important;
}

.px--6px {
  padding-left: -0.375rem !important;
}

.px--6px {
  padding-right: -0.375rem !important;
}

.m--5px {
  margin: -0.3125rem !important;
}

.ms--5px {
  margin-left: -0.3125rem !important;
}

.me--5px {
  margin-right: -0.3125rem !important;
}

.mt--5px {
  margin-top: -0.3125rem !important;
}

.mb--5px {
  margin-bottom: -0.3125rem !important;
}

.my--5px {
  margin-top: -0.3125rem !important;
}

.my--5px {
  margin-bottom: -0.3125rem !important;
}

.mx--5px {
  margin-left: -0.3125rem !important;
}

.mx--5px {
  margin-right: -0.3125rem !important;
}

.p--5px {
  padding: -0.3125rem !important;
}

.ps--5px {
  padding-left: -0.3125rem !important;
}

.pe--5px {
  padding-right: -0.3125rem !important;
}

.pt--5px {
  padding-top: -0.3125rem !important;
}

.pb--5px {
  padding-bottom: -0.3125rem !important;
}

.py--5px {
  padding-top: -0.3125rem !important;
}

.py--5px {
  padding-bottom: -0.3125rem !important;
}

.px--5px {
  padding-left: -0.3125rem !important;
}

.px--5px {
  padding-right: -0.3125rem !important;
}

.m--4px {
  margin: -0.25rem !important;
}

.ms--4px {
  margin-left: -0.25rem !important;
}

.me--4px {
  margin-right: -0.25rem !important;
}

.mt--4px {
  margin-top: -0.25rem !important;
}

.mb--4px {
  margin-bottom: -0.25rem !important;
}

.my--4px {
  margin-top: -0.25rem !important;
}

.my--4px {
  margin-bottom: -0.25rem !important;
}

.mx--4px {
  margin-left: -0.25rem !important;
}

.mx--4px {
  margin-right: -0.25rem !important;
}

.p--4px {
  padding: -0.25rem !important;
}

.ps--4px {
  padding-left: -0.25rem !important;
}

.pe--4px {
  padding-right: -0.25rem !important;
}

.pt--4px {
  padding-top: -0.25rem !important;
}

.pb--4px {
  padding-bottom: -0.25rem !important;
}

.py--4px {
  padding-top: -0.25rem !important;
}

.py--4px {
  padding-bottom: -0.25rem !important;
}

.px--4px {
  padding-left: -0.25rem !important;
}

.px--4px {
  padding-right: -0.25rem !important;
}

.m--3px {
  margin: -0.1875rem !important;
}

.ms--3px {
  margin-left: -0.1875rem !important;
}

.me--3px {
  margin-right: -0.1875rem !important;
}

.mt--3px {
  margin-top: -0.1875rem !important;
}

.mb--3px {
  margin-bottom: -0.1875rem !important;
}

.my--3px {
  margin-top: -0.1875rem !important;
}

.my--3px {
  margin-bottom: -0.1875rem !important;
}

.mx--3px {
  margin-left: -0.1875rem !important;
}

.mx--3px {
  margin-right: -0.1875rem !important;
}

.p--3px {
  padding: -0.1875rem !important;
}

.ps--3px {
  padding-left: -0.1875rem !important;
}

.pe--3px {
  padding-right: -0.1875rem !important;
}

.pt--3px {
  padding-top: -0.1875rem !important;
}

.pb--3px {
  padding-bottom: -0.1875rem !important;
}

.py--3px {
  padding-top: -0.1875rem !important;
}

.py--3px {
  padding-bottom: -0.1875rem !important;
}

.px--3px {
  padding-left: -0.1875rem !important;
}

.px--3px {
  padding-right: -0.1875rem !important;
}

.m--2px {
  margin: -0.125rem !important;
}

.ms--2px {
  margin-left: -0.125rem !important;
}

.me--2px {
  margin-right: -0.125rem !important;
}

.mt--2px {
  margin-top: -0.125rem !important;
}

.mb--2px {
  margin-bottom: -0.125rem !important;
}

.my--2px {
  margin-top: -0.125rem !important;
}

.my--2px {
  margin-bottom: -0.125rem !important;
}

.mx--2px {
  margin-left: -0.125rem !important;
}

.mx--2px {
  margin-right: -0.125rem !important;
}

.p--2px {
  padding: -0.125rem !important;
}

.ps--2px {
  padding-left: -0.125rem !important;
}

.pe--2px {
  padding-right: -0.125rem !important;
}

.pt--2px {
  padding-top: -0.125rem !important;
}

.pb--2px {
  padding-bottom: -0.125rem !important;
}

.py--2px {
  padding-top: -0.125rem !important;
}

.py--2px {
  padding-bottom: -0.125rem !important;
}

.px--2px {
  padding-left: -0.125rem !important;
}

.px--2px {
  padding-right: -0.125rem !important;
}

.m--1px {
  margin: -0.0625rem !important;
}

.ms--1px {
  margin-left: -0.0625rem !important;
}

.me--1px {
  margin-right: -0.0625rem !important;
}

.mt--1px {
  margin-top: -0.0625rem !important;
}

.mb--1px {
  margin-bottom: -0.0625rem !important;
}

.my--1px {
  margin-top: -0.0625rem !important;
}

.my--1px {
  margin-bottom: -0.0625rem !important;
}

.mx--1px {
  margin-left: -0.0625rem !important;
}

.mx--1px {
  margin-right: -0.0625rem !important;
}

.p--1px {
  padding: -0.0625rem !important;
}

.ps--1px {
  padding-left: -0.0625rem !important;
}

.pe--1px {
  padding-right: -0.0625rem !important;
}

.pt--1px {
  padding-top: -0.0625rem !important;
}

.pb--1px {
  padding-bottom: -0.0625rem !important;
}

.py--1px {
  padding-top: -0.0625rem !important;
}

.py--1px {
  padding-bottom: -0.0625rem !important;
}

.px--1px {
  padding-left: -0.0625rem !important;
}

.px--1px {
  padding-right: -0.0625rem !important;
}

.m-0px {
  margin: 0rem !important;
}

.ms-0px {
  margin-left: 0rem !important;
}

.me-0px {
  margin-right: 0rem !important;
}

.mt-0px {
  margin-top: 0rem !important;
}

.mb-0px {
  margin-bottom: 0rem !important;
}

.my-0px {
  margin-top: 0rem !important;
}

.my-0px {
  margin-bottom: 0rem !important;
}

.mx-0px {
  margin-left: 0rem !important;
}

.mx-0px {
  margin-right: 0rem !important;
}

.p-0px {
  padding: 0rem !important;
}

.ps-0px {
  padding-left: 0rem !important;
}

.pe-0px {
  padding-right: 0rem !important;
}

.pt-0px {
  padding-top: 0rem !important;
}

.pb-0px {
  padding-bottom: 0rem !important;
}

.py-0px {
  padding-top: 0rem !important;
}

.py-0px {
  padding-bottom: 0rem !important;
}

.px-0px {
  padding-left: 0rem !important;
}

.px-0px {
  padding-right: 0rem !important;
}

.m-1px {
  margin: 0.0625rem !important;
}

.ms-1px {
  margin-left: 0.0625rem !important;
}

.me-1px {
  margin-right: 0.0625rem !important;
}

.mt-1px {
  margin-top: 0.0625rem !important;
}

.mb-1px {
  margin-bottom: 0.0625rem !important;
}

.my-1px {
  margin-top: 0.0625rem !important;
}

.my-1px {
  margin-bottom: 0.0625rem !important;
}

.mx-1px {
  margin-left: 0.0625rem !important;
}

.mx-1px {
  margin-right: 0.0625rem !important;
}

.p-1px {
  padding: 0.0625rem !important;
}

.ps-1px {
  padding-left: 0.0625rem !important;
}

.pe-1px {
  padding-right: 0.0625rem !important;
}

.pt-1px {
  padding-top: 0.0625rem !important;
}

.pb-1px {
  padding-bottom: 0.0625rem !important;
}

.py-1px {
  padding-top: 0.0625rem !important;
}

.py-1px {
  padding-bottom: 0.0625rem !important;
}

.px-1px {
  padding-left: 0.0625rem !important;
}

.px-1px {
  padding-right: 0.0625rem !important;
}

.m-2px {
  margin: 0.125rem !important;
}

.ms-2px {
  margin-left: 0.125rem !important;
}

.me-2px {
  margin-right: 0.125rem !important;
}

.mt-2px {
  margin-top: 0.125rem !important;
}

.mb-2px {
  margin-bottom: 0.125rem !important;
}

.my-2px {
  margin-top: 0.125rem !important;
}

.my-2px {
  margin-bottom: 0.125rem !important;
}

.mx-2px {
  margin-left: 0.125rem !important;
}

.mx-2px {
  margin-right: 0.125rem !important;
}

.p-2px {
  padding: 0.125rem !important;
}

.ps-2px {
  padding-left: 0.125rem !important;
}

.pe-2px {
  padding-right: 0.125rem !important;
}

.pt-2px {
  padding-top: 0.125rem !important;
}

.pb-2px {
  padding-bottom: 0.125rem !important;
}

.py-2px {
  padding-top: 0.125rem !important;
}

.py-2px {
  padding-bottom: 0.125rem !important;
}

.px-2px {
  padding-left: 0.125rem !important;
}

.px-2px {
  padding-right: 0.125rem !important;
}

.m-3px {
  margin: 0.1875rem !important;
}

.ms-3px {
  margin-left: 0.1875rem !important;
}

.me-3px {
  margin-right: 0.1875rem !important;
}

.mt-3px {
  margin-top: 0.1875rem !important;
}

.mb-3px {
  margin-bottom: 0.1875rem !important;
}

.my-3px {
  margin-top: 0.1875rem !important;
}

.my-3px {
  margin-bottom: 0.1875rem !important;
}

.mx-3px {
  margin-left: 0.1875rem !important;
}

.mx-3px {
  margin-right: 0.1875rem !important;
}

.p-3px {
  padding: 0.1875rem !important;
}

.ps-3px {
  padding-left: 0.1875rem !important;
}

.pe-3px {
  padding-right: 0.1875rem !important;
}

.pt-3px {
  padding-top: 0.1875rem !important;
}

.pb-3px {
  padding-bottom: 0.1875rem !important;
}

.py-3px {
  padding-top: 0.1875rem !important;
}

.py-3px {
  padding-bottom: 0.1875rem !important;
}

.px-3px {
  padding-left: 0.1875rem !important;
}

.px-3px {
  padding-right: 0.1875rem !important;
}

.m-4px {
  margin: 0.25rem !important;
}

.ms-4px {
  margin-left: 0.25rem !important;
}

.me-4px {
  margin-right: 0.25rem !important;
}

.mt-4px {
  margin-top: 0.25rem !important;
}

.mb-4px {
  margin-bottom: 0.25rem !important;
}

.my-4px {
  margin-top: 0.25rem !important;
}

.my-4px {
  margin-bottom: 0.25rem !important;
}

.mx-4px {
  margin-left: 0.25rem !important;
}

.mx-4px {
  margin-right: 0.25rem !important;
}

.p-4px {
  padding: 0.25rem !important;
}

.ps-4px {
  padding-left: 0.25rem !important;
}

.pe-4px {
  padding-right: 0.25rem !important;
}

.pt-4px {
  padding-top: 0.25rem !important;
}

.pb-4px {
  padding-bottom: 0.25rem !important;
}

.py-4px {
  padding-top: 0.25rem !important;
}

.py-4px {
  padding-bottom: 0.25rem !important;
}

.px-4px {
  padding-left: 0.25rem !important;
}

.px-4px {
  padding-right: 0.25rem !important;
}

.m-5px {
  margin: 0.3125rem !important;
}

.ms-5px {
  margin-left: 0.3125rem !important;
}

.me-5px {
  margin-right: 0.3125rem !important;
}

.mt-5px {
  margin-top: 0.3125rem !important;
}

.mb-5px {
  margin-bottom: 0.3125rem !important;
}

.my-5px {
  margin-top: 0.3125rem !important;
}

.my-5px {
  margin-bottom: 0.3125rem !important;
}

.mx-5px {
  margin-left: 0.3125rem !important;
}

.mx-5px {
  margin-right: 0.3125rem !important;
}

.p-5px {
  padding: 0.3125rem !important;
}

.ps-5px {
  padding-left: 0.3125rem !important;
}

.pe-5px {
  padding-right: 0.3125rem !important;
}

.pt-5px {
  padding-top: 0.3125rem !important;
}

.pb-5px {
  padding-bottom: 0.3125rem !important;
}

.py-5px {
  padding-top: 0.3125rem !important;
}

.py-5px {
  padding-bottom: 0.3125rem !important;
}

.px-5px {
  padding-left: 0.3125rem !important;
}

.px-5px {
  padding-right: 0.3125rem !important;
}

.m-6px {
  margin: 0.375rem !important;
}

.ms-6px {
  margin-left: 0.375rem !important;
}

.me-6px {
  margin-right: 0.375rem !important;
}

.mt-6px {
  margin-top: 0.375rem !important;
}

.mb-6px {
  margin-bottom: 0.375rem !important;
}

.my-6px {
  margin-top: 0.375rem !important;
}

.my-6px {
  margin-bottom: 0.375rem !important;
}

.mx-6px {
  margin-left: 0.375rem !important;
}

.mx-6px {
  margin-right: 0.375rem !important;
}

.p-6px {
  padding: 0.375rem !important;
}

.ps-6px {
  padding-left: 0.375rem !important;
}

.pe-6px {
  padding-right: 0.375rem !important;
}

.pt-6px {
  padding-top: 0.375rem !important;
}

.pb-6px {
  padding-bottom: 0.375rem !important;
}

.py-6px {
  padding-top: 0.375rem !important;
}

.py-6px {
  padding-bottom: 0.375rem !important;
}

.px-6px {
  padding-left: 0.375rem !important;
}

.px-6px {
  padding-right: 0.375rem !important;
}

.m-7px {
  margin: 0.4375rem !important;
}

.ms-7px {
  margin-left: 0.4375rem !important;
}

.me-7px {
  margin-right: 0.4375rem !important;
}

.mt-7px {
  margin-top: 0.4375rem !important;
}

.mb-7px {
  margin-bottom: 0.4375rem !important;
}

.my-7px {
  margin-top: 0.4375rem !important;
}

.my-7px {
  margin-bottom: 0.4375rem !important;
}

.mx-7px {
  margin-left: 0.4375rem !important;
}

.mx-7px {
  margin-right: 0.4375rem !important;
}

.p-7px {
  padding: 0.4375rem !important;
}

.ps-7px {
  padding-left: 0.4375rem !important;
}

.pe-7px {
  padding-right: 0.4375rem !important;
}

.pt-7px {
  padding-top: 0.4375rem !important;
}

.pb-7px {
  padding-bottom: 0.4375rem !important;
}

.py-7px {
  padding-top: 0.4375rem !important;
}

.py-7px {
  padding-bottom: 0.4375rem !important;
}

.px-7px {
  padding-left: 0.4375rem !important;
}

.px-7px {
  padding-right: 0.4375rem !important;
}

.m-8px {
  margin: 0.5rem !important;
}

.ms-8px {
  margin-left: 0.5rem !important;
}

.me-8px {
  margin-right: 0.5rem !important;
}

.mt-8px {
  margin-top: 0.5rem !important;
}

.mb-8px {
  margin-bottom: 0.5rem !important;
}

.my-8px {
  margin-top: 0.5rem !important;
}

.my-8px {
  margin-bottom: 0.5rem !important;
}

.mx-8px {
  margin-left: 0.5rem !important;
}

.mx-8px {
  margin-right: 0.5rem !important;
}

.p-8px {
  padding: 0.5rem !important;
}

.ps-8px {
  padding-left: 0.5rem !important;
}

.pe-8px {
  padding-right: 0.5rem !important;
}

.pt-8px {
  padding-top: 0.5rem !important;
}

.pb-8px {
  padding-bottom: 0.5rem !important;
}

.py-8px {
  padding-top: 0.5rem !important;
}

.py-8px {
  padding-bottom: 0.5rem !important;
}

.px-8px {
  padding-left: 0.5rem !important;
}

.px-8px {
  padding-right: 0.5rem !important;
}

.m-9px {
  margin: 0.5625rem !important;
}

.ms-9px {
  margin-left: 0.5625rem !important;
}

.me-9px {
  margin-right: 0.5625rem !important;
}

.mt-9px {
  margin-top: 0.5625rem !important;
}

.mb-9px {
  margin-bottom: 0.5625rem !important;
}

.my-9px {
  margin-top: 0.5625rem !important;
}

.my-9px {
  margin-bottom: 0.5625rem !important;
}

.mx-9px {
  margin-left: 0.5625rem !important;
}

.mx-9px {
  margin-right: 0.5625rem !important;
}

.p-9px {
  padding: 0.5625rem !important;
}

.ps-9px {
  padding-left: 0.5625rem !important;
}

.pe-9px {
  padding-right: 0.5625rem !important;
}

.pt-9px {
  padding-top: 0.5625rem !important;
}

.pb-9px {
  padding-bottom: 0.5625rem !important;
}

.py-9px {
  padding-top: 0.5625rem !important;
}

.py-9px {
  padding-bottom: 0.5625rem !important;
}

.px-9px {
  padding-left: 0.5625rem !important;
}

.px-9px {
  padding-right: 0.5625rem !important;
}

.m-10px {
  margin: 0.625rem !important;
}

.ms-10px {
  margin-left: 0.625rem !important;
}

.me-10px {
  margin-right: 0.625rem !important;
}

.mt-10px {
  margin-top: 0.625rem !important;
}

.mb-10px {
  margin-bottom: 0.625rem !important;
}

.my-10px {
  margin-top: 0.625rem !important;
}

.my-10px {
  margin-bottom: 0.625rem !important;
}

.mx-10px {
  margin-left: 0.625rem !important;
}

.mx-10px {
  margin-right: 0.625rem !important;
}

.p-10px {
  padding: 0.625rem !important;
}

.ps-10px {
  padding-left: 0.625rem !important;
}

.pe-10px {
  padding-right: 0.625rem !important;
}

.pt-10px {
  padding-top: 0.625rem !important;
}

.pb-10px {
  padding-bottom: 0.625rem !important;
}

.py-10px {
  padding-top: 0.625rem !important;
}

.py-10px {
  padding-bottom: 0.625rem !important;
}

.px-10px {
  padding-left: 0.625rem !important;
}

.px-10px {
  padding-right: 0.625rem !important;
}

.m-11px {
  margin: 0.6875rem !important;
}

.ms-11px {
  margin-left: 0.6875rem !important;
}

.me-11px {
  margin-right: 0.6875rem !important;
}

.mt-11px {
  margin-top: 0.6875rem !important;
}

.mb-11px {
  margin-bottom: 0.6875rem !important;
}

.my-11px {
  margin-top: 0.6875rem !important;
}

.my-11px {
  margin-bottom: 0.6875rem !important;
}

.mx-11px {
  margin-left: 0.6875rem !important;
}

.mx-11px {
  margin-right: 0.6875rem !important;
}

.p-11px {
  padding: 0.6875rem !important;
}

.ps-11px {
  padding-left: 0.6875rem !important;
}

.pe-11px {
  padding-right: 0.6875rem !important;
}

.pt-11px {
  padding-top: 0.6875rem !important;
}

.pb-11px {
  padding-bottom: 0.6875rem !important;
}

.py-11px {
  padding-top: 0.6875rem !important;
}

.py-11px {
  padding-bottom: 0.6875rem !important;
}

.px-11px {
  padding-left: 0.6875rem !important;
}

.px-11px {
  padding-right: 0.6875rem !important;
}

.m-12px {
  margin: 0.75rem !important;
}

.ms-12px {
  margin-left: 0.75rem !important;
}

.me-12px {
  margin-right: 0.75rem !important;
}

.mt-12px {
  margin-top: 0.75rem !important;
}

.mb-12px {
  margin-bottom: 0.75rem !important;
}

.my-12px {
  margin-top: 0.75rem !important;
}

.my-12px {
  margin-bottom: 0.75rem !important;
}

.mx-12px {
  margin-left: 0.75rem !important;
}

.mx-12px {
  margin-right: 0.75rem !important;
}

.p-12px {
  padding: 0.75rem !important;
}

.ps-12px {
  padding-left: 0.75rem !important;
}

.pe-12px {
  padding-right: 0.75rem !important;
}

.pt-12px {
  padding-top: 0.75rem !important;
}

.pb-12px {
  padding-bottom: 0.75rem !important;
}

.py-12px {
  padding-top: 0.75rem !important;
}

.py-12px {
  padding-bottom: 0.75rem !important;
}

.px-12px {
  padding-left: 0.75rem !important;
}

.px-12px {
  padding-right: 0.75rem !important;
}

.m-13px {
  margin: 0.8125rem !important;
}

.ms-13px {
  margin-left: 0.8125rem !important;
}

.me-13px {
  margin-right: 0.8125rem !important;
}

.mt-13px {
  margin-top: 0.8125rem !important;
}

.mb-13px {
  margin-bottom: 0.8125rem !important;
}

.my-13px {
  margin-top: 0.8125rem !important;
}

.my-13px {
  margin-bottom: 0.8125rem !important;
}

.mx-13px {
  margin-left: 0.8125rem !important;
}

.mx-13px {
  margin-right: 0.8125rem !important;
}

.p-13px {
  padding: 0.8125rem !important;
}

.ps-13px {
  padding-left: 0.8125rem !important;
}

.pe-13px {
  padding-right: 0.8125rem !important;
}

.pt-13px {
  padding-top: 0.8125rem !important;
}

.pb-13px {
  padding-bottom: 0.8125rem !important;
}

.py-13px {
  padding-top: 0.8125rem !important;
}

.py-13px {
  padding-bottom: 0.8125rem !important;
}

.px-13px {
  padding-left: 0.8125rem !important;
}

.px-13px {
  padding-right: 0.8125rem !important;
}

.m-14px {
  margin: 0.875rem !important;
}

.ms-14px {
  margin-left: 0.875rem !important;
}

.me-14px {
  margin-right: 0.875rem !important;
}

.mt-14px {
  margin-top: 0.875rem !important;
}

.mb-14px {
  margin-bottom: 0.875rem !important;
}

.my-14px {
  margin-top: 0.875rem !important;
}

.my-14px {
  margin-bottom: 0.875rem !important;
}

.mx-14px {
  margin-left: 0.875rem !important;
}

.mx-14px {
  margin-right: 0.875rem !important;
}

.p-14px {
  padding: 0.875rem !important;
}

.ps-14px {
  padding-left: 0.875rem !important;
}

.pe-14px {
  padding-right: 0.875rem !important;
}

.pt-14px {
  padding-top: 0.875rem !important;
}

.pb-14px {
  padding-bottom: 0.875rem !important;
}

.py-14px {
  padding-top: 0.875rem !important;
}

.py-14px {
  padding-bottom: 0.875rem !important;
}

.px-14px {
  padding-left: 0.875rem !important;
}

.px-14px {
  padding-right: 0.875rem !important;
}

.m-15px {
  margin: 0.9375rem !important;
}

.ms-15px {
  margin-left: 0.9375rem !important;
}

.me-15px {
  margin-right: 0.9375rem !important;
}

.mt-15px {
  margin-top: 0.9375rem !important;
}

.mb-15px {
  margin-bottom: 0.9375rem !important;
}

.my-15px {
  margin-top: 0.9375rem !important;
}

.my-15px {
  margin-bottom: 0.9375rem !important;
}

.mx-15px {
  margin-left: 0.9375rem !important;
}

.mx-15px {
  margin-right: 0.9375rem !important;
}

.p-15px {
  padding: 0.9375rem !important;
}

.ps-15px {
  padding-left: 0.9375rem !important;
}

.pe-15px {
  padding-right: 0.9375rem !important;
}

.pt-15px {
  padding-top: 0.9375rem !important;
}

.pb-15px {
  padding-bottom: 0.9375rem !important;
}

.py-15px {
  padding-top: 0.9375rem !important;
}

.py-15px {
  padding-bottom: 0.9375rem !important;
}

.px-15px {
  padding-left: 0.9375rem !important;
}

.px-15px {
  padding-right: 0.9375rem !important;
}

.m-16px {
  margin: 1rem !important;
}

.ms-16px {
  margin-left: 1rem !important;
}

.me-16px {
  margin-right: 1rem !important;
}

.mt-16px {
  margin-top: 1rem !important;
}

.mb-16px {
  margin-bottom: 1rem !important;
}

.my-16px {
  margin-top: 1rem !important;
}

.my-16px {
  margin-bottom: 1rem !important;
}

.mx-16px {
  margin-left: 1rem !important;
}

.mx-16px {
  margin-right: 1rem !important;
}

.p-16px {
  padding: 1rem !important;
}

.ps-16px {
  padding-left: 1rem !important;
}

.pe-16px {
  padding-right: 1rem !important;
}

.pt-16px {
  padding-top: 1rem !important;
}

.pb-16px {
  padding-bottom: 1rem !important;
}

.py-16px {
  padding-top: 1rem !important;
}

.py-16px {
  padding-bottom: 1rem !important;
}

.px-16px {
  padding-left: 1rem !important;
}

.px-16px {
  padding-right: 1rem !important;
}

.m-17px {
  margin: 1.0625rem !important;
}

.ms-17px {
  margin-left: 1.0625rem !important;
}

.me-17px {
  margin-right: 1.0625rem !important;
}

.mt-17px {
  margin-top: 1.0625rem !important;
}

.mb-17px {
  margin-bottom: 1.0625rem !important;
}

.my-17px {
  margin-top: 1.0625rem !important;
}

.my-17px {
  margin-bottom: 1.0625rem !important;
}

.mx-17px {
  margin-left: 1.0625rem !important;
}

.mx-17px {
  margin-right: 1.0625rem !important;
}

.p-17px {
  padding: 1.0625rem !important;
}

.ps-17px {
  padding-left: 1.0625rem !important;
}

.pe-17px {
  padding-right: 1.0625rem !important;
}

.pt-17px {
  padding-top: 1.0625rem !important;
}

.pb-17px {
  padding-bottom: 1.0625rem !important;
}

.py-17px {
  padding-top: 1.0625rem !important;
}

.py-17px {
  padding-bottom: 1.0625rem !important;
}

.px-17px {
  padding-left: 1.0625rem !important;
}

.px-17px {
  padding-right: 1.0625rem !important;
}

.m-18px {
  margin: 1.125rem !important;
}

.ms-18px {
  margin-left: 1.125rem !important;
}

.me-18px {
  margin-right: 1.125rem !important;
}

.mt-18px {
  margin-top: 1.125rem !important;
}

.mb-18px {
  margin-bottom: 1.125rem !important;
}

.my-18px {
  margin-top: 1.125rem !important;
}

.my-18px {
  margin-bottom: 1.125rem !important;
}

.mx-18px {
  margin-left: 1.125rem !important;
}

.mx-18px {
  margin-right: 1.125rem !important;
}

.p-18px {
  padding: 1.125rem !important;
}

.ps-18px {
  padding-left: 1.125rem !important;
}

.pe-18px {
  padding-right: 1.125rem !important;
}

.pt-18px {
  padding-top: 1.125rem !important;
}

.pb-18px {
  padding-bottom: 1.125rem !important;
}

.py-18px {
  padding-top: 1.125rem !important;
}

.py-18px {
  padding-bottom: 1.125rem !important;
}

.px-18px {
  padding-left: 1.125rem !important;
}

.px-18px {
  padding-right: 1.125rem !important;
}

.m-19px {
  margin: 1.1875rem !important;
}

.ms-19px {
  margin-left: 1.1875rem !important;
}

.me-19px {
  margin-right: 1.1875rem !important;
}

.mt-19px {
  margin-top: 1.1875rem !important;
}

.mb-19px {
  margin-bottom: 1.1875rem !important;
}

.my-19px {
  margin-top: 1.1875rem !important;
}

.my-19px {
  margin-bottom: 1.1875rem !important;
}

.mx-19px {
  margin-left: 1.1875rem !important;
}

.mx-19px {
  margin-right: 1.1875rem !important;
}

.p-19px {
  padding: 1.1875rem !important;
}

.ps-19px {
  padding-left: 1.1875rem !important;
}

.pe-19px {
  padding-right: 1.1875rem !important;
}

.pt-19px {
  padding-top: 1.1875rem !important;
}

.pb-19px {
  padding-bottom: 1.1875rem !important;
}

.py-19px {
  padding-top: 1.1875rem !important;
}

.py-19px {
  padding-bottom: 1.1875rem !important;
}

.px-19px {
  padding-left: 1.1875rem !important;
}

.px-19px {
  padding-right: 1.1875rem !important;
}

.m-20px {
  margin: 1.25rem !important;
}

.ms-20px {
  margin-left: 1.25rem !important;
}

.me-20px {
  margin-right: 1.25rem !important;
}

.mt-20px {
  margin-top: 1.25rem !important;
}

.mb-20px {
  margin-bottom: 1.25rem !important;
}

.my-20px {
  margin-top: 1.25rem !important;
}

.my-20px {
  margin-bottom: 1.25rem !important;
}

.mx-20px {
  margin-left: 1.25rem !important;
}

.mx-20px {
  margin-right: 1.25rem !important;
}

.p-20px {
  padding: 1.25rem !important;
}

.ps-20px {
  padding-left: 1.25rem !important;
}

.pe-20px {
  padding-right: 1.25rem !important;
}

.pt-20px {
  padding-top: 1.25rem !important;
}

.pb-20px {
  padding-bottom: 1.25rem !important;
}

.py-20px {
  padding-top: 1.25rem !important;
}

.py-20px {
  padding-bottom: 1.25rem !important;
}

.px-20px {
  padding-left: 1.25rem !important;
}

.px-20px {
  padding-right: 1.25rem !important;
}

.m-21px {
  margin: 1.3125rem !important;
}

.ms-21px {
  margin-left: 1.3125rem !important;
}

.me-21px {
  margin-right: 1.3125rem !important;
}

.mt-21px {
  margin-top: 1.3125rem !important;
}

.mb-21px {
  margin-bottom: 1.3125rem !important;
}

.my-21px {
  margin-top: 1.3125rem !important;
}

.my-21px {
  margin-bottom: 1.3125rem !important;
}

.mx-21px {
  margin-left: 1.3125rem !important;
}

.mx-21px {
  margin-right: 1.3125rem !important;
}

.p-21px {
  padding: 1.3125rem !important;
}

.ps-21px {
  padding-left: 1.3125rem !important;
}

.pe-21px {
  padding-right: 1.3125rem !important;
}

.pt-21px {
  padding-top: 1.3125rem !important;
}

.pb-21px {
  padding-bottom: 1.3125rem !important;
}

.py-21px {
  padding-top: 1.3125rem !important;
}

.py-21px {
  padding-bottom: 1.3125rem !important;
}

.px-21px {
  padding-left: 1.3125rem !important;
}

.px-21px {
  padding-right: 1.3125rem !important;
}

.m-22px {
  margin: 1.375rem !important;
}

.ms-22px {
  margin-left: 1.375rem !important;
}

.me-22px {
  margin-right: 1.375rem !important;
}

.mt-22px {
  margin-top: 1.375rem !important;
}

.mb-22px {
  margin-bottom: 1.375rem !important;
}

.my-22px {
  margin-top: 1.375rem !important;
}

.my-22px {
  margin-bottom: 1.375rem !important;
}

.mx-22px {
  margin-left: 1.375rem !important;
}

.mx-22px {
  margin-right: 1.375rem !important;
}

.p-22px {
  padding: 1.375rem !important;
}

.ps-22px {
  padding-left: 1.375rem !important;
}

.pe-22px {
  padding-right: 1.375rem !important;
}

.pt-22px {
  padding-top: 1.375rem !important;
}

.pb-22px {
  padding-bottom: 1.375rem !important;
}

.py-22px {
  padding-top: 1.375rem !important;
}

.py-22px {
  padding-bottom: 1.375rem !important;
}

.px-22px {
  padding-left: 1.375rem !important;
}

.px-22px {
  padding-right: 1.375rem !important;
}

.m-23px {
  margin: 1.4375rem !important;
}

.ms-23px {
  margin-left: 1.4375rem !important;
}

.me-23px {
  margin-right: 1.4375rem !important;
}

.mt-23px {
  margin-top: 1.4375rem !important;
}

.mb-23px {
  margin-bottom: 1.4375rem !important;
}

.my-23px {
  margin-top: 1.4375rem !important;
}

.my-23px {
  margin-bottom: 1.4375rem !important;
}

.mx-23px {
  margin-left: 1.4375rem !important;
}

.mx-23px {
  margin-right: 1.4375rem !important;
}

.p-23px {
  padding: 1.4375rem !important;
}

.ps-23px {
  padding-left: 1.4375rem !important;
}

.pe-23px {
  padding-right: 1.4375rem !important;
}

.pt-23px {
  padding-top: 1.4375rem !important;
}

.pb-23px {
  padding-bottom: 1.4375rem !important;
}

.py-23px {
  padding-top: 1.4375rem !important;
}

.py-23px {
  padding-bottom: 1.4375rem !important;
}

.px-23px {
  padding-left: 1.4375rem !important;
}

.px-23px {
  padding-right: 1.4375rem !important;
}

.m-24px {
  margin: 1.5rem !important;
}

.ms-24px {
  margin-left: 1.5rem !important;
}

.me-24px {
  margin-right: 1.5rem !important;
}

.mt-24px {
  margin-top: 1.5rem !important;
}

.mb-24px {
  margin-bottom: 1.5rem !important;
}

.my-24px {
  margin-top: 1.5rem !important;
}

.my-24px {
  margin-bottom: 1.5rem !important;
}

.mx-24px {
  margin-left: 1.5rem !important;
}

.mx-24px {
  margin-right: 1.5rem !important;
}

.p-24px {
  padding: 1.5rem !important;
}

.ps-24px {
  padding-left: 1.5rem !important;
}

.pe-24px {
  padding-right: 1.5rem !important;
}

.pt-24px {
  padding-top: 1.5rem !important;
}

.pb-24px {
  padding-bottom: 1.5rem !important;
}

.py-24px {
  padding-top: 1.5rem !important;
}

.py-24px {
  padding-bottom: 1.5rem !important;
}

.px-24px {
  padding-left: 1.5rem !important;
}

.px-24px {
  padding-right: 1.5rem !important;
}

.m-25px {
  margin: 1.5625rem !important;
}

.ms-25px {
  margin-left: 1.5625rem !important;
}

.me-25px {
  margin-right: 1.5625rem !important;
}

.mt-25px {
  margin-top: 1.5625rem !important;
}

.mb-25px {
  margin-bottom: 1.5625rem !important;
}

.my-25px {
  margin-top: 1.5625rem !important;
}

.my-25px {
  margin-bottom: 1.5625rem !important;
}

.mx-25px {
  margin-left: 1.5625rem !important;
}

.mx-25px {
  margin-right: 1.5625rem !important;
}

.p-25px {
  padding: 1.5625rem !important;
}

.ps-25px {
  padding-left: 1.5625rem !important;
}

.pe-25px {
  padding-right: 1.5625rem !important;
}

.pt-25px {
  padding-top: 1.5625rem !important;
}

.pb-25px {
  padding-bottom: 1.5625rem !important;
}

.py-25px {
  padding-top: 1.5625rem !important;
}

.py-25px {
  padding-bottom: 1.5625rem !important;
}

.px-25px {
  padding-left: 1.5625rem !important;
}

.px-25px {
  padding-right: 1.5625rem !important;
}

.m-26px {
  margin: 1.625rem !important;
}

.ms-26px {
  margin-left: 1.625rem !important;
}

.me-26px {
  margin-right: 1.625rem !important;
}

.mt-26px {
  margin-top: 1.625rem !important;
}

.mb-26px {
  margin-bottom: 1.625rem !important;
}

.my-26px {
  margin-top: 1.625rem !important;
}

.my-26px {
  margin-bottom: 1.625rem !important;
}

.mx-26px {
  margin-left: 1.625rem !important;
}

.mx-26px {
  margin-right: 1.625rem !important;
}

.p-26px {
  padding: 1.625rem !important;
}

.ps-26px {
  padding-left: 1.625rem !important;
}

.pe-26px {
  padding-right: 1.625rem !important;
}

.pt-26px {
  padding-top: 1.625rem !important;
}

.pb-26px {
  padding-bottom: 1.625rem !important;
}

.py-26px {
  padding-top: 1.625rem !important;
}

.py-26px {
  padding-bottom: 1.625rem !important;
}

.px-26px {
  padding-left: 1.625rem !important;
}

.px-26px {
  padding-right: 1.625rem !important;
}

.m-27px {
  margin: 1.6875rem !important;
}

.ms-27px {
  margin-left: 1.6875rem !important;
}

.me-27px {
  margin-right: 1.6875rem !important;
}

.mt-27px {
  margin-top: 1.6875rem !important;
}

.mb-27px {
  margin-bottom: 1.6875rem !important;
}

.my-27px {
  margin-top: 1.6875rem !important;
}

.my-27px {
  margin-bottom: 1.6875rem !important;
}

.mx-27px {
  margin-left: 1.6875rem !important;
}

.mx-27px {
  margin-right: 1.6875rem !important;
}

.p-27px {
  padding: 1.6875rem !important;
}

.ps-27px {
  padding-left: 1.6875rem !important;
}

.pe-27px {
  padding-right: 1.6875rem !important;
}

.pt-27px {
  padding-top: 1.6875rem !important;
}

.pb-27px {
  padding-bottom: 1.6875rem !important;
}

.py-27px {
  padding-top: 1.6875rem !important;
}

.py-27px {
  padding-bottom: 1.6875rem !important;
}

.px-27px {
  padding-left: 1.6875rem !important;
}

.px-27px {
  padding-right: 1.6875rem !important;
}

.m-28px {
  margin: 1.75rem !important;
}

.ms-28px {
  margin-left: 1.75rem !important;
}

.me-28px {
  margin-right: 1.75rem !important;
}

.mt-28px {
  margin-top: 1.75rem !important;
}

.mb-28px {
  margin-bottom: 1.75rem !important;
}

.my-28px {
  margin-top: 1.75rem !important;
}

.my-28px {
  margin-bottom: 1.75rem !important;
}

.mx-28px {
  margin-left: 1.75rem !important;
}

.mx-28px {
  margin-right: 1.75rem !important;
}

.p-28px {
  padding: 1.75rem !important;
}

.ps-28px {
  padding-left: 1.75rem !important;
}

.pe-28px {
  padding-right: 1.75rem !important;
}

.pt-28px {
  padding-top: 1.75rem !important;
}

.pb-28px {
  padding-bottom: 1.75rem !important;
}

.py-28px {
  padding-top: 1.75rem !important;
}

.py-28px {
  padding-bottom: 1.75rem !important;
}

.px-28px {
  padding-left: 1.75rem !important;
}

.px-28px {
  padding-right: 1.75rem !important;
}

.m-29px {
  margin: 1.8125rem !important;
}

.ms-29px {
  margin-left: 1.8125rem !important;
}

.me-29px {
  margin-right: 1.8125rem !important;
}

.mt-29px {
  margin-top: 1.8125rem !important;
}

.mb-29px {
  margin-bottom: 1.8125rem !important;
}

.my-29px {
  margin-top: 1.8125rem !important;
}

.my-29px {
  margin-bottom: 1.8125rem !important;
}

.mx-29px {
  margin-left: 1.8125rem !important;
}

.mx-29px {
  margin-right: 1.8125rem !important;
}

.p-29px {
  padding: 1.8125rem !important;
}

.ps-29px {
  padding-left: 1.8125rem !important;
}

.pe-29px {
  padding-right: 1.8125rem !important;
}

.pt-29px {
  padding-top: 1.8125rem !important;
}

.pb-29px {
  padding-bottom: 1.8125rem !important;
}

.py-29px {
  padding-top: 1.8125rem !important;
}

.py-29px {
  padding-bottom: 1.8125rem !important;
}

.px-29px {
  padding-left: 1.8125rem !important;
}

.px-29px {
  padding-right: 1.8125rem !important;
}

.m-30px {
  margin: 1.875rem !important;
}

.ms-30px {
  margin-left: 1.875rem !important;
}

.me-30px {
  margin-right: 1.875rem !important;
}

.mt-30px {
  margin-top: 1.875rem !important;
}

.mb-30px {
  margin-bottom: 1.875rem !important;
}

.my-30px {
  margin-top: 1.875rem !important;
}

.my-30px {
  margin-bottom: 1.875rem !important;
}

.mx-30px {
  margin-left: 1.875rem !important;
}

.mx-30px {
  margin-right: 1.875rem !important;
}

.p-30px {
  padding: 1.875rem !important;
}

.ps-30px {
  padding-left: 1.875rem !important;
}

.pe-30px {
  padding-right: 1.875rem !important;
}

.pt-30px {
  padding-top: 1.875rem !important;
}

.pb-30px {
  padding-bottom: 1.875rem !important;
}

.py-30px {
  padding-top: 1.875rem !important;
}

.py-30px {
  padding-bottom: 1.875rem !important;
}

.px-30px {
  padding-left: 1.875rem !important;
}

.px-30px {
  padding-right: 1.875rem !important;
}

.m-31px {
  margin: 1.9375rem !important;
}

.ms-31px {
  margin-left: 1.9375rem !important;
}

.me-31px {
  margin-right: 1.9375rem !important;
}

.mt-31px {
  margin-top: 1.9375rem !important;
}

.mb-31px {
  margin-bottom: 1.9375rem !important;
}

.my-31px {
  margin-top: 1.9375rem !important;
}

.my-31px {
  margin-bottom: 1.9375rem !important;
}

.mx-31px {
  margin-left: 1.9375rem !important;
}

.mx-31px {
  margin-right: 1.9375rem !important;
}

.p-31px {
  padding: 1.9375rem !important;
}

.ps-31px {
  padding-left: 1.9375rem !important;
}

.pe-31px {
  padding-right: 1.9375rem !important;
}

.pt-31px {
  padding-top: 1.9375rem !important;
}

.pb-31px {
  padding-bottom: 1.9375rem !important;
}

.py-31px {
  padding-top: 1.9375rem !important;
}

.py-31px {
  padding-bottom: 1.9375rem !important;
}

.px-31px {
  padding-left: 1.9375rem !important;
}

.px-31px {
  padding-right: 1.9375rem !important;
}

.m-32px {
  margin: 2rem !important;
}

.ms-32px {
  margin-left: 2rem !important;
}

.me-32px {
  margin-right: 2rem !important;
}

.mt-32px {
  margin-top: 2rem !important;
}

.mb-32px {
  margin-bottom: 2rem !important;
}

.my-32px {
  margin-top: 2rem !important;
}

.my-32px {
  margin-bottom: 2rem !important;
}

.mx-32px {
  margin-left: 2rem !important;
}

.mx-32px {
  margin-right: 2rem !important;
}

.p-32px {
  padding: 2rem !important;
}

.ps-32px {
  padding-left: 2rem !important;
}

.pe-32px {
  padding-right: 2rem !important;
}

.pt-32px {
  padding-top: 2rem !important;
}

.pb-32px {
  padding-bottom: 2rem !important;
}

.py-32px {
  padding-top: 2rem !important;
}

.py-32px {
  padding-bottom: 2rem !important;
}

.px-32px {
  padding-left: 2rem !important;
}

.px-32px {
  padding-right: 2rem !important;
}

.m-33px {
  margin: 2.0625rem !important;
}

.ms-33px {
  margin-left: 2.0625rem !important;
}

.me-33px {
  margin-right: 2.0625rem !important;
}

.mt-33px {
  margin-top: 2.0625rem !important;
}

.mb-33px {
  margin-bottom: 2.0625rem !important;
}

.my-33px {
  margin-top: 2.0625rem !important;
}

.my-33px {
  margin-bottom: 2.0625rem !important;
}

.mx-33px {
  margin-left: 2.0625rem !important;
}

.mx-33px {
  margin-right: 2.0625rem !important;
}

.p-33px {
  padding: 2.0625rem !important;
}

.ps-33px {
  padding-left: 2.0625rem !important;
}

.pe-33px {
  padding-right: 2.0625rem !important;
}

.pt-33px {
  padding-top: 2.0625rem !important;
}

.pb-33px {
  padding-bottom: 2.0625rem !important;
}

.py-33px {
  padding-top: 2.0625rem !important;
}

.py-33px {
  padding-bottom: 2.0625rem !important;
}

.px-33px {
  padding-left: 2.0625rem !important;
}

.px-33px {
  padding-right: 2.0625rem !important;
}

.m-34px {
  margin: 2.125rem !important;
}

.ms-34px {
  margin-left: 2.125rem !important;
}

.me-34px {
  margin-right: 2.125rem !important;
}

.mt-34px {
  margin-top: 2.125rem !important;
}

.mb-34px {
  margin-bottom: 2.125rem !important;
}

.my-34px {
  margin-top: 2.125rem !important;
}

.my-34px {
  margin-bottom: 2.125rem !important;
}

.mx-34px {
  margin-left: 2.125rem !important;
}

.mx-34px {
  margin-right: 2.125rem !important;
}

.p-34px {
  padding: 2.125rem !important;
}

.ps-34px {
  padding-left: 2.125rem !important;
}

.pe-34px {
  padding-right: 2.125rem !important;
}

.pt-34px {
  padding-top: 2.125rem !important;
}

.pb-34px {
  padding-bottom: 2.125rem !important;
}

.py-34px {
  padding-top: 2.125rem !important;
}

.py-34px {
  padding-bottom: 2.125rem !important;
}

.px-34px {
  padding-left: 2.125rem !important;
}

.px-34px {
  padding-right: 2.125rem !important;
}

.m-35px {
  margin: 2.1875rem !important;
}

.ms-35px {
  margin-left: 2.1875rem !important;
}

.me-35px {
  margin-right: 2.1875rem !important;
}

.mt-35px {
  margin-top: 2.1875rem !important;
}

.mb-35px {
  margin-bottom: 2.1875rem !important;
}

.my-35px {
  margin-top: 2.1875rem !important;
}

.my-35px {
  margin-bottom: 2.1875rem !important;
}

.mx-35px {
  margin-left: 2.1875rem !important;
}

.mx-35px {
  margin-right: 2.1875rem !important;
}

.p-35px {
  padding: 2.1875rem !important;
}

.ps-35px {
  padding-left: 2.1875rem !important;
}

.pe-35px {
  padding-right: 2.1875rem !important;
}

.pt-35px {
  padding-top: 2.1875rem !important;
}

.pb-35px {
  padding-bottom: 2.1875rem !important;
}

.py-35px {
  padding-top: 2.1875rem !important;
}

.py-35px {
  padding-bottom: 2.1875rem !important;
}

.px-35px {
  padding-left: 2.1875rem !important;
}

.px-35px {
  padding-right: 2.1875rem !important;
}

.m-36px {
  margin: 2.25rem !important;
}

.ms-36px {
  margin-left: 2.25rem !important;
}

.me-36px {
  margin-right: 2.25rem !important;
}

.mt-36px {
  margin-top: 2.25rem !important;
}

.mb-36px {
  margin-bottom: 2.25rem !important;
}

.my-36px {
  margin-top: 2.25rem !important;
}

.my-36px {
  margin-bottom: 2.25rem !important;
}

.mx-36px {
  margin-left: 2.25rem !important;
}

.mx-36px {
  margin-right: 2.25rem !important;
}

.p-36px {
  padding: 2.25rem !important;
}

.ps-36px {
  padding-left: 2.25rem !important;
}

.pe-36px {
  padding-right: 2.25rem !important;
}

.pt-36px {
  padding-top: 2.25rem !important;
}

.pb-36px {
  padding-bottom: 2.25rem !important;
}

.py-36px {
  padding-top: 2.25rem !important;
}

.py-36px {
  padding-bottom: 2.25rem !important;
}

.px-36px {
  padding-left: 2.25rem !important;
}

.px-36px {
  padding-right: 2.25rem !important;
}

.m-37px {
  margin: 2.3125rem !important;
}

.ms-37px {
  margin-left: 2.3125rem !important;
}

.me-37px {
  margin-right: 2.3125rem !important;
}

.mt-37px {
  margin-top: 2.3125rem !important;
}

.mb-37px {
  margin-bottom: 2.3125rem !important;
}

.my-37px {
  margin-top: 2.3125rem !important;
}

.my-37px {
  margin-bottom: 2.3125rem !important;
}

.mx-37px {
  margin-left: 2.3125rem !important;
}

.mx-37px {
  margin-right: 2.3125rem !important;
}

.p-37px {
  padding: 2.3125rem !important;
}

.ps-37px {
  padding-left: 2.3125rem !important;
}

.pe-37px {
  padding-right: 2.3125rem !important;
}

.pt-37px {
  padding-top: 2.3125rem !important;
}

.pb-37px {
  padding-bottom: 2.3125rem !important;
}

.py-37px {
  padding-top: 2.3125rem !important;
}

.py-37px {
  padding-bottom: 2.3125rem !important;
}

.px-37px {
  padding-left: 2.3125rem !important;
}

.px-37px {
  padding-right: 2.3125rem !important;
}

.m-38px {
  margin: 2.375rem !important;
}

.ms-38px {
  margin-left: 2.375rem !important;
}

.me-38px {
  margin-right: 2.375rem !important;
}

.mt-38px {
  margin-top: 2.375rem !important;
}

.mb-38px {
  margin-bottom: 2.375rem !important;
}

.my-38px {
  margin-top: 2.375rem !important;
}

.my-38px {
  margin-bottom: 2.375rem !important;
}

.mx-38px {
  margin-left: 2.375rem !important;
}

.mx-38px {
  margin-right: 2.375rem !important;
}

.p-38px {
  padding: 2.375rem !important;
}

.ps-38px {
  padding-left: 2.375rem !important;
}

.pe-38px {
  padding-right: 2.375rem !important;
}

.pt-38px {
  padding-top: 2.375rem !important;
}

.pb-38px {
  padding-bottom: 2.375rem !important;
}

.py-38px {
  padding-top: 2.375rem !important;
}

.py-38px {
  padding-bottom: 2.375rem !important;
}

.px-38px {
  padding-left: 2.375rem !important;
}

.px-38px {
  padding-right: 2.375rem !important;
}

.m-39px {
  margin: 2.4375rem !important;
}

.ms-39px {
  margin-left: 2.4375rem !important;
}

.me-39px {
  margin-right: 2.4375rem !important;
}

.mt-39px {
  margin-top: 2.4375rem !important;
}

.mb-39px {
  margin-bottom: 2.4375rem !important;
}

.my-39px {
  margin-top: 2.4375rem !important;
}

.my-39px {
  margin-bottom: 2.4375rem !important;
}

.mx-39px {
  margin-left: 2.4375rem !important;
}

.mx-39px {
  margin-right: 2.4375rem !important;
}

.p-39px {
  padding: 2.4375rem !important;
}

.ps-39px {
  padding-left: 2.4375rem !important;
}

.pe-39px {
  padding-right: 2.4375rem !important;
}

.pt-39px {
  padding-top: 2.4375rem !important;
}

.pb-39px {
  padding-bottom: 2.4375rem !important;
}

.py-39px {
  padding-top: 2.4375rem !important;
}

.py-39px {
  padding-bottom: 2.4375rem !important;
}

.px-39px {
  padding-left: 2.4375rem !important;
}

.px-39px {
  padding-right: 2.4375rem !important;
}

.m-40px {
  margin: 2.5rem !important;
}

.ms-40px {
  margin-left: 2.5rem !important;
}

.me-40px {
  margin-right: 2.5rem !important;
}

.mt-40px {
  margin-top: 2.5rem !important;
}

.mb-40px {
  margin-bottom: 2.5rem !important;
}

.my-40px {
  margin-top: 2.5rem !important;
}

.my-40px {
  margin-bottom: 2.5rem !important;
}

.mx-40px {
  margin-left: 2.5rem !important;
}

.mx-40px {
  margin-right: 2.5rem !important;
}

.p-40px {
  padding: 2.5rem !important;
}

.ps-40px {
  padding-left: 2.5rem !important;
}

.pe-40px {
  padding-right: 2.5rem !important;
}

.pt-40px {
  padding-top: 2.5rem !important;
}

.pb-40px {
  padding-bottom: 2.5rem !important;
}

.py-40px {
  padding-top: 2.5rem !important;
}

.py-40px {
  padding-bottom: 2.5rem !important;
}

.px-40px {
  padding-left: 2.5rem !important;
}

.px-40px {
  padding-right: 2.5rem !important;
}

.m-41px {
  margin: 2.5625rem !important;
}

.ms-41px {
  margin-left: 2.5625rem !important;
}

.me-41px {
  margin-right: 2.5625rem !important;
}

.mt-41px {
  margin-top: 2.5625rem !important;
}

.mb-41px {
  margin-bottom: 2.5625rem !important;
}

.my-41px {
  margin-top: 2.5625rem !important;
}

.my-41px {
  margin-bottom: 2.5625rem !important;
}

.mx-41px {
  margin-left: 2.5625rem !important;
}

.mx-41px {
  margin-right: 2.5625rem !important;
}

.p-41px {
  padding: 2.5625rem !important;
}

.ps-41px {
  padding-left: 2.5625rem !important;
}

.pe-41px {
  padding-right: 2.5625rem !important;
}

.pt-41px {
  padding-top: 2.5625rem !important;
}

.pb-41px {
  padding-bottom: 2.5625rem !important;
}

.py-41px {
  padding-top: 2.5625rem !important;
}

.py-41px {
  padding-bottom: 2.5625rem !important;
}

.px-41px {
  padding-left: 2.5625rem !important;
}

.px-41px {
  padding-right: 2.5625rem !important;
}

.m-42px {
  margin: 2.625rem !important;
}

.ms-42px {
  margin-left: 2.625rem !important;
}

.me-42px {
  margin-right: 2.625rem !important;
}

.mt-42px {
  margin-top: 2.625rem !important;
}

.mb-42px {
  margin-bottom: 2.625rem !important;
}

.my-42px {
  margin-top: 2.625rem !important;
}

.my-42px {
  margin-bottom: 2.625rem !important;
}

.mx-42px {
  margin-left: 2.625rem !important;
}

.mx-42px {
  margin-right: 2.625rem !important;
}

.p-42px {
  padding: 2.625rem !important;
}

.ps-42px {
  padding-left: 2.625rem !important;
}

.pe-42px {
  padding-right: 2.625rem !important;
}

.pt-42px {
  padding-top: 2.625rem !important;
}

.pb-42px {
  padding-bottom: 2.625rem !important;
}

.py-42px {
  padding-top: 2.625rem !important;
}

.py-42px {
  padding-bottom: 2.625rem !important;
}

.px-42px {
  padding-left: 2.625rem !important;
}

.px-42px {
  padding-right: 2.625rem !important;
}

.m-43px {
  margin: 2.6875rem !important;
}

.ms-43px {
  margin-left: 2.6875rem !important;
}

.me-43px {
  margin-right: 2.6875rem !important;
}

.mt-43px {
  margin-top: 2.6875rem !important;
}

.mb-43px {
  margin-bottom: 2.6875rem !important;
}

.my-43px {
  margin-top: 2.6875rem !important;
}

.my-43px {
  margin-bottom: 2.6875rem !important;
}

.mx-43px {
  margin-left: 2.6875rem !important;
}

.mx-43px {
  margin-right: 2.6875rem !important;
}

.p-43px {
  padding: 2.6875rem !important;
}

.ps-43px {
  padding-left: 2.6875rem !important;
}

.pe-43px {
  padding-right: 2.6875rem !important;
}

.pt-43px {
  padding-top: 2.6875rem !important;
}

.pb-43px {
  padding-bottom: 2.6875rem !important;
}

.py-43px {
  padding-top: 2.6875rem !important;
}

.py-43px {
  padding-bottom: 2.6875rem !important;
}

.px-43px {
  padding-left: 2.6875rem !important;
}

.px-43px {
  padding-right: 2.6875rem !important;
}

.m-44px {
  margin: 2.75rem !important;
}

.ms-44px {
  margin-left: 2.75rem !important;
}

.me-44px {
  margin-right: 2.75rem !important;
}

.mt-44px {
  margin-top: 2.75rem !important;
}

.mb-44px {
  margin-bottom: 2.75rem !important;
}

.my-44px {
  margin-top: 2.75rem !important;
}

.my-44px {
  margin-bottom: 2.75rem !important;
}

.mx-44px {
  margin-left: 2.75rem !important;
}

.mx-44px {
  margin-right: 2.75rem !important;
}

.p-44px {
  padding: 2.75rem !important;
}

.ps-44px {
  padding-left: 2.75rem !important;
}

.pe-44px {
  padding-right: 2.75rem !important;
}

.pt-44px {
  padding-top: 2.75rem !important;
}

.pb-44px {
  padding-bottom: 2.75rem !important;
}

.py-44px {
  padding-top: 2.75rem !important;
}

.py-44px {
  padding-bottom: 2.75rem !important;
}

.px-44px {
  padding-left: 2.75rem !important;
}

.px-44px {
  padding-right: 2.75rem !important;
}

.m-45px {
  margin: 2.8125rem !important;
}

.ms-45px {
  margin-left: 2.8125rem !important;
}

.me-45px {
  margin-right: 2.8125rem !important;
}

.mt-45px {
  margin-top: 2.8125rem !important;
}

.mb-45px {
  margin-bottom: 2.8125rem !important;
}

.my-45px {
  margin-top: 2.8125rem !important;
}

.my-45px {
  margin-bottom: 2.8125rem !important;
}

.mx-45px {
  margin-left: 2.8125rem !important;
}

.mx-45px {
  margin-right: 2.8125rem !important;
}

.p-45px {
  padding: 2.8125rem !important;
}

.ps-45px {
  padding-left: 2.8125rem !important;
}

.pe-45px {
  padding-right: 2.8125rem !important;
}

.pt-45px {
  padding-top: 2.8125rem !important;
}

.pb-45px {
  padding-bottom: 2.8125rem !important;
}

.py-45px {
  padding-top: 2.8125rem !important;
}

.py-45px {
  padding-bottom: 2.8125rem !important;
}

.px-45px {
  padding-left: 2.8125rem !important;
}

.px-45px {
  padding-right: 2.8125rem !important;
}

.m-46px {
  margin: 2.875rem !important;
}

.ms-46px {
  margin-left: 2.875rem !important;
}

.me-46px {
  margin-right: 2.875rem !important;
}

.mt-46px {
  margin-top: 2.875rem !important;
}

.mb-46px {
  margin-bottom: 2.875rem !important;
}

.my-46px {
  margin-top: 2.875rem !important;
}

.my-46px {
  margin-bottom: 2.875rem !important;
}

.mx-46px {
  margin-left: 2.875rem !important;
}

.mx-46px {
  margin-right: 2.875rem !important;
}

.p-46px {
  padding: 2.875rem !important;
}

.ps-46px {
  padding-left: 2.875rem !important;
}

.pe-46px {
  padding-right: 2.875rem !important;
}

.pt-46px {
  padding-top: 2.875rem !important;
}

.pb-46px {
  padding-bottom: 2.875rem !important;
}

.py-46px {
  padding-top: 2.875rem !important;
}

.py-46px {
  padding-bottom: 2.875rem !important;
}

.px-46px {
  padding-left: 2.875rem !important;
}

.px-46px {
  padding-right: 2.875rem !important;
}

.m-47px {
  margin: 2.9375rem !important;
}

.ms-47px {
  margin-left: 2.9375rem !important;
}

.me-47px {
  margin-right: 2.9375rem !important;
}

.mt-47px {
  margin-top: 2.9375rem !important;
}

.mb-47px {
  margin-bottom: 2.9375rem !important;
}

.my-47px {
  margin-top: 2.9375rem !important;
}

.my-47px {
  margin-bottom: 2.9375rem !important;
}

.mx-47px {
  margin-left: 2.9375rem !important;
}

.mx-47px {
  margin-right: 2.9375rem !important;
}

.p-47px {
  padding: 2.9375rem !important;
}

.ps-47px {
  padding-left: 2.9375rem !important;
}

.pe-47px {
  padding-right: 2.9375rem !important;
}

.pt-47px {
  padding-top: 2.9375rem !important;
}

.pb-47px {
  padding-bottom: 2.9375rem !important;
}

.py-47px {
  padding-top: 2.9375rem !important;
}

.py-47px {
  padding-bottom: 2.9375rem !important;
}

.px-47px {
  padding-left: 2.9375rem !important;
}

.px-47px {
  padding-right: 2.9375rem !important;
}

.m-48px {
  margin: 3rem !important;
}

.ms-48px {
  margin-left: 3rem !important;
}

.me-48px {
  margin-right: 3rem !important;
}

.mt-48px {
  margin-top: 3rem !important;
}

.mb-48px {
  margin-bottom: 3rem !important;
}

.my-48px {
  margin-top: 3rem !important;
}

.my-48px {
  margin-bottom: 3rem !important;
}

.mx-48px {
  margin-left: 3rem !important;
}

.mx-48px {
  margin-right: 3rem !important;
}

.p-48px {
  padding: 3rem !important;
}

.ps-48px {
  padding-left: 3rem !important;
}

.pe-48px {
  padding-right: 3rem !important;
}

.pt-48px {
  padding-top: 3rem !important;
}

.pb-48px {
  padding-bottom: 3rem !important;
}

.py-48px {
  padding-top: 3rem !important;
}

.py-48px {
  padding-bottom: 3rem !important;
}

.px-48px {
  padding-left: 3rem !important;
}

.px-48px {
  padding-right: 3rem !important;
}

.m-49px {
  margin: 3.0625rem !important;
}

.ms-49px {
  margin-left: 3.0625rem !important;
}

.me-49px {
  margin-right: 3.0625rem !important;
}

.mt-49px {
  margin-top: 3.0625rem !important;
}

.mb-49px {
  margin-bottom: 3.0625rem !important;
}

.my-49px {
  margin-top: 3.0625rem !important;
}

.my-49px {
  margin-bottom: 3.0625rem !important;
}

.mx-49px {
  margin-left: 3.0625rem !important;
}

.mx-49px {
  margin-right: 3.0625rem !important;
}

.p-49px {
  padding: 3.0625rem !important;
}

.ps-49px {
  padding-left: 3.0625rem !important;
}

.pe-49px {
  padding-right: 3.0625rem !important;
}

.pt-49px {
  padding-top: 3.0625rem !important;
}

.pb-49px {
  padding-bottom: 3.0625rem !important;
}

.py-49px {
  padding-top: 3.0625rem !important;
}

.py-49px {
  padding-bottom: 3.0625rem !important;
}

.px-49px {
  padding-left: 3.0625rem !important;
}

.px-49px {
  padding-right: 3.0625rem !important;
}

.m-50px {
  margin: 3.125rem !important;
}

.ms-50px {
  margin-left: 3.125rem !important;
}

.me-50px {
  margin-right: 3.125rem !important;
}

.mt-50px {
  margin-top: 3.125rem !important;
}

.mb-50px {
  margin-bottom: 3.125rem !important;
}

.my-50px {
  margin-top: 3.125rem !important;
}

.my-50px {
  margin-bottom: 3.125rem !important;
}

.mx-50px {
  margin-left: 3.125rem !important;
}

.mx-50px {
  margin-right: 3.125rem !important;
}

.p-50px {
  padding: 3.125rem !important;
}

.ps-50px {
  padding-left: 3.125rem !important;
}

.pe-50px {
  padding-right: 3.125rem !important;
}

.pt-50px {
  padding-top: 3.125rem !important;
}

.pb-50px {
  padding-bottom: 3.125rem !important;
}

.py-50px {
  padding-top: 3.125rem !important;
}

.py-50px {
  padding-bottom: 3.125rem !important;
}

.px-50px {
  padding-left: 3.125rem !important;
}

.px-50px {
  padding-right: 3.125rem !important;
}

.m-51px {
  margin: 3.1875rem !important;
}

.ms-51px {
  margin-left: 3.1875rem !important;
}

.me-51px {
  margin-right: 3.1875rem !important;
}

.mt-51px {
  margin-top: 3.1875rem !important;
}

.mb-51px {
  margin-bottom: 3.1875rem !important;
}

.my-51px {
  margin-top: 3.1875rem !important;
}

.my-51px {
  margin-bottom: 3.1875rem !important;
}

.mx-51px {
  margin-left: 3.1875rem !important;
}

.mx-51px {
  margin-right: 3.1875rem !important;
}

.p-51px {
  padding: 3.1875rem !important;
}

.ps-51px {
  padding-left: 3.1875rem !important;
}

.pe-51px {
  padding-right: 3.1875rem !important;
}

.pt-51px {
  padding-top: 3.1875rem !important;
}

.pb-51px {
  padding-bottom: 3.1875rem !important;
}

.py-51px {
  padding-top: 3.1875rem !important;
}

.py-51px {
  padding-bottom: 3.1875rem !important;
}

.px-51px {
  padding-left: 3.1875rem !important;
}

.px-51px {
  padding-right: 3.1875rem !important;
}

.m-52px {
  margin: 3.25rem !important;
}

.ms-52px {
  margin-left: 3.25rem !important;
}

.me-52px {
  margin-right: 3.25rem !important;
}

.mt-52px {
  margin-top: 3.25rem !important;
}

.mb-52px {
  margin-bottom: 3.25rem !important;
}

.my-52px {
  margin-top: 3.25rem !important;
}

.my-52px {
  margin-bottom: 3.25rem !important;
}

.mx-52px {
  margin-left: 3.25rem !important;
}

.mx-52px {
  margin-right: 3.25rem !important;
}

.p-52px {
  padding: 3.25rem !important;
}

.ps-52px {
  padding-left: 3.25rem !important;
}

.pe-52px {
  padding-right: 3.25rem !important;
}

.pt-52px {
  padding-top: 3.25rem !important;
}

.pb-52px {
  padding-bottom: 3.25rem !important;
}

.py-52px {
  padding-top: 3.25rem !important;
}

.py-52px {
  padding-bottom: 3.25rem !important;
}

.px-52px {
  padding-left: 3.25rem !important;
}

.px-52px {
  padding-right: 3.25rem !important;
}

.m-53px {
  margin: 3.3125rem !important;
}

.ms-53px {
  margin-left: 3.3125rem !important;
}

.me-53px {
  margin-right: 3.3125rem !important;
}

.mt-53px {
  margin-top: 3.3125rem !important;
}

.mb-53px {
  margin-bottom: 3.3125rem !important;
}

.my-53px {
  margin-top: 3.3125rem !important;
}

.my-53px {
  margin-bottom: 3.3125rem !important;
}

.mx-53px {
  margin-left: 3.3125rem !important;
}

.mx-53px {
  margin-right: 3.3125rem !important;
}

.p-53px {
  padding: 3.3125rem !important;
}

.ps-53px {
  padding-left: 3.3125rem !important;
}

.pe-53px {
  padding-right: 3.3125rem !important;
}

.pt-53px {
  padding-top: 3.3125rem !important;
}

.pb-53px {
  padding-bottom: 3.3125rem !important;
}

.py-53px {
  padding-top: 3.3125rem !important;
}

.py-53px {
  padding-bottom: 3.3125rem !important;
}

.px-53px {
  padding-left: 3.3125rem !important;
}

.px-53px {
  padding-right: 3.3125rem !important;
}

.m-54px {
  margin: 3.375rem !important;
}

.ms-54px {
  margin-left: 3.375rem !important;
}

.me-54px {
  margin-right: 3.375rem !important;
}

.mt-54px {
  margin-top: 3.375rem !important;
}

.mb-54px {
  margin-bottom: 3.375rem !important;
}

.my-54px {
  margin-top: 3.375rem !important;
}

.my-54px {
  margin-bottom: 3.375rem !important;
}

.mx-54px {
  margin-left: 3.375rem !important;
}

.mx-54px {
  margin-right: 3.375rem !important;
}

.p-54px {
  padding: 3.375rem !important;
}

.ps-54px {
  padding-left: 3.375rem !important;
}

.pe-54px {
  padding-right: 3.375rem !important;
}

.pt-54px {
  padding-top: 3.375rem !important;
}

.pb-54px {
  padding-bottom: 3.375rem !important;
}

.py-54px {
  padding-top: 3.375rem !important;
}

.py-54px {
  padding-bottom: 3.375rem !important;
}

.px-54px {
  padding-left: 3.375rem !important;
}

.px-54px {
  padding-right: 3.375rem !important;
}

.m-55px {
  margin: 3.4375rem !important;
}

.ms-55px {
  margin-left: 3.4375rem !important;
}

.me-55px {
  margin-right: 3.4375rem !important;
}

.mt-55px {
  margin-top: 3.4375rem !important;
}

.mb-55px {
  margin-bottom: 3.4375rem !important;
}

.my-55px {
  margin-top: 3.4375rem !important;
}

.my-55px {
  margin-bottom: 3.4375rem !important;
}

.mx-55px {
  margin-left: 3.4375rem !important;
}

.mx-55px {
  margin-right: 3.4375rem !important;
}

.p-55px {
  padding: 3.4375rem !important;
}

.ps-55px {
  padding-left: 3.4375rem !important;
}

.pe-55px {
  padding-right: 3.4375rem !important;
}

.pt-55px {
  padding-top: 3.4375rem !important;
}

.pb-55px {
  padding-bottom: 3.4375rem !important;
}

.py-55px {
  padding-top: 3.4375rem !important;
}

.py-55px {
  padding-bottom: 3.4375rem !important;
}

.px-55px {
  padding-left: 3.4375rem !important;
}

.px-55px {
  padding-right: 3.4375rem !important;
}

.m-56px {
  margin: 3.5rem !important;
}

.ms-56px {
  margin-left: 3.5rem !important;
}

.me-56px {
  margin-right: 3.5rem !important;
}

.mt-56px {
  margin-top: 3.5rem !important;
}

.mb-56px {
  margin-bottom: 3.5rem !important;
}

.my-56px {
  margin-top: 3.5rem !important;
}

.my-56px {
  margin-bottom: 3.5rem !important;
}

.mx-56px {
  margin-left: 3.5rem !important;
}

.mx-56px {
  margin-right: 3.5rem !important;
}

.p-56px {
  padding: 3.5rem !important;
}

.ps-56px {
  padding-left: 3.5rem !important;
}

.pe-56px {
  padding-right: 3.5rem !important;
}

.pt-56px {
  padding-top: 3.5rem !important;
}

.pb-56px {
  padding-bottom: 3.5rem !important;
}

.py-56px {
  padding-top: 3.5rem !important;
}

.py-56px {
  padding-bottom: 3.5rem !important;
}

.px-56px {
  padding-left: 3.5rem !important;
}

.px-56px {
  padding-right: 3.5rem !important;
}

.m-57px {
  margin: 3.5625rem !important;
}

.ms-57px {
  margin-left: 3.5625rem !important;
}

.me-57px {
  margin-right: 3.5625rem !important;
}

.mt-57px {
  margin-top: 3.5625rem !important;
}

.mb-57px {
  margin-bottom: 3.5625rem !important;
}

.my-57px {
  margin-top: 3.5625rem !important;
}

.my-57px {
  margin-bottom: 3.5625rem !important;
}

.mx-57px {
  margin-left: 3.5625rem !important;
}

.mx-57px {
  margin-right: 3.5625rem !important;
}

.p-57px {
  padding: 3.5625rem !important;
}

.ps-57px {
  padding-left: 3.5625rem !important;
}

.pe-57px {
  padding-right: 3.5625rem !important;
}

.pt-57px {
  padding-top: 3.5625rem !important;
}

.pb-57px {
  padding-bottom: 3.5625rem !important;
}

.py-57px {
  padding-top: 3.5625rem !important;
}

.py-57px {
  padding-bottom: 3.5625rem !important;
}

.px-57px {
  padding-left: 3.5625rem !important;
}

.px-57px {
  padding-right: 3.5625rem !important;
}

.m-58px {
  margin: 3.625rem !important;
}

.ms-58px {
  margin-left: 3.625rem !important;
}

.me-58px {
  margin-right: 3.625rem !important;
}

.mt-58px {
  margin-top: 3.625rem !important;
}

.mb-58px {
  margin-bottom: 3.625rem !important;
}

.my-58px {
  margin-top: 3.625rem !important;
}

.my-58px {
  margin-bottom: 3.625rem !important;
}

.mx-58px {
  margin-left: 3.625rem !important;
}

.mx-58px {
  margin-right: 3.625rem !important;
}

.p-58px {
  padding: 3.625rem !important;
}

.ps-58px {
  padding-left: 3.625rem !important;
}

.pe-58px {
  padding-right: 3.625rem !important;
}

.pt-58px {
  padding-top: 3.625rem !important;
}

.pb-58px {
  padding-bottom: 3.625rem !important;
}

.py-58px {
  padding-top: 3.625rem !important;
}

.py-58px {
  padding-bottom: 3.625rem !important;
}

.px-58px {
  padding-left: 3.625rem !important;
}

.px-58px {
  padding-right: 3.625rem !important;
}

.m-59px {
  margin: 3.6875rem !important;
}

.ms-59px {
  margin-left: 3.6875rem !important;
}

.me-59px {
  margin-right: 3.6875rem !important;
}

.mt-59px {
  margin-top: 3.6875rem !important;
}

.mb-59px {
  margin-bottom: 3.6875rem !important;
}

.my-59px {
  margin-top: 3.6875rem !important;
}

.my-59px {
  margin-bottom: 3.6875rem !important;
}

.mx-59px {
  margin-left: 3.6875rem !important;
}

.mx-59px {
  margin-right: 3.6875rem !important;
}

.p-59px {
  padding: 3.6875rem !important;
}

.ps-59px {
  padding-left: 3.6875rem !important;
}

.pe-59px {
  padding-right: 3.6875rem !important;
}

.pt-59px {
  padding-top: 3.6875rem !important;
}

.pb-59px {
  padding-bottom: 3.6875rem !important;
}

.py-59px {
  padding-top: 3.6875rem !important;
}

.py-59px {
  padding-bottom: 3.6875rem !important;
}

.px-59px {
  padding-left: 3.6875rem !important;
}

.px-59px {
  padding-right: 3.6875rem !important;
}

.m-60px {
  margin: 3.75rem !important;
}

.ms-60px {
  margin-left: 3.75rem !important;
}

.me-60px {
  margin-right: 3.75rem !important;
}

.mt-60px {
  margin-top: 3.75rem !important;
}

.mb-60px {
  margin-bottom: 3.75rem !important;
}

.my-60px {
  margin-top: 3.75rem !important;
}

.my-60px {
  margin-bottom: 3.75rem !important;
}

.mx-60px {
  margin-left: 3.75rem !important;
}

.mx-60px {
  margin-right: 3.75rem !important;
}

.p-60px {
  padding: 3.75rem !important;
}

.ps-60px {
  padding-left: 3.75rem !important;
}

.pe-60px {
  padding-right: 3.75rem !important;
}

.pt-60px {
  padding-top: 3.75rem !important;
}

.pb-60px {
  padding-bottom: 3.75rem !important;
}

.py-60px {
  padding-top: 3.75rem !important;
}

.py-60px {
  padding-bottom: 3.75rem !important;
}

.px-60px {
  padding-left: 3.75rem !important;
}

.px-60px {
  padding-right: 3.75rem !important;
}

.rs-animation--shine {
  position: relative;
  overflow: hidden;
}
.rs-animation--shine:after {
  content: "";
  top: 0;
  opacity: 0.8;
  transform: translateX(-200px);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  animation: shine 2s;
  animation-direction: alternate;
  animation-iteration-count: 2;
  animation-delay: 1s;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
}
@keyframes shine {
  0% {
    transform: translateX(-200%);
  }
  40% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.rs-cursor-pointer {
  cursor: pointer;
}

.rs-uppercase {
  text-transform: uppercase !important;
}

.rs-read-only {
  pointer-events: none;
}
.rs-read-only .rs-clear-form-field-button {
  display: none !important;
}

/* import only the necessary Bootstrap files */
/* Vars */
/** increase the width of the grid lg and xl breakpoints */
/** increase container width for the wider lg and xl breakpoints */
/* --- end customization --- */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: rgb(5.2, 44, 101.2);
  --bs-secondary-text-emphasis: rgb(43.2, 46.8, 50);
  --bs-success-text-emphasis: rgb(10, 54, 33.6);
  --bs-info-text-emphasis: rgb(5.2, 80.8, 96);
  --bs-warning-text-emphasis: rgb(102, 77.2, 2.8);
  --bs-danger-text-emphasis: rgb(88, 21.2, 27.6);
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: rgb(206.6, 226, 254.6);
  --bs-secondary-bg-subtle: rgb(225.6, 227.4, 229);
  --bs-success-bg-subtle: rgb(209, 231, 220.8);
  --bs-info-bg-subtle: rgb(206.6, 244.4, 252);
  --bs-warning-bg-subtle: rgb(255, 242.6, 205.4);
  --bs-danger-bg-subtle: rgb(248, 214.6, 217.8);
  --bs-light-bg-subtle: rgb(251.5, 252, 252.5);
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: rgb(158.2, 197, 254.2);
  --bs-secondary-border-subtle: rgb(196.2, 199.8, 203);
  --bs-success-border-subtle: rgb(163, 207, 186.6);
  --bs-info-border-subtle: rgb(158.2, 233.8, 249);
  --bs-warning-border-subtle: rgb(255, 230.2, 155.8);
  --bs-danger-border-subtle: rgb(241, 174.2, 180.6);
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Montserrat Light", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: "Montserrat Light", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: rgb(10.4, 88, 202.4);
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: rgb(255, 242.6, 205.4);
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: rgb(42.5, 47.5, 52.5);
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: rgb(109.8, 168, 253.8);
  --bs-secondary-text-emphasis: rgb(166.8, 172.2, 177);
  --bs-success-text-emphasis: rgb(117, 183, 152.4);
  --bs-info-text-emphasis: rgb(109.8, 223.2, 246);
  --bs-warning-text-emphasis: rgb(255, 217.8, 106.2);
  --bs-danger-text-emphasis: rgb(234, 133.8, 143.4);
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: rgb(2.6, 22, 50.6);
  --bs-secondary-bg-subtle: rgb(21.6, 23.4, 25);
  --bs-success-bg-subtle: rgb(5, 27, 16.8);
  --bs-info-bg-subtle: rgb(2.6, 40.4, 48);
  --bs-warning-bg-subtle: rgb(51, 38.6, 1.4);
  --bs-danger-bg-subtle: rgb(44, 10.6, 13.8);
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: rgb(7.8, 66, 151.8);
  --bs-secondary-border-subtle: rgb(64.8, 70.2, 75);
  --bs-success-border-subtle: rgb(15, 81, 50.4);
  --bs-info-border-subtle: rgb(7.8, 121.2, 144);
  --bs-warning-border-subtle: rgb(153, 115.8, 4.2);
  --bs-danger-border-subtle: rgb(132, 31.8, 41.4);
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: rgb(109.8, 168, 253.8);
  --bs-link-hover-color: rgb(138.84, 185.4, 254.04);
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: rgb(230.4, 132.6, 181.2);
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: rgb(102, 77.2, 2.8);
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: rgb(117, 183, 152.4);
  --bs-form-valid-border-color: rgb(117, 183, 152.4);
  --bs-form-invalid-color: rgb(234, 133.8, 143.4);
  --bs-form-invalid-border-color: rgb(234, 133.8, 143.4);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 90%;
  }
}
@media (min-width: 1396px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 80%;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1396px;
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1396px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1396px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1396px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
@property --rs-card--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-card--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 30px;
}
@property --rs-card--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 30px;
}
@property --rs-card--margin-bottom {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-card--background-color--primary {
  syntax: "<color>";
  inherits: false;
  initial-value: white;
}
@property --rs-card-details--margin-bottom {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-card-details--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
/* Breakpoints */
@property --rs-container-breakpoint--lg {
  syntax: "<length>";
  inherits: false;
  initial-value: 992px;
}
@property --rs-ds--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-ds--border-radius--small {
  syntax: "<length>";
  inherits: true;
  initial-value: 4px;
}
@property --rs-ds--main-container--width {
  syntax: "<percentage>";
  inherits: true;
  initial-value: 100%;
}
@property --rs-ds--main-container-top-spacing {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-ds--main-container--padding-inline {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-ds--section--bottom-spacing {
  syntax: "<length>";
  inherits: true;
  initial-value: 40px;
}
@property --rs-ds--link--color {
  syntax: "<length>";
  inherits: true;
  initial-value: orange;
}
:root {
  --rs-ds--font--primary: "Montserrat";
  --rs-ds--font--primary-light: "Montserrat-light";
  --rs-ds--body-font-size: 0.875rem;
  --rs-ds--body-line-height: 1.375rem;
  --rs-ds--font-size--xs: 0.625rem;
  --rs-ds--font-size--xs-line-height: 1rem;
  --rs-ds--font-size--sm: 0.75rem;
  --rs-ds--font-size--sm-line-height: 1.25rem;
  --rs-ds--font-size--md: 0.875rem;
  --rs-ds--font-size--md-line-height: 1.375rem;
  --rs-ds--font-size--lg: 1rem;
  --rs-ds--font-size--lg-line-height: 1.5rem;
  --rs-ds--text-color: var(--rs-color--grey--700);
  --rs-ds--text-secondary: var(--rs-color--grey--500);
  --rs-ds--text-disabled: var(--rs-color--grey--300);
  --rs-ds--text-dark: var(--rs-color--grey--900);
  --rs-ds--border-color: var(--rs-color--grey--200);
  --rs-ds--color--primary: var(--rs-color--orange--300);
  --rs-ds--color--primary--hover: hsl(from var(--rs-ds--color--primary) h calc(s - 2) calc(l - 15));
  --rs-ds--color--hover: var(--rs-color--grey--100);
  --rs-ds--color--alert: var(--rs-color--red--400);
  --rs-ds--background-color: var(--rs-color--grey--50);
  --rs-ds--color--icon--grey: var(--rs-color--grey--700);
  --rs-ds--color--icon--disabled: var(--rs-color--grey--300);
  --rs-ds--color--icon--orange: var(--rs-color--orange--300);
  --rs-ds--link--color: var(--rs-ds--color--primary);
  --rs-ds--button-height: 2.5rem;
  --rs-transition--bezier: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --rs-ds--box--bottom-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.08);
  --rs-ds--box--top-shadow: 1px -6px 10px 0 rgba(98, 105, 115, 0.08);
  --rs-ds--main-container-top-spacing: 2.5rem;
  --rs-ds--main-container--padding-inline: 1rem;
  --rs-ds--section--bottom-spacing: 2.5rem;
}

/**
 * @param {*} $param
 * @param {String|*} $param-optional
 */
/* Fonts */
button,
a[mat-button] {
  --rs-btn--border-radius: 12px;
  --rs-btn--padding-inline: 1rem;
  --rs-btn--padding-block: 0.438rem;
  --rs-btn--with-icon--padding: 1rem;
  --rs-btn--height: 2.5rem;
  --rs-btn--line-height: 1rem;
  --rs-btn--color--disabled: hsl(from var(--rs-ds--color--primary) h calc(s + 4) calc(l + 22));
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
}

button[mat-button].rs-btn--text,
a[mat-button].rs-btn--text, button[mat-button].rs-btn--secondary,
a[mat-button].rs-btn--secondary, button[mat-button].rs-btn--primary,
a[mat-button].rs-btn--primary {
  min-width: auto;
  background: transparent;
  border-radius: var(--rs-btn--border-radius);
  padding-inline: var(--rs-btn--padding-inline);
  padding-block: var(--rs-btn--padding-block);
  height: var(--rs-btn--height);
  line-height: var(--rs-btn--line-height);
  border: none;
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
}
button[mat-button].rs-btn--text > mat-icon,
a[mat-button].rs-btn--text > mat-icon, button[mat-button].rs-btn--secondary > mat-icon,
a[mat-button].rs-btn--secondary > mat-icon, button[mat-button].rs-btn--primary > mat-icon,
a[mat-button].rs-btn--primary > mat-icon {
  --mat-text-button-icon-offset: -3px;
}
button[mat-button].rs-btn--text:focus-visible,
a[mat-button].rs-btn--text:focus-visible, button[mat-button].rs-btn--secondary:focus-visible,
a[mat-button].rs-btn--secondary:focus-visible, button[mat-button].rs-btn--primary:focus-visible,
a[mat-button].rs-btn--primary:focus-visible {
  outline: 2px solid var(--rs-color--blue--500);
  outline-offset: -2px;
}

button[mat-button].rs-btn--primary,
a[mat-button].rs-btn--primary {
  background-color: var(--rs-ds--color--primary);
  color: white;
}
button[mat-button].rs-btn--primary:hover,
a[mat-button].rs-btn--primary:hover {
  background-color: var(--rs-ds--color--primary--hover);
}
button[mat-button].rs-btn--primary:disabled,
a[mat-button].rs-btn--primary:disabled, button.mat-mdc-button-disabled[mat-button].rs-btn--primary,
a.mat-mdc-button-disabled[mat-button].rs-btn--primary, button.rs-button-disabled[mat-button].rs-btn--primary,
a.rs-button-disabled[mat-button].rs-btn--primary {
  background-color: var(--rs-btn--color--disabled);
}
button[mat-button].rs-btn--primary .mat-icon,
a[mat-button].rs-btn--primary .mat-icon {
  color: white;
}

button[mat-button].rs-btn--secondary,
a[mat-button].rs-btn--secondary {
  background-color: transparent;
  color: var(--rs-ds--color--primary);
  border: 1px solid var(--rs-ds--color--primary);
}
button[mat-button].rs-btn--secondary .mat-icon,
a[mat-button].rs-btn--secondary .mat-icon {
  color: var(--rs-ds--color--primary);
}
button[mat-button].rs-btn--secondary:hover,
a[mat-button].rs-btn--secondary:hover {
  border: 1px solid var(--rs-ds--color--primary--hover);
  color: var(--rs-ds--color--primary--hover);
}
button[mat-button].rs-btn--secondary:hover mat-icon,
a[mat-button].rs-btn--secondary:hover mat-icon {
  color: var(--rs-ds--color--primary--hover);
}
button[mat-button].rs-btn--secondary:disabled,
a[mat-button].rs-btn--secondary:disabled, button.mat-mdc-button-disabled[mat-button].rs-btn--secondary,
a.mat-mdc-button-disabled[mat-button].rs-btn--secondary, button.rs-button-disabled[mat-button].rs-btn--secondary,
a.rs-button-disabled[mat-button].rs-btn--secondary {
  border: 1px solid var(--rs-btn--color--disabled);
  color: var(--rs-btn--color--disabled);
}

button[mat-button].rs-btn--text,
a[mat-button].rs-btn--text {
  --rs-btn--with-icon--padding: 0;
  min-width: auto;
  padding-inline: 0;
  background-color: transparent !important;
  color: var(--rs-ds--color--primary);
}
button[mat-button].rs-btn--text .mat-icon,
a[mat-button].rs-btn--text .mat-icon {
  --mat-text-button-icon-offset: -1px;
  color: var(--rs-ds--color--primary);
}
button[mat-button].rs-btn--text:hover,
a[mat-button].rs-btn--text:hover {
  color: var(--rs-ds--color--primary--hover);
}
button[mat-button].rs-btn--text:hover mat-icon,
a[mat-button].rs-btn--text:hover mat-icon {
  color: var(--rs-ds--color--primary--hover);
}
button[mat-button].rs-btn--text:disabled,
a[mat-button].rs-btn--text:disabled, button.mat-mdc-button-disabled[mat-button].rs-btn--text,
a.mat-mdc-button-disabled[mat-button].rs-btn--text, button.rs-button-disabled[mat-button].rs-btn--text,
a.rs-button-disabled[mat-button].rs-btn--text {
  color: var(--rs-btn--color--disabled);
}
button[mat-button].rs-btn--text:hover,
a[mat-button].rs-btn--text:hover {
  text-decoration: underline;
}
button[mat-button].rs-btn--text .mat-ripple.mat-mdc-button-ripple,
a[mat-button].rs-btn--text .mat-ripple.mat-mdc-button-ripple {
  display: none;
}

.introjs-tooltipReferenceLayer * {
  font-family: "Montserrat-light" !important;
  font-weight: 400;
}
.introjs-tooltipReferenceLayer .introjs-tooltip {
  display: flex !important;
  flex-direction: column;
}
.introjs-tooltipReferenceLayer .introjs-tooltip.step-1 .introjs-prevbutton {
  display: none;
}
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltiptext {
  padding-top: 0;
  padding-bottom: 0;
}
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltiptext h2,
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltiptext .title {
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 15px;
}
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltiptext * {
  color: var(--rs-ds--text-color);
}
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-bullets {
  order: 2;
}
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons {
  border-top: none;
  align-self: center;
  order: 1;
}
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons .introjs-button {
  border: none;
  text-shadow: none;
  color: var(--rs-color--white);
  padding: 8px 30px;
}
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons .introjs-button:focus {
  box-shadow: none;
}
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons .introjs-button.introjs-nextbutton {
  background-color: var(--rs-ds--color--primary);
  border-radius: var(--rs-ds--border-radius);
  padding: 7px 16px;
}
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons .introjs-button.introjs-nextbutton:hover {
  background-color: var(--rs-ds--color--primary--hover);
}
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons .introjs-button.introjs-prevbutton {
  margin-right: 15px;
  color: var(--rs-ds--color--primary);
  background-color: transparent;
  border: 1px solid var(--rs-ds--color--primary);
  border-radius: var(--rs-ds--border-radius);
  padding: 7px 16px;
}
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons .introjs-button.introjs-prevbutton:not(.introjs-disabled):hover {
  border: 1px solid var(--rs-ds--color--primary--hover);
  color: var(--rs-ds--color--primary--hover);
}

@property --rs-card--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-card--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 30px;
}
@property --rs-card--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 30px;
}
@property --rs-card--margin-bottom {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-card--background-color--primary {
  syntax: "<color>";
  inherits: false;
  initial-value: white;
}
@property --rs-card-details--margin-bottom {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-card-details--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
/* Breakpoints */
@property --rs-container-breakpoint--lg {
  syntax: "<length>";
  inherits: false;
  initial-value: 992px;
}
@property --rs-ds--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-ds--border-radius--small {
  syntax: "<length>";
  inherits: true;
  initial-value: 4px;
}
@property --rs-ds--main-container--width {
  syntax: "<percentage>";
  inherits: true;
  initial-value: 100%;
}
@property --rs-ds--main-container-top-spacing {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-ds--main-container--padding-inline {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-ds--section--bottom-spacing {
  syntax: "<length>";
  inherits: true;
  initial-value: 40px;
}
@property --rs-ds--link--color {
  syntax: "<length>";
  inherits: true;
  initial-value: orange;
}
:root {
  --rs-ds--font--primary: "Montserrat";
  --rs-ds--font--primary-light: "Montserrat-light";
  --rs-ds--body-font-size: 0.875rem;
  --rs-ds--body-line-height: 1.375rem;
  --rs-ds--font-size--xs: 0.625rem;
  --rs-ds--font-size--xs-line-height: 1rem;
  --rs-ds--font-size--sm: 0.75rem;
  --rs-ds--font-size--sm-line-height: 1.25rem;
  --rs-ds--font-size--md: 0.875rem;
  --rs-ds--font-size--md-line-height: 1.375rem;
  --rs-ds--font-size--lg: 1rem;
  --rs-ds--font-size--lg-line-height: 1.5rem;
  --rs-ds--text-color: var(--rs-color--grey--700);
  --rs-ds--text-secondary: var(--rs-color--grey--500);
  --rs-ds--text-disabled: var(--rs-color--grey--300);
  --rs-ds--text-dark: var(--rs-color--grey--900);
  --rs-ds--border-color: var(--rs-color--grey--200);
  --rs-ds--color--primary: var(--rs-color--orange--300);
  --rs-ds--color--primary--hover: hsl(from var(--rs-ds--color--primary) h calc(s - 2) calc(l - 15));
  --rs-ds--color--hover: var(--rs-color--grey--100);
  --rs-ds--color--alert: var(--rs-color--red--400);
  --rs-ds--background-color: var(--rs-color--grey--50);
  --rs-ds--color--icon--grey: var(--rs-color--grey--700);
  --rs-ds--color--icon--disabled: var(--rs-color--grey--300);
  --rs-ds--color--icon--orange: var(--rs-color--orange--300);
  --rs-ds--link--color: var(--rs-ds--color--primary);
  --rs-ds--button-height: 2.5rem;
  --rs-transition--bezier: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --rs-ds--box--bottom-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.08);
  --rs-ds--box--top-shadow: 1px -6px 10px 0 rgba(98, 105, 115, 0.08);
  --rs-ds--main-container-top-spacing: 2.5rem;
  --rs-ds--main-container--padding-inline: 1rem;
  --rs-ds--section--bottom-spacing: 2.5rem;
}

/**
 * @param {*} $param
 * @param {String|*} $param-optional
 */
/* Fonts */
button,
a[mat-button] {
  --rs-btn--border-radius: 12px;
  --rs-btn--padding-inline: 1rem;
  --rs-btn--padding-block: 0.438rem;
  --rs-btn--with-icon--padding: 1rem;
  --rs-btn--height: 2.5rem;
  --rs-btn--line-height: 1rem;
  --rs-btn--color--disabled: hsl(from var(--rs-ds--color--primary) h calc(s + 4) calc(l + 22));
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
}

button[mat-button].rs-btn--primary,
a[mat-button].rs-btn--primary, button[mat-button].rs-btn--secondary,
a[mat-button].rs-btn--secondary, button[mat-button].rs-btn--text,
a[mat-button].rs-btn--text {
  min-width: auto;
  background: transparent;
  border-radius: var(--rs-btn--border-radius);
  padding-inline: var(--rs-btn--padding-inline);
  padding-block: var(--rs-btn--padding-block);
  height: var(--rs-btn--height);
  line-height: var(--rs-btn--line-height);
  border: none;
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
}

button[mat-button].rs-btn--primary > mat-icon,
a[mat-button].rs-btn--primary > mat-icon, button[mat-button].rs-btn--secondary > mat-icon,
a[mat-button].rs-btn--secondary > mat-icon, button[mat-button].rs-btn--text > mat-icon,
a[mat-button].rs-btn--text > mat-icon {
  --mat-text-button-icon-offset: -3px;
}

button[mat-button].rs-btn--primary:focus-visible,
a[mat-button].rs-btn--primary:focus-visible, button[mat-button].rs-btn--secondary:focus-visible,
a[mat-button].rs-btn--secondary:focus-visible, button[mat-button].rs-btn--text:focus-visible,
a[mat-button].rs-btn--text:focus-visible {
  outline: 2px solid var(--rs-color--blue--500);
  outline-offset: -2px;
}

button[mat-button].rs-btn--primary,
a[mat-button].rs-btn--primary {
  background-color: var(--rs-ds--color--primary);
  color: white;
}

button[mat-button].rs-btn--primary:hover,
a[mat-button].rs-btn--primary:hover {
  background-color: var(--rs-ds--color--primary--hover);
}

button[mat-button].rs-btn--primary:disabled,
a[mat-button].rs-btn--primary:disabled, button.mat-mdc-button-disabled[mat-button].rs-btn--primary,
a.mat-mdc-button-disabled[mat-button].rs-btn--primary, button.rs-button-disabled[mat-button].rs-btn--primary,
a.rs-button-disabled[mat-button].rs-btn--primary {
  background-color: var(--rs-btn--color--disabled);
}

button[mat-button].rs-btn--primary .mat-icon,
a[mat-button].rs-btn--primary .mat-icon {
  color: white;
}

button[mat-button].rs-btn--secondary,
a[mat-button].rs-btn--secondary {
  background-color: transparent;
  color: var(--rs-ds--color--primary);
  border: 1px solid var(--rs-ds--color--primary);
}

button[mat-button].rs-btn--secondary .mat-icon,
a[mat-button].rs-btn--secondary .mat-icon {
  color: var(--rs-ds--color--primary);
}

button[mat-button].rs-btn--secondary:hover,
a[mat-button].rs-btn--secondary:hover {
  border: 1px solid var(--rs-ds--color--primary--hover);
  color: var(--rs-ds--color--primary--hover);
}

button[mat-button].rs-btn--secondary:hover mat-icon,
a[mat-button].rs-btn--secondary:hover mat-icon {
  color: var(--rs-ds--color--primary--hover);
}

button[mat-button].rs-btn--secondary:disabled,
a[mat-button].rs-btn--secondary:disabled, button.mat-mdc-button-disabled[mat-button].rs-btn--secondary,
a.mat-mdc-button-disabled[mat-button].rs-btn--secondary, button.rs-button-disabled[mat-button].rs-btn--secondary,
a.rs-button-disabled[mat-button].rs-btn--secondary {
  border: 1px solid var(--rs-btn--color--disabled);
  color: var(--rs-btn--color--disabled);
}

button[mat-button].rs-btn--text,
a[mat-button].rs-btn--text {
  --rs-btn--with-icon--padding: 0;
  min-width: auto;
  padding-inline: 0;
  background-color: transparent !important;
  color: var(--rs-ds--color--primary);
}

button[mat-button].rs-btn--text .mat-icon,
a[mat-button].rs-btn--text .mat-icon {
  --mat-text-button-icon-offset: -1px;
  color: var(--rs-ds--color--primary);
}

button[mat-button].rs-btn--text:hover,
a[mat-button].rs-btn--text:hover {
  color: var(--rs-ds--color--primary--hover);
}

button[mat-button].rs-btn--text:hover mat-icon,
a[mat-button].rs-btn--text:hover mat-icon {
  color: var(--rs-ds--color--primary--hover);
}

button[mat-button].rs-btn--text:disabled,
a[mat-button].rs-btn--text:disabled, button.mat-mdc-button-disabled[mat-button].rs-btn--text,
a.mat-mdc-button-disabled[mat-button].rs-btn--text, button.rs-button-disabled[mat-button].rs-btn--text,
a.rs-button-disabled[mat-button].rs-btn--text {
  color: var(--rs-btn--color--disabled);
}

button[mat-button].rs-btn--text:hover,
a[mat-button].rs-btn--text:hover {
  text-decoration: underline;
}

button[mat-button].rs-btn--text .mat-ripple.mat-mdc-button-ripple,
a[mat-button].rs-btn--text .mat-ripple.mat-mdc-button-ripple {
  display: none;
}

:root {
  --mdc-fab-small-container-color: var(--rs-ds--color--primary);
}

button[mat-icon-button].mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
}
button[mat-icon-button].mat-mdc-button-base.rs-icon-button-orange {
  border: 1px solid;
  line-height: 20px;
  border-color: var(--rs-ds--color--primary);
  color: var(--rs-ds--color--primary);
}
button[mat-icon-button].mat-mdc-button-base.rs-icon-button-orange[disabled], button[mat-icon-button].mat-mdc-button-base.rs-icon-button-orange.rs-button-disabled {
  border-color: var(--rs-ds--color--icon--disabled);
  color: var(--rs-ds--color--icon--disabled);
}
button[mat-icon-button].mat-mdc-button-base.rs-button-icon-rectangle {
  border-radius: var(--rs-btn--border-radius);
}
button[mat-icon-button].mat-mdc-button-base.rs-button-icon-rectangle .mat-mdc-button-persistent-ripple {
  border-radius: var(--rs-btn--border-radius);
}
button[mat-icon-button].mat-mdc-button-base.rs-button-icon-rectangle:disabled, button[mat-icon-button].mat-mdc-button-base.rs-button-icon-rectangle.rs-button-disabled {
  pointer-events: all !important;
  cursor: not-allowed !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: var(--rs-color--white) !important;
}
button[mat-icon-button].mat-mdc-button-base.rs-button-icon-rectangle:disabled .mat-icon, button[mat-icon-button].mat-mdc-button-base.rs-button-icon-rectangle.rs-button-disabled .mat-icon {
  color: var(--rs-color--white) !important;
}
button[mat-icon-button].mat-mdc-button-base.rs-button-icon-rectangle:disabled .mat-mdc-button-persistent-ripple::before, button[mat-icon-button].mat-mdc-button-base.rs-button-icon-rectangle.rs-button-disabled .mat-mdc-button-persistent-ripple::before {
  background-color: transparent;
}
button[mat-icon-button].mat-mdc-button-base:disabled, button[mat-icon-button].mat-mdc-button-base.rs-button-disabled {
  cursor: not-allowed !important;
}
button[mat-icon-button].mat-mdc-button-base:disabled .mat-mdc-button-persistent-ripple::before, button[mat-icon-button].mat-mdc-button-base.rs-button-disabled .mat-mdc-button-persistent-ripple::before {
  background-color: transparent;
}

button[mat-button],
a[mat-button] {
  --mat-text-button-with-icon-horizontal-padding: var(--rs-btn--padding-x);
  --mdc-text-button-container-shape: var(--rs-btn--border-radius);
}
button[mat-button] .mat-mdc-button-persistent-ripple::before,
a[mat-button] .mat-mdc-button-persistent-ripple::before {
  background-color: transparent;
}

button.rs-button-disabled {
  pointer-events: none;
}

.rs-expansion-panel.mat-expansion-panel {
  box-shadow: none;
}
.rs-expansion-panel.mat-expansion-panel .mat-expansion-panel-header:not([aria-disabled=true]) {
  padding: 0 2px 0 0;
}
.rs-expansion-panel.mat-expansion-panel .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background-color: transparent;
}
.rs-expansion-panel.mat-expansion-panel .mat-expansion-panel-body {
  padding: 0 0 12px 12px;
}

@property --rs-card--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-card--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 30px;
}
@property --rs-card--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 30px;
}
@property --rs-card--margin-bottom {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-card--background-color--primary {
  syntax: "<color>";
  inherits: false;
  initial-value: white;
}
@property --rs-card-details--margin-bottom {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-card-details--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
/* Breakpoints */
@property --rs-container-breakpoint--lg {
  syntax: "<length>";
  inherits: false;
  initial-value: 992px;
}
@property --rs-ds--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-ds--border-radius--small {
  syntax: "<length>";
  inherits: true;
  initial-value: 4px;
}
@property --rs-ds--main-container--width {
  syntax: "<percentage>";
  inherits: true;
  initial-value: 100%;
}
@property --rs-ds--main-container-top-spacing {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-ds--main-container--padding-inline {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-ds--section--bottom-spacing {
  syntax: "<length>";
  inherits: true;
  initial-value: 40px;
}
@property --rs-ds--link--color {
  syntax: "<length>";
  inherits: true;
  initial-value: orange;
}
:root {
  --rs-ds--font--primary: "Montserrat";
  --rs-ds--font--primary-light: "Montserrat-light";
  --rs-ds--body-font-size: 0.875rem;
  --rs-ds--body-line-height: 1.375rem;
  --rs-ds--font-size--xs: 0.625rem;
  --rs-ds--font-size--xs-line-height: 1rem;
  --rs-ds--font-size--sm: 0.75rem;
  --rs-ds--font-size--sm-line-height: 1.25rem;
  --rs-ds--font-size--md: 0.875rem;
  --rs-ds--font-size--md-line-height: 1.375rem;
  --rs-ds--font-size--lg: 1rem;
  --rs-ds--font-size--lg-line-height: 1.5rem;
  --rs-ds--text-color: var(--rs-color--grey--700);
  --rs-ds--text-secondary: var(--rs-color--grey--500);
  --rs-ds--text-disabled: var(--rs-color--grey--300);
  --rs-ds--text-dark: var(--rs-color--grey--900);
  --rs-ds--border-color: var(--rs-color--grey--200);
  --rs-ds--color--primary: var(--rs-color--orange--300);
  --rs-ds--color--primary--hover: hsl(from var(--rs-ds--color--primary) h calc(s - 2) calc(l - 15));
  --rs-ds--color--hover: var(--rs-color--grey--100);
  --rs-ds--color--alert: var(--rs-color--red--400);
  --rs-ds--background-color: var(--rs-color--grey--50);
  --rs-ds--color--icon--grey: var(--rs-color--grey--700);
  --rs-ds--color--icon--disabled: var(--rs-color--grey--300);
  --rs-ds--color--icon--orange: var(--rs-color--orange--300);
  --rs-ds--link--color: var(--rs-ds--color--primary);
  --rs-ds--button-height: 2.5rem;
  --rs-transition--bezier: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --rs-ds--box--bottom-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.08);
  --rs-ds--box--top-shadow: 1px -6px 10px 0 rgba(98, 105, 115, 0.08);
  --rs-ds--main-container-top-spacing: 2.5rem;
  --rs-ds--main-container--padding-inline: 1rem;
  --rs-ds--section--bottom-spacing: 2.5rem;
}

/**
 * @param {*} $param
 * @param {String|*} $param-optional
 */
/* Fonts */
.rs-card:not([card-style]),
rs-card:not([card-style]) {
  --rs-card--border-radius: var(--rs-ds--border-radius);
  --rs-card--background-color--primary: var(--rs-color--white);
  --rs-card--box-shadow: var(--rs-ds--box--bottom-shadow);
  background-color: var(--rs-card--background-color--primary);
  border-radius: var(--rs-card--border-radius);
  padding: var(--rs-card--padding);
  box-shadow: var(--rs-card--box-shadow);
  margin-bottom: var(--rs-card--margin-bottom);
}
.rs-card[card-style=details],
rs-card[card-style=details] {
  --rs-card-details--font-size: 0.75rem;
  --rs-card-details--line-height: 1.25rem;
  --rs-card-details--padding: 1rem;
  --rs-card-details--margin-bottom: 1rem;
  background-color: var(--rs-ds--background-color);
  border-radius: var(--rs-ds--border-radius--small);
  padding: var(--rs-card-details--padding);
  margin-bottom: var(--rs-card-details--margin-bottom);
  font-size: var(--rs-card-details--font-size);
  line-height: var(--rs-card-details--line-height);
}

rs-card {
  display: block;
}

.rs-card:has(header),
rs-card:has(header) {
  --rs-card--padding: 1rem;
}
.rs-card header,
rs-card header {
  padding: 0.5rem 0.5rem 1.5rem;
  border-bottom: 1px solid var(--rs-ds--border-color);
}
.rs-card main,
rs-card main {
  padding: 1rem 0.5rem 0.5rem;
}

.rs-progress-bar {
  min-width: 50px;
  border-radius: 3px;
}
.rs-progress-bar.upload-successful .mdc-linear-progress__bar-inner {
  border-color: var(--rs-color--green--600) !important;
}
.rs-progress-bar.upload-error .mdc-linear-progress__bar-inner {
  border-color: var(--rs-ds--color--alert) !important;
}

:root {
  --mat-dialog-container-max-width: 1000px;
}

mat-dialog-container.mat-mdc-dialog-container {
  padding: 0;
  overflow: visible;
  position: relative;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-surface {
  padding: 33px 40px 40px 40px;
  --mdc-dialog-container-shape: var(--rs-ds--border-radius);
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-title {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  color: var(rs-color--grey--700);
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  padding-right: 24px;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-title::before {
  height: auto;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-title .mat-mdc-icon-button:not(:hover).cdk-program-focused .mat-mdc-button-persistent-ripple::before {
  --mat-icon-button-focus-state-layer-opacity: 0;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-title .mat-mdc-icon-button:hover.cdk-program-focused .mat-mdc-button-persistent-ripple::before {
  --mat-icon-button-focus-state-layer-opacity: 0.04;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-title button[mat-dialog-close] {
  color: var(--rs-color--grey--700);
  position: absolute;
  right: -17px;
  top: -11px;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-title button[mat-dialog-close] mat-icon {
  vertical-align: initial;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-title.overlay-close-button {
  position: absolute;
  width: 100%;
  border-bottom: none;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-content {
  margin: 0;
  padding: 0 0 4px 0;
  font-family: var(--rs-ds--font--primary-light) !important;
  font-weight: 400;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-content > :last-child {
  margin-bottom: 16px;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-actions {
  min-height: auto;
  margin-top: 27px;
  padding: 0;
  justify-content: flex-end;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-actions.center-action-buttons {
  justify-content: center;
}
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-actions .mat-button-base + .mat-button-base,
mat-dialog-container.mat-mdc-dialog-container .mat-mdc-dialog-actions .mat-mdc-button-base + .mat-mdc-button-base {
  margin-left: 16px;
}

.mdc-tooltip {
  --mdc-plain-tooltip-container-color: var(--rs-ds--text-secondary);
}
.mdc-tooltip .mdc-tooltip__surface {
  font-size: 0.75rem !important;
  line-height: 1.125rem !important;
  font-family: var(--rs-ds--font--primary-light) !important;
  font-weight: 400;
  white-space: pre-line;
}

.mat-mdc-snack-bar-container {
  max-width: 50vw !important;
  padding: 20px;
}
.mat-mdc-snack-bar-container.success {
  background: var(--rs-color--green--600);
  font-family: var(--rs-ds--font--primary-light) !important;
  font-weight: 400;
}
.mat-mdc-snack-bar-container.success .md-simple-snackbar-message {
  color: var(--rs-color--white);
}
.mat-mdc-snack-bar-container.error {
  background: var(--rs-ds--color--alert);
  font-family: var(--rs-ds--font--primary-light) !important;
  font-weight: 400;
}
.mat-mdc-snack-bar-container.error .md-simple-snackbar-message {
  color: var(--rs-color--white);
}

@property --rs-card--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-card--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 30px;
}
@property --rs-card--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 30px;
}
@property --rs-card--margin-bottom {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-card--background-color--primary {
  syntax: "<color>";
  inherits: false;
  initial-value: white;
}
@property --rs-card-details--margin-bottom {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-card-details--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
/* Breakpoints */
@property --rs-container-breakpoint--lg {
  syntax: "<length>";
  inherits: false;
  initial-value: 992px;
}
@property --rs-ds--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-ds--border-radius--small {
  syntax: "<length>";
  inherits: true;
  initial-value: 4px;
}
@property --rs-ds--main-container--width {
  syntax: "<percentage>";
  inherits: true;
  initial-value: 100%;
}
@property --rs-ds--main-container-top-spacing {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-ds--main-container--padding-inline {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-ds--section--bottom-spacing {
  syntax: "<length>";
  inherits: true;
  initial-value: 40px;
}
@property --rs-ds--link--color {
  syntax: "<length>";
  inherits: true;
  initial-value: orange;
}
:root {
  --rs-ds--font--primary: "Montserrat";
  --rs-ds--font--primary-light: "Montserrat-light";
  --rs-ds--body-font-size: 0.875rem;
  --rs-ds--body-line-height: 1.375rem;
  --rs-ds--font-size--xs: 0.625rem;
  --rs-ds--font-size--xs-line-height: 1rem;
  --rs-ds--font-size--sm: 0.75rem;
  --rs-ds--font-size--sm-line-height: 1.25rem;
  --rs-ds--font-size--md: 0.875rem;
  --rs-ds--font-size--md-line-height: 1.375rem;
  --rs-ds--font-size--lg: 1rem;
  --rs-ds--font-size--lg-line-height: 1.5rem;
  --rs-ds--text-color: var(--rs-color--grey--700);
  --rs-ds--text-secondary: var(--rs-color--grey--500);
  --rs-ds--text-disabled: var(--rs-color--grey--300);
  --rs-ds--text-dark: var(--rs-color--grey--900);
  --rs-ds--border-color: var(--rs-color--grey--200);
  --rs-ds--color--primary: var(--rs-color--orange--300);
  --rs-ds--color--primary--hover: hsl(from var(--rs-ds--color--primary) h calc(s - 2) calc(l - 15));
  --rs-ds--color--hover: var(--rs-color--grey--100);
  --rs-ds--color--alert: var(--rs-color--red--400);
  --rs-ds--background-color: var(--rs-color--grey--50);
  --rs-ds--color--icon--grey: var(--rs-color--grey--700);
  --rs-ds--color--icon--disabled: var(--rs-color--grey--300);
  --rs-ds--color--icon--orange: var(--rs-color--orange--300);
  --rs-ds--link--color: var(--rs-ds--color--primary);
  --rs-ds--button-height: 2.5rem;
  --rs-transition--bezier: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --rs-ds--box--bottom-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.08);
  --rs-ds--box--top-shadow: 1px -6px 10px 0 rgba(98, 105, 115, 0.08);
  --rs-ds--main-container-top-spacing: 2.5rem;
  --rs-ds--main-container--padding-inline: 1rem;
  --rs-ds--section--bottom-spacing: 2.5rem;
}

/**
 * @param {*} $param
 * @param {String|*} $param-optional
 */
/* Fonts */
.rs-chip--orange {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  font-size: 0.6875rem !important;
  line-height: 0.875rem !important;
  border-radius: 3px;
  border: 1px solid var(--rs-color--orange--100);
  display: inline-block;
  padding: 3px 8px;
  background-color: var(--rs-color--orange--50);
  color: var(--rs-ds--color--primary);
}
.rs-chip--orange[rs-chip-density="1"] {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  padding: 2px 8px;
}
.rs-chip--blue {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  font-size: 0.6875rem !important;
  line-height: 0.875rem !important;
  border-radius: 3px;
  border: 1px solid var(--rs-color--accent--200);
  display: inline-block;
  padding: 3px 8px;
  background-color: var(--rs-color--accent--50);
  color: var(--rs-color--accent--500);
}
.rs-chip--blue[rs-chip-density="1"] {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  padding: 2px 8px;
}
.rs-chip--green {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  font-size: 0.6875rem !important;
  line-height: 0.875rem !important;
  border-radius: 3px;
  border: 1px solid var(--rs-color--green--600);
  display: inline-block;
  padding: 3px 8px;
  background-color: var(--rs-color--green--50);
  color: var(--rs-color--green--800);
}
.rs-chip--green[rs-chip-density="1"] {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  padding: 2px 8px;
}
.rs-chip--red {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  font-size: 0.6875rem !important;
  line-height: 0.875rem !important;
  border-radius: 3px;
  border: 1px solid var(--rs-color--red--200);
  display: inline-block;
  padding: 3px 8px;
  background-color: var(--rs-color--red--50);
  color: var(--rs-color--red--700);
}
.rs-chip--red[rs-chip-density="1"] {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  padding: 2px 8px;
}
.rs-chip--grey {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  font-size: 0.6875rem !important;
  line-height: 0.875rem !important;
  border-radius: 3px;
  border: 1px solid var(--rs-color--grey--300);
  display: inline-block;
  padding: 3px 8px;
  background-color: var(--rs-color--grey--50);
  color: var(--rs-color--grey--500);
}
.rs-chip--grey[rs-chip-density="1"] {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  padding: 2px 8px;
}
.rs-chip--yellow {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  font-size: 0.6875rem !important;
  line-height: 0.875rem !important;
  border-radius: 3px;
  border: 1px solid var(--rs-color--yellow--600);
  display: inline-block;
  padding: 3px 8px;
  background-color: var(--rs-color--yellow--100);
  color: var(--rs-color--yellow--900);
}
.rs-chip--yellow[rs-chip-density="1"] {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  padding: 2px 8px;
}

rs-chip[rs-chip--color=orange] {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  font-size: 0.6875rem !important;
  line-height: 0.875rem !important;
  border-radius: 3px;
  border: 1px solid var(--rs-color--orange--100);
  display: inline-block;
  padding: 3px 8px;
  background-color: var(--rs-color--orange--50);
  color: var(--rs-ds--color--primary);
}
rs-chip[rs-chip--color=orange][rs-chip-density="1"] {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  padding: 2px 8px;
}
rs-chip[rs-chip--color=blue] {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  font-size: 0.6875rem !important;
  line-height: 0.875rem !important;
  border-radius: 3px;
  border: 1px solid var(--rs-color--accent--200);
  display: inline-block;
  padding: 3px 8px;
  background-color: var(--rs-color--accent--50);
  color: var(--rs-color--accent--500);
}
rs-chip[rs-chip--color=blue][rs-chip-density="1"] {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  padding: 2px 8px;
}
rs-chip[rs-chip--color=green] {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  font-size: 0.6875rem !important;
  line-height: 0.875rem !important;
  border-radius: 3px;
  border: 1px solid var(--rs-color--green--600);
  display: inline-block;
  padding: 3px 8px;
  background-color: var(--rs-color--green--50);
  color: var(--rs-color--green--800);
}
rs-chip[rs-chip--color=green][rs-chip-density="1"] {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  padding: 2px 8px;
}
rs-chip[rs-chip--color=red] {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  font-size: 0.6875rem !important;
  line-height: 0.875rem !important;
  border-radius: 3px;
  border: 1px solid var(--rs-color--red--200);
  display: inline-block;
  padding: 3px 8px;
  background-color: var(--rs-color--red--50);
  color: var(--rs-color--red--700);
}
rs-chip[rs-chip--color=red][rs-chip-density="1"] {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  padding: 2px 8px;
}
rs-chip[rs-chip--color=grey] {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  font-size: 0.6875rem !important;
  line-height: 0.875rem !important;
  border-radius: 3px;
  border: 1px solid var(--rs-color--grey--300);
  display: inline-block;
  padding: 3px 8px;
  background-color: var(--rs-color--grey--50);
  color: var(--rs-color--grey--500);
}
rs-chip[rs-chip--color=grey][rs-chip-density="1"] {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  padding: 2px 8px;
}
rs-chip[rs-chip--color=yellow] {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  font-size: 0.6875rem !important;
  line-height: 0.875rem !important;
  border-radius: 3px;
  border: 1px solid var(--rs-color--yellow--600);
  display: inline-block;
  padding: 3px 8px;
  background-color: var(--rs-color--yellow--100);
  color: var(--rs-color--yellow--900);
}
rs-chip[rs-chip--color=yellow][rs-chip-density="1"] {
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  padding: 2px 8px;
}

[rs-icon-outline],
.material-icons-outlined,
.material-icons.material-icons--outlined {
  font-family: "Material Icons Outlined";
}

[rs-icon-symbols-outline] {
  font-family: "Material Symbols Outlined";
}

.material-icons-two-tone,
.material-icons.material-icons--two-tone {
  font-family: "Material Icons Two Tone";
}

.material-icons-round,
.material-icons.material-icons--round {
  font-family: "Material Icons Round";
}

.material-icons-sharp,
.material-icons.material-icons--sharp {
  font-family: "Material Icons Sharp";
}

.mat-icon {
  vertical-align: middle;
}
.mat-icon.green {
  color: var(--rs-color--green--600);
}
.mat-icon.back-arrow {
  transform: rotate(180deg);
}
.mat-icon.yellow {
  color: var(--rs-color--yellow--600) !important;
}
.mat-icon.green {
  color: var(--rs-color--green--600) !important;
}
.mat-icon.red {
  color: var(--rs-ds--color--alert) !important;
}

.rs-nav-vertical a, .rs-nav-vertical--sticky-top a {
  display: table;
}
.rs-nav-vertical--sticky-top {
  position: sticky !important;
  top: 40px;
  align-self: flex-start;
}
.rs-nav a, .rs-nav-vertical a, .rs-nav-vertical--sticky-top a {
  cursor: pointer;
  color: var(--rs-ds--text-color);
  font-family: var(--rs-ds--font--primary-light) !important;
  font-weight: 400;
  font-size: 0.875rem !important;
  line-height: 1.125rem !important;
  margin-bottom: 20px;
}
.rs-nav a.selected, .rs-nav-vertical a.selected, .rs-nav-vertical--sticky-top a.selected, .rs-nav a:hover, .rs-nav-vertical a:hover, .rs-nav-vertical--sticky-top a:hover {
  color: var(--rs-ds--color--primary) !important;
}

table.white-box-table {
  table-layout: fixed;
  width: 100%;
}
table.white-box-table thead tr {
  border-bottom: 1px solid var(--rs-ds--border-color);
}
table.white-box-table thead tr th {
  height: 2rem;
  vertical-align: top;
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
}
table.white-box-table tbody tr:first-child {
  height: 3rem;
  vertical-align: bottom;
}
table.white-box-table tbody td {
  line-height: 2rem;
  font-family: var(--rs-ds--font--primary-light) !important;
  font-weight: 400;
}

rs-tag, rs-tag[color=orange] {
  display: block;
  position: relative;
  overflow: hidden;
  padding: 2px 8px;
  border-radius: 40px;
  text-transform: uppercase;
  color: var(--rs-color--white);
  letter-spacing: 1px;
  font-size: 0.75rem !important;
  line-height: 1.125rem !important;
}
rs-tag[color=orange] {
  background-image: linear-gradient(to right, #fdab5d, #fc8e5d);
}

@property --rs-mat-drawer--sticky-top-position {
  syntax: "<length>";
  inherits: true;
  initial-value: 0;
}
@property --rs-drawer-header--padding {
  syntax: "<length>+";
  inherits: true;
  initial-value: 20px;
}
:root {
  --mat-sidenav-container-shape: 0;
}

mat-drawer-container.mat-drawer-container,
.mat-sidenav-container {
  --mat-sidenav-content-background-color: transparent;
  --mat-sidenav-container-background-color: var(--rs-color--white);
  --mat-sidenav-container-width: 450px;
  overflow: visible !important;
}
mat-drawer-container.mat-drawer-container:has(.mat-drawer[mode=over]):has(.mat-drawer-shown), mat-drawer-container.mat-drawer-container:has(.mat-drawer[mode=over]).mat-drawer-container-has-open, mat-drawer-container.mat-drawer-container:has(.mat-drawer[ng-reflect-mode=over]):has(.mat-drawer-shown), mat-drawer-container.mat-drawer-container:has(.mat-drawer[ng-reflect-mode=over]).mat-drawer-container-has-open,
.mat-sidenav-container:has(.mat-drawer[mode=over]):has(.mat-drawer-shown),
.mat-sidenav-container:has(.mat-drawer[mode=over]).mat-drawer-container-has-open,
.mat-sidenav-container:has(.mat-drawer[ng-reflect-mode=over]):has(.mat-drawer-shown),
.mat-sidenav-container:has(.mat-drawer[ng-reflect-mode=over]).mat-drawer-container-has-open {
  z-index: 3;
}
mat-drawer-container.mat-drawer-container:has(.mat-drawer[mode=over]):has(.mat-drawer-shown) .mat-drawer,
mat-drawer-container.mat-drawer-container:has(.mat-drawer[mode=over]):has(.mat-drawer-shown) .mat-drawer-backdrop,
mat-drawer-container.mat-drawer-container:has(.mat-drawer[mode=over]):has(.mat-drawer-shown) .mat-sidenav,
mat-drawer-container.mat-drawer-container:has(.mat-drawer[mode=over]):has(.mat-drawer-shown) .mat-sidenav-backdrop, mat-drawer-container.mat-drawer-container:has(.mat-drawer[mode=over]).mat-drawer-container-has-open .mat-drawer,
mat-drawer-container.mat-drawer-container:has(.mat-drawer[mode=over]).mat-drawer-container-has-open .mat-drawer-backdrop,
mat-drawer-container.mat-drawer-container:has(.mat-drawer[mode=over]).mat-drawer-container-has-open .mat-sidenav,
mat-drawer-container.mat-drawer-container:has(.mat-drawer[mode=over]).mat-drawer-container-has-open .mat-sidenav-backdrop, mat-drawer-container.mat-drawer-container:has(.mat-drawer[ng-reflect-mode=over]):has(.mat-drawer-shown) .mat-drawer,
mat-drawer-container.mat-drawer-container:has(.mat-drawer[ng-reflect-mode=over]):has(.mat-drawer-shown) .mat-drawer-backdrop,
mat-drawer-container.mat-drawer-container:has(.mat-drawer[ng-reflect-mode=over]):has(.mat-drawer-shown) .mat-sidenav,
mat-drawer-container.mat-drawer-container:has(.mat-drawer[ng-reflect-mode=over]):has(.mat-drawer-shown) .mat-sidenav-backdrop, mat-drawer-container.mat-drawer-container:has(.mat-drawer[ng-reflect-mode=over]).mat-drawer-container-has-open .mat-drawer,
mat-drawer-container.mat-drawer-container:has(.mat-drawer[ng-reflect-mode=over]).mat-drawer-container-has-open .mat-drawer-backdrop,
mat-drawer-container.mat-drawer-container:has(.mat-drawer[ng-reflect-mode=over]).mat-drawer-container-has-open .mat-sidenav,
mat-drawer-container.mat-drawer-container:has(.mat-drawer[ng-reflect-mode=over]).mat-drawer-container-has-open .mat-sidenav-backdrop,
.mat-sidenav-container:has(.mat-drawer[mode=over]):has(.mat-drawer-shown) .mat-drawer,
.mat-sidenav-container:has(.mat-drawer[mode=over]):has(.mat-drawer-shown) .mat-drawer-backdrop,
.mat-sidenav-container:has(.mat-drawer[mode=over]):has(.mat-drawer-shown) .mat-sidenav,
.mat-sidenav-container:has(.mat-drawer[mode=over]):has(.mat-drawer-shown) .mat-sidenav-backdrop,
.mat-sidenav-container:has(.mat-drawer[mode=over]).mat-drawer-container-has-open .mat-drawer,
.mat-sidenav-container:has(.mat-drawer[mode=over]).mat-drawer-container-has-open .mat-drawer-backdrop,
.mat-sidenav-container:has(.mat-drawer[mode=over]).mat-drawer-container-has-open .mat-sidenav,
.mat-sidenav-container:has(.mat-drawer[mode=over]).mat-drawer-container-has-open .mat-sidenav-backdrop,
.mat-sidenav-container:has(.mat-drawer[ng-reflect-mode=over]):has(.mat-drawer-shown) .mat-drawer,
.mat-sidenav-container:has(.mat-drawer[ng-reflect-mode=over]):has(.mat-drawer-shown) .mat-drawer-backdrop,
.mat-sidenav-container:has(.mat-drawer[ng-reflect-mode=over]):has(.mat-drawer-shown) .mat-sidenav,
.mat-sidenav-container:has(.mat-drawer[ng-reflect-mode=over]):has(.mat-drawer-shown) .mat-sidenav-backdrop,
.mat-sidenav-container:has(.mat-drawer[ng-reflect-mode=over]).mat-drawer-container-has-open .mat-drawer,
.mat-sidenav-container:has(.mat-drawer[ng-reflect-mode=over]).mat-drawer-container-has-open .mat-drawer-backdrop,
.mat-sidenav-container:has(.mat-drawer[ng-reflect-mode=over]).mat-drawer-container-has-open .mat-sidenav,
.mat-sidenav-container:has(.mat-drawer[ng-reflect-mode=over]).mat-drawer-container-has-open .mat-sidenav-backdrop {
  position: fixed;
}
mat-drawer-container.mat-drawer-container:has(.mat-drawer[mode=side]) .mat-drawer,
.mat-sidenav-container:has(.mat-drawer[mode=side]) .mat-drawer {
  margin-bottom: calc(-80px);
}
mat-drawer-container.mat-drawer-container:has(.mat-drawer[mode=side]) .mat-drawer-content,
.mat-sidenav-container:has(.mat-drawer[mode=side]) .mat-drawer-content {
  min-height: calc(100vh - 336px);
}
mat-drawer-container.mat-drawer-container:has(.mat-drawer[mode=side]) .mat-drawer-inner-container,
.mat-sidenav-container:has(.mat-drawer[mode=side]) .mat-drawer-inner-container {
  border-top: 1px solid var(--rs-ds--border-color);
  border-bottom: 1px solid var(--rs-ds--border-color);
}
mat-drawer-container.mat-drawer-container .mat-drawer .rs-drawer-header,
mat-drawer-container.mat-drawer-container .mat-drawer rs-drawer-header,
mat-drawer-container.mat-drawer-container .mat-drawer .rs-sidenav-header,
mat-drawer-container.mat-drawer-container .mat-sidenav .rs-drawer-header,
mat-drawer-container.mat-drawer-container .mat-sidenav rs-drawer-header,
mat-drawer-container.mat-drawer-container .mat-sidenav .rs-sidenav-header,
.mat-sidenav-container .mat-drawer .rs-drawer-header,
.mat-sidenav-container .mat-drawer rs-drawer-header,
.mat-sidenav-container .mat-drawer .rs-sidenav-header,
.mat-sidenav-container .mat-sidenav .rs-drawer-header,
.mat-sidenav-container .mat-sidenav rs-drawer-header,
.mat-sidenav-container .mat-sidenav .rs-sidenav-header {
  position: relative;
  text-align: center;
  padding: var(--rs-drawer-header--padding);
  border-bottom: 1px solid var(--rs-ds--border-color);
  margin-bottom: 20px;
}
mat-drawer-container.mat-drawer-container .mat-drawer .rs-drawer-header button,
mat-drawer-container.mat-drawer-container .mat-drawer rs-drawer-header button,
mat-drawer-container.mat-drawer-container .mat-drawer .rs-sidenav-header button,
mat-drawer-container.mat-drawer-container .mat-sidenav .rs-drawer-header button,
mat-drawer-container.mat-drawer-container .mat-sidenav rs-drawer-header button,
mat-drawer-container.mat-drawer-container .mat-sidenav .rs-sidenav-header button,
.mat-sidenav-container .mat-drawer .rs-drawer-header button,
.mat-sidenav-container .mat-drawer rs-drawer-header button,
.mat-sidenav-container .mat-drawer .rs-sidenav-header button,
.mat-sidenav-container .mat-sidenav .rs-drawer-header button,
.mat-sidenav-container .mat-sidenav rs-drawer-header button,
.mat-sidenav-container .mat-sidenav .rs-sidenav-header button {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
}
mat-drawer-container.mat-drawer-container .mat-drawer .rs-drawer-header .rs-drawer-title,
mat-drawer-container.mat-drawer-container .mat-drawer .rs-drawer-header .rs-sidenav-title,
mat-drawer-container.mat-drawer-container .mat-drawer rs-drawer-header .rs-drawer-title,
mat-drawer-container.mat-drawer-container .mat-drawer rs-drawer-header .rs-sidenav-title,
mat-drawer-container.mat-drawer-container .mat-drawer .rs-sidenav-header .rs-drawer-title,
mat-drawer-container.mat-drawer-container .mat-drawer .rs-sidenav-header .rs-sidenav-title,
mat-drawer-container.mat-drawer-container .mat-sidenav .rs-drawer-header .rs-drawer-title,
mat-drawer-container.mat-drawer-container .mat-sidenav .rs-drawer-header .rs-sidenav-title,
mat-drawer-container.mat-drawer-container .mat-sidenav rs-drawer-header .rs-drawer-title,
mat-drawer-container.mat-drawer-container .mat-sidenav rs-drawer-header .rs-sidenav-title,
mat-drawer-container.mat-drawer-container .mat-sidenav .rs-sidenav-header .rs-drawer-title,
mat-drawer-container.mat-drawer-container .mat-sidenav .rs-sidenav-header .rs-sidenav-title,
.mat-sidenav-container .mat-drawer .rs-drawer-header .rs-drawer-title,
.mat-sidenav-container .mat-drawer .rs-drawer-header .rs-sidenav-title,
.mat-sidenav-container .mat-drawer rs-drawer-header .rs-drawer-title,
.mat-sidenav-container .mat-drawer rs-drawer-header .rs-sidenav-title,
.mat-sidenav-container .mat-drawer .rs-sidenav-header .rs-drawer-title,
.mat-sidenav-container .mat-drawer .rs-sidenav-header .rs-sidenav-title,
.mat-sidenav-container .mat-sidenav .rs-drawer-header .rs-drawer-title,
.mat-sidenav-container .mat-sidenav .rs-drawer-header .rs-sidenav-title,
.mat-sidenav-container .mat-sidenav rs-drawer-header .rs-drawer-title,
.mat-sidenav-container .mat-sidenav rs-drawer-header .rs-sidenav-title,
.mat-sidenav-container .mat-sidenav .rs-sidenav-header .rs-drawer-title,
.mat-sidenav-container .mat-sidenav .rs-sidenav-header .rs-sidenav-title {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  color: var(--rs-ds--color--primary);
  font-size: 15px;
  line-height: 19px;
  margin: 0;
  padding: 0;
}
mat-drawer-container.mat-drawer-container .mat-drawer .rs-drawer-content,
mat-drawer-container.mat-drawer-container .mat-drawer .rs-sidenav-content,
mat-drawer-container.mat-drawer-container .mat-sidenav .rs-drawer-content,
mat-drawer-container.mat-drawer-container .mat-sidenav .rs-sidenav-content,
.mat-sidenav-container .mat-drawer .rs-drawer-content,
.mat-sidenav-container .mat-drawer .rs-sidenav-content,
.mat-sidenav-container .mat-sidenav .rs-drawer-content,
.mat-sidenav-container .mat-sidenav .rs-sidenav-content {
  margin: 0 40px;
}
mat-drawer-container.mat-drawer-container .mat-drawer .rs-drawer-content .rs-section-title,
mat-drawer-container.mat-drawer-container .mat-drawer .rs-sidenav-content .rs-section-title,
mat-drawer-container.mat-drawer-container .mat-sidenav .rs-drawer-content .rs-section-title,
mat-drawer-container.mat-drawer-container .mat-sidenav .rs-sidenav-content .rs-section-title,
.mat-sidenav-container .mat-drawer .rs-drawer-content .rs-section-title,
.mat-sidenav-container .mat-drawer .rs-sidenav-content .rs-section-title,
.mat-sidenav-container .mat-sidenav .rs-drawer-content .rs-section-title,
.mat-sidenav-container .mat-sidenav .rs-sidenav-content .rs-section-title {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
mat-drawer-container.mat-drawer-container .mat-drawer mat-expansion-panel,
mat-drawer-container.mat-drawer-container .mat-drawer .mat-expansion-panel,
mat-drawer-container.mat-drawer-container .mat-sidenav mat-expansion-panel,
mat-drawer-container.mat-drawer-container .mat-sidenav .mat-expansion-panel,
.mat-sidenav-container .mat-drawer mat-expansion-panel,
.mat-sidenav-container .mat-drawer .mat-expansion-panel,
.mat-sidenav-container .mat-sidenav mat-expansion-panel,
.mat-sidenav-container .mat-sidenav .mat-expansion-panel {
  box-shadow: none;
}
mat-drawer-container.mat-drawer-container .mat-drawer mat-expansion-panel .mat-expansion-panel-header,
mat-drawer-container.mat-drawer-container .mat-drawer .mat-expansion-panel .mat-expansion-panel-header,
mat-drawer-container.mat-drawer-container .mat-sidenav mat-expansion-panel .mat-expansion-panel-header,
mat-drawer-container.mat-drawer-container .mat-sidenav .mat-expansion-panel .mat-expansion-panel-header,
.mat-sidenav-container .mat-drawer mat-expansion-panel .mat-expansion-panel-header,
.mat-sidenav-container .mat-drawer .mat-expansion-panel .mat-expansion-panel-header,
.mat-sidenav-container .mat-sidenav mat-expansion-panel .mat-expansion-panel-header,
.mat-sidenav-container .mat-sidenav .mat-expansion-panel .mat-expansion-panel-header {
  height: 48px !important;
  padding-block: 0;
  padding-inline: 2px;
  box-shadow: none;
}
mat-drawer-container.mat-drawer-container .mat-drawer mat-expansion-panel .mat-expansion-panel-header:hover,
mat-drawer-container.mat-drawer-container .mat-drawer .mat-expansion-panel .mat-expansion-panel-header:hover,
mat-drawer-container.mat-drawer-container .mat-sidenav mat-expansion-panel .mat-expansion-panel-header:hover,
mat-drawer-container.mat-drawer-container .mat-sidenav .mat-expansion-panel .mat-expansion-panel-header:hover,
.mat-sidenav-container .mat-drawer mat-expansion-panel .mat-expansion-panel-header:hover,
.mat-sidenav-container .mat-drawer .mat-expansion-panel .mat-expansion-panel-header:hover,
.mat-sidenav-container .mat-sidenav mat-expansion-panel .mat-expansion-panel-header:hover,
.mat-sidenav-container .mat-sidenav .mat-expansion-panel .mat-expansion-panel-header:hover {
  background: none !important;
}
mat-drawer-container.mat-drawer-container .mat-drawer mat-expansion-panel .mat-expansion-panel-body,
mat-drawer-container.mat-drawer-container .mat-drawer .mat-expansion-panel .mat-expansion-panel-body,
mat-drawer-container.mat-drawer-container .mat-sidenav mat-expansion-panel .mat-expansion-panel-body,
mat-drawer-container.mat-drawer-container .mat-sidenav .mat-expansion-panel .mat-expansion-panel-body,
.mat-sidenav-container .mat-drawer mat-expansion-panel .mat-expansion-panel-body,
.mat-sidenav-container .mat-drawer .mat-expansion-panel .mat-expansion-panel-body,
.mat-sidenav-container .mat-sidenav mat-expansion-panel .mat-expansion-panel-body,
.mat-sidenav-container .mat-sidenav .mat-expansion-panel .mat-expansion-panel-body {
  padding: 0 0 12px 12px;
  background-color: transparent;
}
mat-drawer-container.mat-drawer-container .mat-drawer mat-expansion-panel.rs-expansion-panel-pre-separator:before,
mat-drawer-container.mat-drawer-container .mat-drawer .mat-expansion-panel.rs-expansion-panel-pre-separator:before,
mat-drawer-container.mat-drawer-container .mat-sidenav mat-expansion-panel.rs-expansion-panel-pre-separator:before,
mat-drawer-container.mat-drawer-container .mat-sidenav .mat-expansion-panel.rs-expansion-panel-pre-separator:before,
.mat-sidenav-container .mat-drawer mat-expansion-panel.rs-expansion-panel-pre-separator:before,
.mat-sidenav-container .mat-drawer .mat-expansion-panel.rs-expansion-panel-pre-separator:before,
.mat-sidenav-container .mat-sidenav mat-expansion-panel.rs-expansion-panel-pre-separator:before,
.mat-sidenav-container .mat-sidenav .mat-expansion-panel.rs-expansion-panel-pre-separator:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--rs-color--grey--50);
  margin: 8px 0 8px;
  display: block;
}
mat-drawer-container.mat-drawer-container .mat-drawer-content,
.mat-sidenav-container .mat-drawer-content {
  overflow: visible;
}

/* [rs-sticky-drawer] => Makes mat-drawer/sidenav content sticky */
mat-drawer-container.mat-drawer-container .mat-drawer[rs-sticky-drawer] .mat-drawer-inner-container,
mat-drawer-container.mat-drawer-container .mat-sidenav[rs-sticky-drawer] .mat-drawer-inner-container,
.mat-sidenav-container .mat-drawer[rs-sticky-drawer] .mat-drawer-inner-container,
.mat-sidenav-container .mat-sidenav[rs-sticky-drawer] .mat-drawer-inner-container {
  position: sticky;
  height: fit-content;
  top: var(--rs-mat-drawer--sticky-top-position);
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary {
  width: calc(100% - 36px) !important;
}
.mat-mdc-standard-chip .mdc-evolution-chip__cell {
  overflow: hidden;
}
.mat-mdc-standard-chip .mdc-evolution-chip__cell .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__cell .mat-mdc-chip-action-label {
  overflow: hidden;
}

form[rs-mode=error-relative] .mat-mdc-form-field-subscript-wrapper::before {
  display: none;
}
form[rs-mode=error-relative] .mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-error-wrapper {
  position: relative;
  padding-right: 8px;
}

:root {
  --mat-datepicker-calendar-container-shape: var(--rs-ds--border-radius);
}
:root {
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.rs-datepicker.mat-mdc-form-field:not(.mat-form-field-disabled) {
  cursor: pointer;
}
.rs-datepicker.mat-mdc-form-field:not(.mat-form-field-disabled) label {
  cursor: pointer !important;
}
.rs-datepicker.mat-mdc-form-field:not(.mat-form-field-disabled) input {
  pointer-events: none;
}
.rs-datepicker[appearance=outline] .mat-mdc-form-field-flex {
  height: 100%;
}
.rs-datepicker:not([appearance=outline]) {
  transform: translateY(0);
  transition: 0.1s transform ease-out;
}
.rs-datepicker:not([appearance=outline]).mat-form-field-hide-placeholder .mat-datepicker-toggle {
  transform: translateY(8px);
  transition: 0.2s transform ease-out;
  transition-delay: 0.2s;
}
.rs-datepicker .mat-mdc-form-field-icon-suffix {
  display: flex;
}
.rs-datepicker .mat-datepicker-toggle .mat-mdc-icon-button {
  height: 30px;
  width: 30px;
  padding: 8px 0;
}
.rs-datepicker .mat-datepicker-toggle .mat-mdc-icon-button svg {
  height: 18px;
  width: 18px;
  transform: translateY(-1px);
}

:root {
  --mdc-radio-selected-focus-icon-color: var(--rs-ds--color--primary--hover);
  --mdc-radio-selected-hover-icon-color: var(--rs-ds--color--primary--hover);
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  display: none;
}

.mat-mdc-radio-button:hover:not(:active) {
  --mdc-radio-selected-icon-color: var(--rs-ds--color--primary--hover);
  --mat-radio-label-text-color: black;
  cursor: pointer;
}
.mat-mdc-radio-button:hover:not(:active) .mdc-label:hover {
  cursor: pointer;
}
.mat-mdc-radio-button:hover:not(:active):has(input[type=radio]:not(:checked)) .mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--rs-color--grey--500) !important;
}

@property --mat-form-field-height {
  syntax: "<length>";
  inherits: true;
  initial-value: 50px;
}
@property --mat-form-field-no-label-height {
  syntax: "<length>";
  inherits: true;
  initial-value: 42px;
}
.mat-mdc-form-field:not([appearance=outline]):not([class*=col-]) {
  width: 100%;
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-form-field-text-prefix {
  font-family: var(--rs-ds--font--primary-light);
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-input-element {
  font-family: var(--rs-ds--font--primary-light) !important;
  font-weight: 400;
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-text-field-wrapper {
  background-color: transparent;
  height: var(--mat-form-field-height);
  padding-left: 4px;
  padding-right: 4px;
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-text-field-wrapper.mdc-text-field--no-label {
  height: var(--mat-form-field-no-label-height);
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-text-field-wrapper .mdc-floating-label--float-above {
  transform: translateY(-106%) !important;
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-text-field-wrapper .mdc-floating-label {
  transform: translateY(-4px);
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-text-field-wrapper mat-label {
  font-family: var(--rs-ds--font--primary-light) !important;
  font-weight: 400;
  font-size: 14px !important;
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix > .mat-icon {
  font-size: 24px;
  line-height: 24px;
  height: 24px;
  width: 24px;
  transition: 0.4s transform var(--rs-transition--bezier);
  transform: translateY(5px);
  padding: 0;
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix {
  padding-left: 0;
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix:nth-of-type(2) {
  padding: 0 0 0 4px;
}

/** Errors  */
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align .mat-mdc-form-field-error-wrapper {
  padding-left: 0;
}

/** Mat hint */
.mat-mdc-form-field:not([appearance=outline]) {
  /* Make it multiline & responsive */
}
.mat-mdc-form-field:not([appearance=outline]).mat-form-field-disabled .mat-mdc-form-field-hint {
  opacity: 0.38;
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-form-field-hint {
  margin: 2px 0;
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-form-field-subscript-wrapper:has(.mat-mdc-form-field-hint)::before {
  display: none;
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-hint-wrapper {
  padding-left: 4px;
  position: relative;
}
.mat-mdc-form-field:not([appearance=outline]) .mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-hint-wrapper .mat-mdc-form-field-bottom-align::before {
  display: none;
}

/** READ ONLY*/
.mat-mdc-form-field:has(input[readonly=true]).mat-form-field-hide-placeholder input {
  display: none;
}
.mat-mdc-form-field:has(input[readonly=true]) .mdc-line-ripple--active::after {
  transform: scale(0) !important;
}

/** mat-auto-complete */
.mat-mdc-form-field:has(mat-autocomplete) .mat-mdc-form-field-icon-suffix mat-icon {
  padding: 0;
  width: 20px;
}

/** When mat-form-field is filled in */
.mat-mdc-form-field:not([appearance=outline]):not(.mat-form-field-hide-placeholder) .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix .mat-icon {
  transform: translateY(0);
  transition: 0.4s transform var(--rs-transition--bezier);
}

:root {
  --mat-option-selected-state-layer-color: var(--rs-color--orange--50);
  --mat-option-selected-state-label-text-color: var(--rs-ds--text-color);
}

div.mat-mdc-select-panel {
  padding: 0 !important;
}

mat-form-field[appearance=outline] {
  --mat-form-field-container-height: 50px;
  --mat-form-field-container-vertical-padding: 13px;
  --mat-mdc-form-field-floating-label-scale: 1;
  width: 100%;
  margin-bottom: 10px;
}
mat-form-field[appearance=outline] .mat-mdc-text-field-wrapper {
  padding-right: 16px;
}
mat-form-field[appearance=outline] .mat-mdc-form-field-flex {
  overflow: hidden;
}

/* Utilities */
mat-form-field[appearance=outline]:has(mat-select)[rs-mat-form-field--density="0"] {
  --mat-form-field-container-text-size: 12px;
  --mat-select-trigger-text-size: 12px;
  --mat-form-field-container-height: 24px;
  --mat-form-field-container-vertical-padding: 3px;
  --rs-clear-form-field-btn--button-size: 24px;
  --rs-clear-form-field-btn--icon-size: 16px;
}

mat-form-field[appearance=outline]:has(mat-select) {
  background-color: var(--rs-mat-form-field--bg-color);
  border-radius: var(--mdc-outlined-text-field-container-shape);
}
mat-form-field[appearance=outline]:has(mat-select)[rs-mat-form-field--color] {
  --mat-select-trigger-text-font: var(--rs-ds--font--primary);
}
mat-form-field[appearance=outline]:has(mat-select)[rs-mat-form-field--color=orange] {
  --mdc-outlined-text-field-outline-color: var(--rs-color--orange--100);
  --mdc-outlined-text-field-focus-outline-color: var(--rs-ds--color--primary);
  --mdc-outlined-text-field-hover-outline-color: var(--rs-ds--color--primary);
  --rs-mat-form-field--bg-color: var(--rs-color--orange--50);
  --rs-mat-form-field--color: var(--rs-ds--color--primary);
  --mat-select-enabled-trigger-text-color: var(--rs-ds--color--primary);
  --mat-select-enabled-arrow-color: var(--rs-ds--color--primary);
  --mat-select-focused-arrow-color: var(--rs-ds--color--primary);
}
mat-form-field[appearance=outline]:has(mat-select)[rs-mat-form-field--color=blue] {
  --mdc-outlined-text-field-outline-color: var(--rs-color--accent--200);
  --mdc-outlined-text-field-focus-outline-color: var(--rs-color--accent--500);
  --mdc-outlined-text-field-hover-outline-color: var(--rs-color--accent--500);
  --rs-mat-form-field--bg-color: var(--rs-color--accent--50);
  --rs-mat-form-field--color: var(--rs-color--accent--500);
  --mat-select-enabled-trigger-text-color: var(--rs-color--accent--500);
  --mat-select-enabled-arrow-color: var(--rs-color--accent--500);
  --mat-select-focused-arrow-color: var(--rs-color--accent--500);
}
mat-form-field[appearance=outline]:has(mat-select)[rs-mat-form-field--color=green] {
  --mdc-outlined-text-field-outline-color: var(--rs-color--green--600);
  --mdc-outlined-text-field-focus-outline-color: var(--rs-color--green--800);
  --mdc-outlined-text-field-hover-outline-color: var(--rs-color--green--800);
  --rs-mat-form-field--bg-color: var(--rs-color--green--50);
  --rs-mat-form-field--color: var(--rs-color--green--800);
  --mat-select-enabled-trigger-text-color: var(--rs-color--green--800);
  --mat-select-enabled-arrow-color: var(--rs-color--green--800);
  --mat-select-focused-arrow-color: var(--rs-color--green--800);
}
mat-form-field[appearance=outline]:has(mat-select)[rs-mat-form-field--color=red] {
  --mdc-outlined-text-field-outline-color: var(--rs-color--red--200);
  --mdc-outlined-text-field-focus-outline-color: var(--rs-color--red--700);
  --mdc-outlined-text-field-hover-outline-color: var(--rs-color--red--700);
  --rs-mat-form-field--bg-color: var(--rs-color--red--50);
  --rs-mat-form-field--color: var(--rs-color--red--700);
  --mat-select-enabled-trigger-text-color: var(--rs-color--red--700);
  --mat-select-enabled-arrow-color: var(--rs-color--red--700);
  --mat-select-focused-arrow-color: var(--rs-color--red--700);
}
mat-form-field[appearance=outline]:has(mat-select)[rs-mat-form-field--color=grey] {
  --mdc-outlined-text-field-outline-color: var(--rs-color--grey--300);
  --mdc-outlined-text-field-focus-outline-color: var(--rs-color--grey--500);
  --mdc-outlined-text-field-hover-outline-color: var(--rs-color--grey--500);
  --rs-mat-form-field--bg-color: var(--rs-color--grey--50);
  --rs-mat-form-field--color: var(--rs-color--grey--500);
  --mat-select-enabled-trigger-text-color: var(--rs-color--grey--500);
  --mat-select-enabled-arrow-color: var(--rs-color--grey--500);
  --mat-select-focused-arrow-color: var(--rs-color--grey--500);
}
mat-form-field[appearance=outline]:has(mat-select)[rs-mat-form-field--color=yellow] {
  --mdc-outlined-text-field-outline-color: var(--rs-color--yellow--600);
  --mdc-outlined-text-field-focus-outline-color: var(--rs-color--yellow--900);
  --mdc-outlined-text-field-hover-outline-color: var(--rs-color--yellow--900);
  --rs-mat-form-field--bg-color: var(--rs-color--yellow--100);
  --rs-mat-form-field--color: var(--rs-color--yellow--900);
  --mat-select-enabled-trigger-text-color: var(--rs-color--yellow--900);
  --mat-select-enabled-arrow-color: var(--rs-color--yellow--900);
  --mat-select-focused-arrow-color: var(--rs-color--yellow--900);
}

/* Breakpoints */
@property --rs-container-breakpoint--lg {
  syntax: "<length>";
  inherits: false;
  initial-value: 992px;
}
@property --rs-ds--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-ds--border-radius--small {
  syntax: "<length>";
  inherits: true;
  initial-value: 4px;
}
@property --rs-ds--main-container--width {
  syntax: "<percentage>";
  inherits: true;
  initial-value: 100%;
}
@property --rs-ds--main-container-top-spacing {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-ds--main-container--padding-inline {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-ds--section--bottom-spacing {
  syntax: "<length>";
  inherits: true;
  initial-value: 40px;
}
@property --rs-ds--link--color {
  syntax: "<length>";
  inherits: true;
  initial-value: orange;
}
:root {
  --rs-ds--font--primary: "Montserrat";
  --rs-ds--font--primary-light: "Montserrat-light";
  --rs-ds--body-font-size: 0.875rem;
  --rs-ds--body-line-height: 1.375rem;
  --rs-ds--font-size--xs: 0.625rem;
  --rs-ds--font-size--xs-line-height: 1rem;
  --rs-ds--font-size--sm: 0.75rem;
  --rs-ds--font-size--sm-line-height: 1.25rem;
  --rs-ds--font-size--md: 0.875rem;
  --rs-ds--font-size--md-line-height: 1.375rem;
  --rs-ds--font-size--lg: 1rem;
  --rs-ds--font-size--lg-line-height: 1.5rem;
  --rs-ds--text-color: var(--rs-color--grey--700);
  --rs-ds--text-secondary: var(--rs-color--grey--500);
  --rs-ds--text-disabled: var(--rs-color--grey--300);
  --rs-ds--text-dark: var(--rs-color--grey--900);
  --rs-ds--border-color: var(--rs-color--grey--200);
  --rs-ds--color--primary: var(--rs-color--orange--300);
  --rs-ds--color--primary--hover: hsl(from var(--rs-ds--color--primary) h calc(s - 2) calc(l - 15));
  --rs-ds--color--hover: var(--rs-color--grey--100);
  --rs-ds--color--alert: var(--rs-color--red--400);
  --rs-ds--background-color: var(--rs-color--grey--50);
  --rs-ds--color--icon--grey: var(--rs-color--grey--700);
  --rs-ds--color--icon--disabled: var(--rs-color--grey--300);
  --rs-ds--color--icon--orange: var(--rs-color--orange--300);
  --rs-ds--link--color: var(--rs-ds--color--primary);
  --rs-ds--button-height: 2.5rem;
  --rs-transition--bezier: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --rs-ds--box--bottom-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.08);
  --rs-ds--box--top-shadow: 1px -6px 10px 0 rgba(98, 105, 115, 0.08);
  --rs-ds--main-container-top-spacing: 2.5rem;
  --rs-ds--main-container--padding-inline: 1rem;
  --rs-ds--section--bottom-spacing: 2.5rem;
}

:root {
  --mdc-checkbox-selected-focus-icon-color: var(--rs-ds--color--primary--hover);
  --mdc-checkbox-selected-hover-icon-color: var(--rs-ds--color--primary--hover);
}

.mat-mdc-checkbox .mdc-checkbox__ripple {
  display: none !important;
}
.mat-mdc-checkbox.rs-mat-checkbox-align-items-start.mat-accent .mdc-form-field {
  align-items: flex-start;
}
.mat-mdc-checkbox.rs-mat-checkbox-align-items-start.mat-accent .mdc-form-field .mdc-checkbox {
  top: 0;
}

.mat-mdc-checkbox:hover:not(:active) {
  --mat-checkbox-label-text-color: black;
  --mdc-checkbox-selected-icon-color: var(--rs-ds--color--primary--hover);
}
.mat-mdc-checkbox:hover:not(:active):has(input[type=checkbox]:not(:checked)) {
  --mdc-checkbox-unselected-icon-color: var(--rs-color--grey--500);
}
.mat-mdc-checkbox:hover:not(:active):has(input[type=checkbox]:not(:checked)) .mdc-checkbox__background {
  border-color: var(--rs-color--grey--500) !important;
}

.mat-mdc-form-field.mat-mdc-form-field-type-mat-chip-grid .mat-mdc-text-field-wrapper {
  height: auto !important;
}
.mat-mdc-form-field.mat-mdc-form-field-type-mat-chip-grid .mat-mdc-autocomplete-trigger {
  margin-left: 0;
}

.mat-mdc-text-field-wrapper:has(textarea) {
  height: auto !important;
}
.mat-mdc-text-field-wrapper:has(textarea).mdc-text-field--no-label {
  height: auto !important;
}

:root {
  --mdc-switch-selected-focus-handle-color: var(--rs-color--white);
}
:root {
  --mdc-switch-selected-hover-handle-color: var(--rs-color--white);
}
:root {
  --mdc-switch-selected-hover-track-color: var(--rs-ds--color--primary--hover);
}
:root {
  --mdc-switch-selected-focus-track-color: var(--rs-ds--color--primary--hover);
}
:root {
  --mdc-switch-unselected-hover-handle-color: var(--rs-color--grey--700);
}
:root {
  --mdc-switch-unselected-focus-handle-color: var(--rs-color--grey--700);
}
:root {
  --mdc-switch-track-height: 24px;
}
:root {
  --mat-switch-with-icon-handle-size: 16px;
}
:root {
  --mat-switch-pressed-handle-size: 18px;
}
:root {
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0 3px;
}
:root {
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 4px;
}
:root {
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 19px;
}
:root {
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 18px;
}
:root {
  --mdc-switch-track-width: 38px;
}

mat-slide-toggle.mat-mdc-slide-toggle {
  --mdc-form-field-label-text-line-height: 22px;
}
mat-slide-toggle.mat-mdc-slide-toggle .mdc-switch__ripple {
  display: none !important;
}

/* Breakpoints */
@property --rs-container-breakpoint--lg {
  syntax: "<length>";
  inherits: false;
  initial-value: 992px;
}
@property --rs-ds--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-ds--border-radius--small {
  syntax: "<length>";
  inherits: true;
  initial-value: 4px;
}
@property --rs-ds--main-container--width {
  syntax: "<percentage>";
  inherits: true;
  initial-value: 100%;
}
@property --rs-ds--main-container-top-spacing {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-ds--main-container--padding-inline {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-ds--section--bottom-spacing {
  syntax: "<length>";
  inherits: true;
  initial-value: 40px;
}
@property --rs-ds--link--color {
  syntax: "<length>";
  inherits: true;
  initial-value: orange;
}
:root {
  --rs-ds--font--primary: "Montserrat";
  --rs-ds--font--primary-light: "Montserrat-light";
  --rs-ds--body-font-size: 0.875rem;
  --rs-ds--body-line-height: 1.375rem;
  --rs-ds--font-size--xs: 0.625rem;
  --rs-ds--font-size--xs-line-height: 1rem;
  --rs-ds--font-size--sm: 0.75rem;
  --rs-ds--font-size--sm-line-height: 1.25rem;
  --rs-ds--font-size--md: 0.875rem;
  --rs-ds--font-size--md-line-height: 1.375rem;
  --rs-ds--font-size--lg: 1rem;
  --rs-ds--font-size--lg-line-height: 1.5rem;
  --rs-ds--text-color: var(--rs-color--grey--700);
  --rs-ds--text-secondary: var(--rs-color--grey--500);
  --rs-ds--text-disabled: var(--rs-color--grey--300);
  --rs-ds--text-dark: var(--rs-color--grey--900);
  --rs-ds--border-color: var(--rs-color--grey--200);
  --rs-ds--color--primary: var(--rs-color--orange--300);
  --rs-ds--color--primary--hover: hsl(from var(--rs-ds--color--primary) h calc(s - 2) calc(l - 15));
  --rs-ds--color--hover: var(--rs-color--grey--100);
  --rs-ds--color--alert: var(--rs-color--red--400);
  --rs-ds--background-color: var(--rs-color--grey--50);
  --rs-ds--color--icon--grey: var(--rs-color--grey--700);
  --rs-ds--color--icon--disabled: var(--rs-color--grey--300);
  --rs-ds--color--icon--orange: var(--rs-color--orange--300);
  --rs-ds--link--color: var(--rs-ds--color--primary);
  --rs-ds--button-height: 2.5rem;
  --rs-transition--bezier: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --rs-ds--box--bottom-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.08);
  --rs-ds--box--top-shadow: 1px -6px 10px 0 rgba(98, 105, 115, 0.08);
  --rs-ds--main-container-top-spacing: 2.5rem;
  --rs-ds--main-container--padding-inline: 1rem;
  --rs-ds--section--bottom-spacing: 2.5rem;
}

@property --rs-card--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-card--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 30px;
}
@property --rs-card--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 30px;
}
@property --rs-card--margin-bottom {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-card--background-color--primary {
  syntax: "<color>";
  inherits: false;
  initial-value: white;
}
@property --rs-card-details--margin-bottom {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-card-details--padding {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
/* Breakpoints */
@property --rs-container-breakpoint--lg {
  syntax: "<length>";
  inherits: false;
  initial-value: 992px;
}
@property --rs-ds--border-radius {
  syntax: "<length>";
  inherits: true;
  initial-value: 12px;
}
@property --rs-ds--border-radius--small {
  syntax: "<length>";
  inherits: true;
  initial-value: 4px;
}
@property --rs-ds--main-container--width {
  syntax: "<percentage>";
  inherits: true;
  initial-value: 100%;
}
@property --rs-ds--main-container-top-spacing {
  syntax: "<length>";
  inherits: false;
  initial-value: 40px;
}
@property --rs-ds--main-container--padding-inline {
  syntax: "<length>";
  inherits: false;
  initial-value: 16px;
}
@property --rs-ds--section--bottom-spacing {
  syntax: "<length>";
  inherits: true;
  initial-value: 40px;
}
@property --rs-ds--link--color {
  syntax: "<length>";
  inherits: true;
  initial-value: orange;
}
:root {
  --rs-ds--font--primary: "Montserrat";
  --rs-ds--font--primary-light: "Montserrat-light";
  --rs-ds--body-font-size: 0.875rem;
  --rs-ds--body-line-height: 1.375rem;
  --rs-ds--font-size--xs: 0.625rem;
  --rs-ds--font-size--xs-line-height: 1rem;
  --rs-ds--font-size--sm: 0.75rem;
  --rs-ds--font-size--sm-line-height: 1.25rem;
  --rs-ds--font-size--md: 0.875rem;
  --rs-ds--font-size--md-line-height: 1.375rem;
  --rs-ds--font-size--lg: 1rem;
  --rs-ds--font-size--lg-line-height: 1.5rem;
  --rs-ds--text-color: var(--rs-color--grey--700);
  --rs-ds--text-secondary: var(--rs-color--grey--500);
  --rs-ds--text-disabled: var(--rs-color--grey--300);
  --rs-ds--text-dark: var(--rs-color--grey--900);
  --rs-ds--border-color: var(--rs-color--grey--200);
  --rs-ds--color--primary: var(--rs-color--orange--300);
  --rs-ds--color--primary--hover: hsl(from var(--rs-ds--color--primary) h calc(s - 2) calc(l - 15));
  --rs-ds--color--hover: var(--rs-color--grey--100);
  --rs-ds--color--alert: var(--rs-color--red--400);
  --rs-ds--background-color: var(--rs-color--grey--50);
  --rs-ds--color--icon--grey: var(--rs-color--grey--700);
  --rs-ds--color--icon--disabled: var(--rs-color--grey--300);
  --rs-ds--color--icon--orange: var(--rs-color--orange--300);
  --rs-ds--link--color: var(--rs-ds--color--primary);
  --rs-ds--button-height: 2.5rem;
  --rs-transition--bezier: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --rs-ds--box--bottom-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.08);
  --rs-ds--box--top-shadow: 1px -6px 10px 0 rgba(98, 105, 115, 0.08);
  --rs-ds--main-container-top-spacing: 2.5rem;
  --rs-ds--main-container--padding-inline: 1rem;
  --rs-ds--section--bottom-spacing: 2.5rem;
}

/**
 * @param {*} $param
 * @param {String|*} $param-optional
 */
/* Fonts */
mat-vertical-stepper {
  --rs-mat-stepper-content-padding-short: 24px;
  --rs-mat-stepper-step-margin-bottom: 40px;
  --rs-mat-stepper-header-x-spacing: 40px;
  --rs-mat-stepper-header-y-spacing: 24px;
  --rs-mat-stepper-header-label-text-size: 1.125rem;
  --rs-mat-stepper-header-label-line-height: 1.625rem;
  --rs-mat-stepper-content-x-spacing: 40px;
  --rs-mat-stepper-content-y-spacing: 40px;
  --mat-stepper-line-color: var(--rs-ds--border-color);
  --rs-mat-stepper-icon-size: 40px;
  --rs-mat-stepper-lef-offset: 80px;
  --mat-stepper-header-selected-state-icon-background-color: var(--rs-color--accent--50);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--rs-color--accent--500);
  --mat-stepper-header-selected-state-icon-border-color: var(--rs-color--accent--500);
  --mat-stepper-header-icon-background-color: transparent;
  --mat-stepper-header-icon-foreground-color: var(--rs-ds--text-disabled);
  --mat-stepper-header-icon-border-color: var(--rs-ds--text-disabled);
  --rs-mat-stepper-vertical-line-left-align: -60px;
  --mat-stepper-header-hover-state-layer-shape: var(--rs-card--border-radius);
  --mat-stepper-header-focus-state-layer-shape: var(--rs-card--border-radius);
  --mat-stepper-header-selected-state-label-text-size: var(--rs-mat-stepper-header-label-text-size);
  --mat-stepper-header-label-text-size: var(--rs-mat-stepper-header-label-text-size);
  --mat-stepper-header-error-state-label-text-size: var(--rs-mat-stepper-header-label-text-size);
  --mat-stepper-container-color: transparent;
  --mat-stepper-header-hover-state-layer-color: transparent;
  --mat-stepper-header-label-text-color: var(--rs-ds--text-color);
  --mat-stepper-header-selected-state-label-text-color: var(--rs-ds--text-color);
  --mat-stepper-header-error-state-label-text-color: var(--mat-stepper-header-label-text-color);
  width: calc(100% - var(--rs-mat-stepper-lef-offset));
  transform: translateX(var(--rs-mat-stepper-lef-offset));
}
mat-vertical-stepper .mat-step {
  --rs-card--border-radius: var(--rs-ds--border-radius);
  --rs-card--background-color--primary: var(--rs-color--white);
  --rs-card--box-shadow: var(--rs-ds--box--bottom-shadow);
  background-color: var(--rs-card--background-color--primary);
  border-radius: var(--rs-card--border-radius);
  padding: var(--rs-card--padding);
  box-shadow: var(--rs-card--box-shadow);
  margin-bottom: var(--rs-card--margin-bottom);
  --rs-card--padding: 0;
}
mat-vertical-stepper .mat-step:not(:last-child) {
  margin-bottom: var(--rs-mat-stepper-step-margin-bottom);
}
mat-vertical-stepper .mat-step:has(mat-step-header[ng-reflect-selected=false]) .mat-vertical-content-container:has(.closed-step-content),
mat-vertical-stepper .mat-step:has(mat-step-header[ng-reflect-selected=false]) .mat-vertical-stepper-content:has(.closed-step-content) {
  height: auto !important;
  visibility: visible !important;
}
mat-vertical-stepper .mat-step:has(mat-step-header[ng-reflect-selected=false]):has(ct-disabled-step) {
  pointer-events: none;
  box-shadow: none;
  --mat-stepper-header-label-text-color: var(--rs-ds--text-disabled);
}
mat-vertical-stepper .mat-step:has(mat-step-header[ng-reflect-selected=false]):has(ct-disabled-step) .mat-step-icon {
  --mat-stepper-header-icon-background-color: transparent;
  --mat-stepper-header-icon-foreground-color: var(--rs-ds--text-disabled);
  border-color: var(--rs-ds--text-disabled);
}
mat-vertical-stepper .mat-step:has(mat-step-header[ng-reflect-selected=false]):has(ct-disabled-step) .mat-step-icon .mat-step-icon-content {
  display: none;
}
mat-vertical-stepper .mat-step:has(mat-step-header[ng-reflect-selected=false]):has(ct-disabled-step) .mat-step-icon:before {
  content: "\e145";
  font-family: "Material Icons";
  font-size: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  position: absolute;
}
mat-vertical-stepper .mat-step .mat-vertical-stepper-header {
  height: auto;
  overflow: initial;
  padding: var(--rs-mat-stepper-header-y-spacing) var(--rs-mat-stepper-header-x-spacing);
  box-sizing: border-box;
  line-height: var(--rs-mat-stepper-header-label-line-height);
}
mat-vertical-stepper .mat-step .mat-vertical-stepper-header.cdk-keyboard-focused, mat-vertical-stepper .mat-step .mat-vertical-stepper-header.cdk-program-focused {
  background-color: transparent;
}
mat-vertical-stepper .mat-step .mat-vertical-stepper-header[ng-reflect-selected=true] {
  cursor: default;
  pointer-events: none;
}
mat-vertical-stepper .mat-step .mat-vertical-stepper-header[ng-reflect-selected=true] [matripple] {
  display: none;
}
mat-vertical-stepper .mat-step .mat-vertical-stepper-header .mat-step-label {
  width: 100%;
}
mat-vertical-stepper .mat-step .mat-vertical-stepper-header .mat-step-label .mat-step-text-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
mat-vertical-stepper .mat-step .mat-vertical-stepper-header .mat-ripple {
  border-radius: var(--rs-card--border-radius);
}
mat-vertical-stepper .mat-step .mat-vertical-stepper-header ct-disabled-step {
  color: var(--rs-ds--text-disabled);
}
mat-vertical-stepper .mat-step .mat-vertical-stepper-header .mat-step-icon {
  font-size: 1.125rem;
  position: absolute;
  transform: translateX(calc(var(--rs-mat-stepper-vertical-line-left-align) - 60px));
  border: 2px solid var(--mat-stepper-header-icon-border-color);
  height: var(--rs-mat-stepper-icon-size);
  width: var(--rs-mat-stepper-icon-size);
}
mat-vertical-stepper .mat-step .mat-vertical-stepper-header .mat-step-icon.mat-step-icon-selected {
  border-color: var(--mat-stepper-header-selected-state-icon-border-color);
}
mat-vertical-stepper .mat-step .mat-vertical-stepper-header .mat-step-icon.mat-step-icon-state-error {
  border: none;
  background-color: var(--rs-ds--color--alert);
  color: white;
}
mat-vertical-stepper .mat-step .mat-vertical-stepper-header .mat-step-icon.mat-step-icon-state-edit:not(.mat-step-icon-selected), mat-vertical-stepper .mat-step .mat-vertical-stepper-header .mat-step-icon.mat-step-icon-state-done {
  background-color: var(--rs-color--green--600) !important;
  border: none !important;
  color: white !important;
}
mat-vertical-stepper .mat-step .mat-vertical-stepper-header .mat-step-icon.mat-step-icon-state-edit:not(.mat-step-icon-selected) mat-icon, mat-vertical-stepper .mat-step .mat-vertical-stepper-header .mat-step-icon.mat-step-icon-state-done mat-icon {
  font-size: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
}
mat-vertical-stepper .mat-step .mat-vertical-stepper-header .mat-step-icon.mat-step-icon-state-edit:not(.mat-step-icon-selected) mat-icon:before, mat-vertical-stepper .mat-step .mat-vertical-stepper-header .mat-step-icon.mat-step-icon-state-done mat-icon:before {
  content: "check";
}
mat-vertical-stepper .mat-step .mat-vertical-content-container {
  margin-left: 0 !important;
}
mat-vertical-stepper .mat-step .mat-vertical-content-container.mat-stepper-vertical-line::before {
  left: var(--rs-mat-stepper-vertical-line-left-align);
  bottom: calc(8px - (var(--mat-stepper-header-height) + 22px) / 2);
  top: calc(8px - (var(--mat-stepper-header-height) - 58px) / 2);
}
mat-vertical-stepper .mat-step .mat-vertical-content-container .mat-vertical-content {
  padding: 24px var(--rs-mat-stepper-content-x-spacing) var(--rs-mat-stepper-content-y-spacing) var(--rs-mat-stepper-content-x-spacing);
}
mat-vertical-stepper .mat-step .mat-vertical-content-container .mat-vertical-content::before {
  content: "";
  display: block;
  height: 1px;
  background-color: var(--rs-ds--border-color);
  width: calc(100% - var(--rs-mat-stepper-header-x-spacing) * 2);
  top: 0;
  position: absolute;
}
mat-vertical-stepper .mat-step ct-action-buttons {
  display: flex;
  width: 100%;
  justify-content: end;
  margin-top: 40px;
}

.mat-accordion .mat-expansion-panel {
  margin-bottom: 10px;
}
.mat-accordion .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.05);
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.05);
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-body {
  background-color: var(--rs-ds--background-color);
  padding: 30px 24px;
}
.mat-accordion .mat-expansion-panel-header,
.mat-accordion .mat-expansion-panel-content {
  font-size: 14px;
  line-height: 18px;
}
.mat-accordion .mat-expansion-panel-spacing {
  margin: 0;
}

.mat-expansion-panel {
  --mat-expansion-container-shape: var(--rs-card--border-radius);
  border-radius: var(--rs-card--border-radius);
}
.mat-expansion-panel[rs-expansion-panel-style=flat] {
  --mat-expansion-container-background-color: none;
  --mat-expansion-header-hover-state-layer-color: none;
  --mat-expansion-header-expanded-state-height: var(--mat-expansion-header-collapsed-state-height);
}
.mat-expansion-panel[rs-expansion-panel-style=flat]:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.mat-expansion-panel[rs-expansion-panel-style=flat] .mat-expansion-panel-header {
  padding: 0;
}
.mat-expansion-panel[rs-expansion-panel-style=flat] .mat-expansion-panel-body {
  margin: 0;
  padding: 0;
}

.mat-mdc-icon-button:has(.mat-badge-content) {
  z-index: 1;
}

.rs-filter-badge-button.mat-badge-medium.mat-badge-above .mat-badge-content.mat-badge-active {
  color: var(--rs-color--white);
  background-color: var(--rs-color--green--600);
  height: 26px;
  width: 26px;
  font-size: 12px;
  line-height: 25px;
  top: 2px;
  right: 0;
  left: 89%;
}

@property --rs-mat-table--header-cell--align {
  syntax: "center | flex-start";
  inherits: true;
  initial-value: flex-start;
}
@property --rs-mat-table--header-cell--justify {
  syntax: "center | flex-start";
  inherits: true;
  initial-value: flex-start;
}
@property --rs-mat-table--cell--align {
  syntax: "center | flex-start";
  inherits: true;
  initial-value: flex-start;
}
@property --rs-mat-table--cell--justify {
  syntax: "center | flex-start";
  inherits: true;
  initial-value: flex-start;
}
:root {
  --rs-mat-table--mat-mdc-cell--inline-padding: 1rem;
}
:root {
  --mat-table-header-headline-font: Montserrat;
}
:root {
  --mat-table-header-headline-weight: 400;
}
:root {
  --mat-table-row-item-outline-color: var(--rs-ds--border-color);
}

.mat-mdc-table:not(table) {
  border-radius: 3px;
}
.mat-mdc-table:not(table) .mat-sort-header:not(.mat-sort-header-disabled) .mat-sort-header-container {
  transform: translateX(-18px);
}
.mat-mdc-table:not(table) .mat-sort-header .mat-sort-header-container {
  flex-direction: row-reverse;
}
.mat-mdc-table:not(table) .mat-mdc-header-cell {
  border-bottom: none;
  justify-content: var(--rs-mat-table--header-cell--justify);
}
.mat-mdc-table:not(table) .mat-mdc-cell {
  border-bottom: none;
  align-items: var(--rs-mat-table--cell--align);
  justify-content: var(--rs-mat-table--cell--justify);
  word-break: break-word;
}
.mat-mdc-table:not(table) .mat-mdc-footer-row,
.mat-mdc-table:not(table) .mat-mdc-header-row,
.mat-mdc-table:not(table) .mat-mdc-row {
  outline: none;
  border-bottom: 1px solid var(--rs-ds--border-color);
}
.mat-mdc-table:not(table) .mat-mdc-row:last-child {
  border-bottom: none;
}
.mat-mdc-table:not(table) .mat-mdc-header-row {
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.1), 0 2px 5px -3px rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.1);
}
.mat-mdc-table:not(table) .mat-sort-header-content {
  flex-direction: column;
  text-align: left;
  align-items: var(--rs-mat-table--header-cell--align);
}

.mat-mdc-table[rs-table-density="0"] {
  --mat-table-header-headline-size: clamp(10px, 1cqw, 12px);
  --mat-table-header-headline-line-height: 1.125rem;
  --mat-table-row-item-label-text-line-height: 1.125rem;
  --mat-table-row-item-label-text-size: clamp(10px, 1cqw, 12px);
  --mat-table-cell-and-header-cell-inline-padding: 1rem;
}
.mat-mdc-table[rs-table-density="0"] .mdc-data-table__cell,
.mat-mdc-table[rs-table-density="0"] .mdc-data-table__header-cell {
  padding: 0.5rem;
  word-break: break-word;
}
.mat-mdc-table[rs-table-density="0"] .mdc-data-table__cell:first-of-type,
.mat-mdc-table[rs-table-density="0"] .mdc-data-table__header-cell:first-of-type {
  padding-left: var(--mat-table-cell-and-header-cell-inline-padding);
}
.mat-mdc-table[rs-table-density="0"] .mdc-data-table__cell:last-of-type,
.mat-mdc-table[rs-table-density="0"] .mdc-data-table__header-cell:last-of-type {
  padding-right: var(--mat-table-cell-and-header-cell-inline-padding);
}
.mat-mdc-table[rs-table-density="0"] .mat-sort-header:not(.mat-sort-header-disabled) .mat-sort-header-container {
  transform: none;
}
.mat-mdc-table[rs-table-density="0"] .mat-sort-header .mat-sort-header-container {
  flex-direction: row;
}
.mat-mdc-table[rs-table-density="0"] .mdc-data-table__header-cell {
  text-wrap: balance;
}
.mat-mdc-table:not(table):not([rs-table-density]) .mat-mdc-cell {
  padding: 20px var(--rs-mat-table--mat-mdc-cell--inline-padding);
}
.mat-mdc-table:not(table):not([rs-table-density]) .mat-mdc-header-cell {
  padding: 8px var(--rs-mat-table--mat-mdc-cell--inline-padding);
}
.mat-mdc-table:not(table):not([rs-table-density]) .mat-mdc-cell .sub {
  color: var(--rs-ds--text-secondary);
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.mat-mdc-table.rs-table--clickable-rows mat-row:hover,
.mat-mdc-table.rs-table--clickable-rows .mat-mdc-row:hover {
  background-color: rgba(0, 0, 0, 0.02);
  cursor: pointer;
}
.mat-mdc-table.rs-table--clickable-rows .mat-mdc-cell {
  background-color: transparent;
}

.mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) {
  container-name: rs-table-container;
  container-type: inline-size;
}
@container rs-table-container (width <=769px) {
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-sort-header-button,
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-mdc-header-cell,
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-mdc-cell {
    padding: 0 var(--rs-mat-table--mat-mdc-cell--inline-padding);
  }
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-mdc-row {
    --mat-table-row-item-label-text-line-height: 1.375rem;
    --mat-table-row-item-label-text-size: 0.875rem;
    gap: 1rem;
    padding-block: 12px;
  }
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-mdc-row .mat-mdc-cell {
    justify-content: left !important;
    flex-basis: auto !important;
    align-items: start !important;
    flex-direction: column;
  }
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-mdc-row .mat-mdc-cell[rs-table-responsive-header]::before {
    content: attr(rs-table-responsive-header);
    color: var(--rs-ds--text-secondary);
    line-height: 1rem;
    font-size: 0.75rem;
  }
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-mdc-footer-row,
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-mdc-header-row {
    padding: 0;
  }
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-mdc-footer-row::after,
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-mdc-header-row::after,
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-mdc-row::after {
    min-height: 16px;
  }
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-mdc-row:nth-child(odd) {
    background-color: var(--rs-color--grey--50);
  }
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-header-row-mobile {
    width: 100%;
  }
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-mdc-header-row {
    display: none;
  }
  .mat-mdc-table:not(table):has(.mat-mdc-cell[rs-table-responsive-header]) .mat-mdc-row {
    align-items: start;
    flex-direction: column;
  }
}

table.mat-mdc-table {
  overflow: hidden !important;
}
table.mat-mdc-table > * {
  word-wrap: break-word;
}
table.mat-mdc-table thead {
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.1), 0 2px 5px -3px rgba(0, 0, 0, 0.2), 0 -5px 0px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: relative;
}
table.mat-mdc-table thead tr th {
  border: none;
  align-items: center;
  padding-left: 0;
}
table.mat-mdc-table thead tr th:first-child {
  padding-left: 15px;
}
table.mat-mdc-table thead tr th:first-child:not(.mat-sort-header:not(.mat-sort-header-disabled)) {
  padding-left: 30px;
}
table.mat-mdc-table thead tr th:last-child {
  padding-right: 15px;
}
table.mat-mdc-table thead tr th:not(.mat-sort-header:not(.mat-sort-header-disabled)) {
  padding-left: 15px;
}
table.mat-mdc-table thead tr th .mat-sort-header-arrow {
  margin-left: 2px;
}
table.mat-mdc-table tbody tr td {
  font-family: var(--rs-ds--font--primary-light) !important;
  font-weight: 400;
  font-size: 0.875rem !important;
  line-height: 1.125rem !important;
  padding: 20px 0 20px 15px;
}
table.mat-mdc-table tbody tr td:first-child {
  padding-left: 30px;
}
table.mat-mdc-table tbody tr td:last-child {
  padding-right: 15px;
}
table.mat-mdc-table .mat-sort-header-container {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.mat-mdc-paginator {
  z-index: 1;
  position: relative;
  box-shadow: 0 -4px 9px 0 rgba(0, 0, 0, 0.1);
}
.mat-mdc-paginator .mat-mdc-form-field {
  width: 50px !important;
}
.mat-mdc-paginator .mdc-notched-outline > * {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0 !important;
}

.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  --mat-standard-button-toggle-text-color: var(--rs-ds--text-secondary);
  --mat-standard-button-toggle-label-text-size: var(--rs-ds--body-font-size);
  --mat-standard-button-toggle-shape: var(--rs-ds--border-radius);
  --mat-standard-button-toggle-background-color: transparent;
  --mat-standard-button-toggle-height: var(--rs-ds--button-height);
  --mat-standard-button-toggle-state-layer-color: var(--rs-ds--color--hover);
  --mat-standard-button-toggle-hover-state-layer-opacity: 1;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-standard-button-toggle-selected-state-text-color: var(----rs-ds--text-color);
  --mat-standard-button-toggle-selected-state-background-color: var(--rs-ds--border-color);
  --mat-standard-button-toggle-disabled-state-text-color: var(--rs-ds--text-disabled);
  --mat-standard-button-toggle-disabled-state-background-color: transparent;
  --mat-standard-button-toggle-disabled-selected-state-text-color: var(--rs-ds--text-disabled);
  --mat-standard-button-toggle-disabled-selected-state-background-color: var(--rs-ds--border-color);
  --mat-standard-button-toggle-divider-color: var(--rs-ds--text-secondary);
}
.mat-button-toggle-group.mat-button-toggle-group-appearance-standard[disabled] {
  --mat-standard-button-toggle-divider-color: var(--rs-ds--text-disabled);
}
.mat-button-toggle-group.mat-button-toggle-group-appearance-standard:hover {
  color: var(----rs-ds--text-color);
}
.mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle:not(.mat-button-toggle-disabled):hover {
  color: var(----rs-ds--text-color);
}
.mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay {
  background-color: var(--mat-standard-button-toggle-selected-state-background-color);
}
.mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle button {
  z-index: 1;
}
.mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle .mat-ripple {
  z-index: 0;
}

:root {
  --rs-clear-form-field-btn--button-size: 30px;
  --rs-clear-form-field-btn--icon-size: 18px;
}

mat-form-field[appearance=outline] .rs-clear-form-field-button--wrapper {
  margin-left: 5px;
}
mat-form-field.mat-form-field-disabled .rs-clear-form-field-button {
  display: none !important;
}
mat-form-field .rs-clear-form-field-button--wrapper {
  width: var(--rs-clear-form-field-btn--button-size);
  transition: 0.4s width ease-out;
  padding: 0;
}
mat-form-field .rs-clear-form-field-button--wrapper .rs-clear-form-field-button.mdc-icon-button {
  padding: 0;
  height: var(--rs-clear-form-field-btn--button-size);
  width: var(--rs-clear-form-field-btn--button-size);
  right: 0;
  transform-origin: center;
  transform: rotate(-180deg);
  transition: 0.8s transform var(--rs-transition--bezier), 0.4s right ease-out, 0.1s height ease-out, 0.1s width ease-out;
}
mat-form-field .rs-clear-form-field-button--wrapper .rs-clear-form-field-button.mdc-icon-button .mat-icon {
  font-size: var(--rs-clear-form-field-btn--icon-size);
  height: var(--rs-clear-form-field-btn--icon-size);
  line-height: var(--rs-clear-form-field-btn--icon-size);
}
mat-form-field .rs-clear-form-field-button--wrapper.rs-clear-form-field-button--disabled {
  transition: 0.2s width ease-out;
}
mat-form-field .rs-clear-form-field-button--wrapper.rs-clear-form-field-button--disabled .rs-clear-form-field-button {
  pointer-events: none;
  right: -40px;
  transform: rotate(360deg);
  transition: 0.8s transform var(--rs-transition--bezier), 1.4s right ease-out, 0.4s height ease-out, 0.4s width ease-out;
}

mat-form-field:not(:has(textarea)) .rs-clear-form-field-button--wrapper.rs-clear-form-field-button--disabled {
  width: 0;
  margin-left: 0;
}

.mat-mdc-form-field[rs-input-prefix] .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
  left: -18px;
}
.mat-mdc-form-field[rs-input-prefix] .mat-mdc-text-field-wrapper .mat-mdc-form-field-icon-prefix {
  align-self: baseline;
}
.mat-mdc-form-field[rs-input-prefix] .rs-input-prefix {
  opacity: 0;
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  color: var(--rs-color--grey--700);
  transform: translateY(1px);
  display: inline-block;
}
.mat-mdc-form-field[rs-input-prefix]:not(.mat-form-field-hide-placeholder) .rs-input-prefix {
  transition: opacity ease-in-out 0.3s;
  opacity: 1;
}

.rs-button-spinner.rs-button-spinner-type--spinner .spinner-container {
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  position: absolute;
}
.rs-button-spinner.rs-button-spinner-type--spinner .spinner-container .mat-mdc-progress-spinner {
  display: none;
}

.rs-button-spinner.rs-button-spinner-type--spinner.rs-button-spinning .mat-mdc-progress-spinner {
  display: block;
}
.rs-button-spinner.rs-button-spinner-type--spinner.rs-button-spinning .spinner-container svg {
  height: auto !important;
}

.rs-button-spinner.rs-button-spinner-type--border.rs-button-spinning::before, .rs-button-spinner.rs-button-spinner-type--border.rs-button-spinning::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid var(--rs-color--blue--500);
  transition: all 0.5s;
  animation: clipPath 3s infinite linear;
  border-radius: inherit;
}
.rs-button-spinner.rs-button-spinner-type--border.rs-button-spinning::after {
  animation: clipPath 3s infinite -1.5s linear;
}
@keyframes clipPathRounded {
  0% {
    clip-path: inset(100% 0 0 0);
  }
  50% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 0 100% 0);
  }
}
@keyframes clipPath {
  0%, 100% {
    clip-path: inset(0 0 98% 0);
  }
  25% {
    clip-path: inset(0 98% 0 0);
  }
  50% {
    clip-path: inset(98% 0 0 0);
  }
  75% {
    clip-path: inset(0 0 0 98%);
  }
}

.rs-button-spinner.rs-button-spinning {
  pointer-events: none;
}
:root {
  interpolate-size: allow-keywords;
}
@media (width >= 1200px) {
  :root {
    --rs-ds--main-container--width: 90%;
  }
}
@media (width >= 1396px) {
  :root {
    --rs-ds--main-container--width: 80%;
  }
}

html {
  height: 100%;
  font-size: 16px;
}
html.stop-scroll body {
  overflow-y: hidden;
}

body {
  background-color: var(--rs-ds--background-color);
  font-size: var(--rs-ds--body-font-size);
  line-height: var(--rs-ds--body-line-height);
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  color: var(--rs-ds--text-color);
}
body.no-scroll, body[data-no-scroll=on] {
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  position: fixed;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--rs-ds--font--primary) !important;
  font-weight: 600;
  margin: 0;
  text-wrap: balance;
}

h1 {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

h2 {
  font-size: 1.125rem !important;
  line-height: 1.625rem !important;
}

h3 {
  font-size: 1rem;
  line-height: 1.5rem;
}

h4 {
  font-size: 0.9375rem !important;
  line-height: 1.1875rem !important;
}

h5 {
  font-size: 0.8125rem !important;
  line-height: 1rem !important;
}

h6 {
  font-size: 0.6875rem !important;
  line-height: 0.875rem !important;
}

p {
  margin-bottom: 5px;
  text-wrap: pretty;
}

.cdk-global-scrollblock {
  overflow-y: initial;
}

a {
  color: var(--rs-ds--link--color);
  text-decoration: none;
}
a:hover {
  color: hsl(from var(--rs-ds--link--color) h calc(s - 2) calc(l - 15));
  text-decoration: underline;
}
a:not([href]) {
  cursor: pointer;
}

rs-container {
  display: block;
  width: var(--rs-ds--main-container--width);
  padding-inline: var(--rs-ds--main-container--padding-inline);
  margin-inline: auto !important;
}
rs-container[rs-container-width=full] {
  width: calc(100% - var(--rs-ds--main-container--padding-inline) * 2);
  padding-inline: calc((100% - var(--rs-ds--main-container--width)) / 2);
}
rs-container[rs-two-col-grid] {
  display: grid;
  grid-template-columns: 33fr 66fr;
  gap: 32px;
}
@media (width <= 992px) {
  rs-container[rs-two-col-grid] {
    grid-template-columns: 1fr;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

@font-face {
  font-family: Montserrat-Light;
  src: url("../../assets/fonts/Montserrat-Light.ttf");
  font-weight: 400;
}
.container {
  padding: 0 1rem;
  margin: auto;
}

.topbar-container .topbar > .topbar-row {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.rs-label {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  color: var(--rs-ds--text-color);
  font-family: var(--rs-ds--font--primary-light) !important;
  font-weight: 400;
  display: inline-block;
  padding-bottom: 20px;
}
.rs-label.invalid {
  color: var(--rs-ds--color--alert);
}

.mat-calendar-body-selected {
  color: var(--rs-color--white);
}

button.mat-calendar-body-cell.mat-calendar-body-disabled {
  cursor: not-allowed;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mdc-text-field .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6) !important;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}