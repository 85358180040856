@use '@angular/material' as mat;

:root {
  //--mdc-switch-
  @include mat.slide-toggle-overrides(
    (
      selected-focus-handle-color: var(--rs-color--white),
      selected-hover-handle-color: var(--rs-color--white),
      selected-hover-track-color: var(--rs-ds--color--primary--hover),
      selected-focus-track-color: var(--rs-ds--color--primary--hover),
      unselected-hover-handle-color: var(--rs-color--grey--700),
      unselected-focus-handle-color: var(--rs-color--grey--700),
      track-height: 24px,
      with-icon-handle-size: 16px,
      pressed-handle-size: 18px,
      unselected-pressed-handle-horizontal-margin: 0 3px,
      unselected-with-icon-handle-horizontal-margin: 0 4px,
      selected-with-icon-handle-horizontal-margin: 0 19px,
      selected-pressed-handle-horizontal-margin: 0 18px,
      track-width: 38px,
    )
  );
}

mat-slide-toggle.mat-mdc-slide-toggle {
  --mdc-form-field-label-text-line-height: 22px;

  .mdc-switch__ripple {
    display: none !important;
  }
}
