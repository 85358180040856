@use 'sass:math';

.rs-sub-text {
  color: var(--rs-ds--text-secondary);
  @include rs-px-to-rem(13, 16);
}

rs-font-light-block,
[rs-font-light-block] {
  @include rs-font--primary-light();
  display: block;
}

$rs-text-colors-utils-map: (
  'secondary': (
    color: var(--rs-ds--text-secondary),
  ),
  'alert': (
    color: var(--rs-ds--color--alert),
  ),
  'ds-primary': (
    color: var(--rs-ds--color--primary),
  ),
);

@each $key, $props in $rs-text-colors-utils-map {
  %rs-text-color--#{$key} {
    color: map-get($props, color) !important;
  }

  [rs-text-color='#{$key}'] {
    @extend %rs-text-color--#{$key};
  }
}

$rs-font-size-utils-map: (
  'xs': (
    font-size: var(--rs-ds--font-size--xs),
    line-height: var(--rs-ds--font-size--xs-line-height),
  ),
  'sm': (
    font-size: var(--rs-ds--font-size--sm),
    line-height: var(--rs-ds--font-size--sm-line-height),
  ),
  'md': (
    font-size: var(--rs-ds--font-size--md),
    line-height: var(--rs-ds--font-size--md-line-height),
  ),
  'lg': (
    font-size: var(--rs-ds--font-size--lg),
    line-height: var(--rs-ds--font-size--lg-line-height),
  ),
);

@each $key, $props in $rs-font-size-utils-map {
  %rs-font-size--#{$key} {
    font-size: map-get($props, font-size);
    line-height: map-get($props, line-height);
  }

  [rs-font-size='#{$key}'] {
    @extend %rs-font-size--#{$key};
  }
}

// font-sizes utility defines: font-size & line-height

/** If even number add 4 to value else 3 >> igloo rules */
@function getLineHeight($value) {
  @if ($value%2==0) {
    @return math.div($value + 4, 16);
  } @else {
    @return math.div($value + 3, 16);
  }
}

/** $font-sizes-map: (
  [class-suffix]: '[font-size in-rem] [line-height in-rem]'
  ex: 13px: ('font-size': 0.8125rem, 'line-height': 1rem)
) */

$rs-font-sizes-map: ();

@for $i from 1 through 60 {
  $rs-font-sizes-map: map-merge(
    $rs-font-sizes-map,
    (
      #{$i}px:
        (
          font-size: #{math.div($i, 16)}rem unquote('!important'),
          line-height: #{getLineHeight($i)}rem unquote('!important'),
        )
    )
  );
}

// @debug $font-sizes-map;

// Generating font-sizes-map utilities classes
@each $class-suffix, $value in $rs-font-sizes-map {
  // generate classes
  .rs-font-size-#{$class-suffix} {
    $fs: map-get(map-get($rs-font-sizes-map, $class-suffix), 'font-size');
    $lh: map-get(map-get($rs-font-sizes-map, $class-suffix), 'line-height');

    font-size: $fs;
    line-height: $lh;
  }
}
